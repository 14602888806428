import * as Yup from 'yup'
import { validatePOChallanOrVoucherNumber } from './HelperFuncitons'



const paths = window.location.pathname?.split('/')

// admin sign in
export const SignInInitaialValue = {
  phoneNumber: "",
}



export const SignInValidationSchema = Yup.object({
  phoneNumber: Yup.string()
    .required('Phone number is required')
    .matches(/^\+?[1-9]\d{9,12}$/, 'Invalid phone number')
})

export const OtpverificationInitial = {
  otp: "",
}

export const OtpValidationSchema = Yup.object({
  otp: Yup.string()
  .max(6,"Otp must have 6 digits")
    .required('OTP is required')
    .matches(/^[0-9]{6}$/, 'Invalid OTP')
})

export const poAddInitalValue = {
  // siteId: "",
  type:"",
  vendorId: "",
  createDate: new Date().toISOString(),
  dueDate: "",
  remarks:"",
  items: [],
  termsAndCondition: {
    title:"",
    description:"",
  }
}

export const poAddValidationSchema = (language={}) =>{
  return  Yup.object().shape({
    // siteId: Yup.string().trim().required('Site ID is required'),
    type: Yup.string().trim().required(language?.type_required ||'Type is required'),
    vendorId: Yup.string().trim().required(language?.vendor_id_required ||'Vendor ID is required'),
    createDate: Yup.string().trim().required(language?.create_date_required ||'Create Date is required'),
    dueDate: Yup.string().trim().required(language?.due_date_required ||'Due Date is required'),
    termsAndCondition:  Yup.object().shape({
      title: Yup.string()
        .required(language?.terms_and_conditions_required ||'T&C is required')
    }),
    items: Yup.array().of(
      Yup.object().shape({
        itemId: Yup.string().trim().required(language?.item_id_required ||'Item ID is required'),
        // quantity: Yup.number().typeError('Quantity must be a number').positive('Quantity must be a positive number').required('Quantity is required'),
        quantity: Yup.string().test('quantity', language?.quantity_greater_than_zero ||'Quantity must be greater than zero', function (value) {
          const { selected } = this.parent;
          if (selected) {
            if (!value || Number(value) <= 0) {
              return false
            }
          }
          return true;
        }),
  
        unitPrice: Yup.string().test('unitPrice', language?.unit_price_greater_than_zero ||'UnitPrice must be greater than zero', function (value) {
          const { selected } = this.parent;
          if (selected) {
            if (!value || Number(value) <= 0) {
              return false
            }
          }
          return true;
        }),
      })
    ),
  });
}

export const dprAddInitalValue = {
  // siteId: "",
  type:"",
  vendorId: "",
  createDate: new Date().toISOString(),
  remarks:"",
  items: [],
  // workDescription: "",
  // length:0,
  // breath:0,
  // height:1,
  // number1:0,
  // number2:0,
  // diameter:0,
  // isDiameter:false

}


export const dprAddValidationSchema = (language={})=>{
  return Yup.object().shape({
    vendorId: Yup.string().trim().required(language?.vendor_id_required || "Vendor ID is required"),
    createDate: Yup.string().trim().required(language?.create_date_required || "Create Date is required"),
    items: Yup.array().of(
      Yup.object().shape({
        itemId: Yup.string().trim().required(language?.item_id_required || "Item ID is required"),
        workDescription: Yup.string(),
        // .test(
        //   "workDescription",
        //   "Work Description is required",
        //   function (value) {
        //     const { selected } = this.parent;
        //     if (selected && (!value || !value.trim())) {
        //       return false;
        //     }
        //     return true;
        //   }
        // )
        commodityPart: Yup.string()
        .test(
          "commodityPart",
          language?.commodity_part_required || "Commodity part is required",
          function (value) {
            const { selected } = this.parent;
            if (selected && (!value || !value.trim())) {
              return false;
            }
            return true;
          }
        ),
        commoditySubPart: Yup.string()
        // .test(
        //   "commoditySubPart",
        //   "Commodity sub part is required",
        //   function (value) {
        //     const { selected } = this.parent;
        //     if (selected && (!value || !value.trim())) {
        //       return false;
        //     }
        //     return true;
        //   }
        // )
        ,
        itemQty: Yup.array()
          .min(1, language?.atleast_one_item_qty_required || "At least one itemQty is required if the item is selected")
          .of(
            Yup.object().shape({
              number1: Yup.number()
                .test("number1", language?.number1_greater_than_zero || "Number1 must be greater than zero", function (value) {
                  const { items } = this.options.context;
                  const { path } = this;
  
                  // Extract indices
                  const itemIndex = parseInt(path?.match(/items\[(\d+)\]/)?.[1], 10);
                  const { selected } = items[itemIndex] || {};
  
                  // Validation logic
                  if (selected && (!value || value <= 0)) {
                    return false;
                  }
                  return true;
                })
                .required(language?.number1_required || "Number1 is required"),
              number2: Yup.number()
                .test("number2", language?.number2_greater_than_zero || "Number2 must be greater than zero", function (value) {
                  const { items } = this.options.context;
                  const { path } = this;
  
                  // Extract indices
                  const itemIndex = parseInt(path?.match(/items\[(\d+)\]/)?.[1], 10);
                  const { selected } = items[itemIndex] || {};
  
                  // Validation logic
                  if (selected && (!value || value <= 0)) {
                    return false;
                  }
                  return true;
                })
                .required(language?.number2_required || "Number2 is required"),
              diameter: Yup.number()
                .test("diameter", language?.diameter_greater_than_zero || "Diameter must be greater than zero", function (value) {
                  const { items } = this.options.context;
                  const { path } = this;
  
                  // Extract indices
                  const itemIndex = parseInt(path?.match(/items\[(\d+)\]/)?.[1], 10);
                  const { selected, isDiameter } = items[itemIndex] || {};
  
                  // Validation logic
                  if (selected && isDiameter && (!value || value <= 0)) {
                    return false;
                  }
                  return true;
                })
                .nullable(),
              length: Yup.number()
                .test("length", language?.length_greater_than_zero || "Length must be greater than zero", function (value) {
                  const { items } = this.options.context;
                  const { path } = this;
  
                  // Extract indices
                  const itemIndex = parseInt(path?.match(/items\[(\d+)\]/)?.[1], 10);
                  const { selected, isDiameter } = items[itemIndex] || {};
  
                  // Validation logic
                  if (selected && !isDiameter && (!value || value <= 0)) {
                    return false;
                  }
                  return true;
                })
                .nullable(),
              breath: Yup.number()
                .test("breath", language?.breadth_greater_than_zero || "Breath must be greater than zero", function (value) {
                  const { items } = this.options.context;
                  const { path } = this;
  
                  // Extract indices
                  const itemIndex = parseInt(path?.match(/items\[(\d+)\]/)?.[1], 10);
                  const { selected, isDiameter } = items[itemIndex] || {};
  
                  // Validation logic
                  if (selected && !isDiameter && (!value || value <= 0)) {
                    return false;
                  }
                  return true;
                })
                .nullable(),
              height: Yup.number()
                .test("height", language?.height_greater_than_zero || "Height must be greater than zero", function (value) {
                  const { items } = this.options.context;
                  const { path } = this;
  
                  // Extract indices
                  const itemIndex = parseInt(path?.match(/items\[(\d+)\]/)?.[1], 10);
                  const { selected } = items[itemIndex] || {};
  
                  // Validation logic
                  if (selected && (!value || value <= 0)) {
                    return false;
                  }
                  return true;
                })
                .nullable(language?.fill_all_values || "Fill all values"),
                subPart: Yup.string()
                .test("subPart", language?.required || "Required", function (value) {
                  const { items } = this.options.context;
                  const { path } = this;
      
                  // Extract indices
                  const itemIndex = parseInt(path?.match(/items\[(\d+)\]/)?.[1], 10);
                  const { selected } = items[itemIndex] || {};
      
                  // Validation logic
                  if (selected && !value) {
                    return false;
                  }
                  return true;
                }),
            })
          ),
      })
    ),
  });
}

export const dlrAddInitalValue = {
  createDate: new Date().toISOString(),
  remarks:"",
  reason:"",
  items: [
    {
      vendorId: "",
      itemId:"",
      noOfLabour:0,
      noOfTechnical:0,
      noOfNonTechnical:0,
    }
  ],
}


export const dlrAddValidationSchema = (language={})=>{
  return Yup.object().shape({
    createDate: Yup.string().trim().required(language?.create_date_required || "Create Date is required"),
    reason: Yup.string().trim().required(language?.required || "Reason for labour is required"),
    items: Yup.array().of(
      Yup.object().shape({
        itemId: Yup.object().shape({
          value:Yup.string().required(language?.required || "Item ID is required"),
        }),
        vendorId: Yup.object().shape({
          value:Yup.string().required(language?.required || "Vendor ID is required"),
        }),
        noOfLabour:Yup.number().test("noOfLabour", language?.must_be_greater_than_zero || "Greater than zero", function (value) {
          // Validation logic
          if(!value && value <= 0) {
            return false;
          }
          return true;
        }).required(language?.required || "Required"),
        noOfTechnical:Yup.number().test("noOfTechnical", language?.positive_number || "Must be positive number", function (value) {
          // Validation logic
          if((!value && value!=0) || value < 0) {
            return false;
          }
          return true;
        }).required(language?.required || "Required"),
        noOfNonTechnical:Yup.number().test("noOfNonTechnical", language?.positive_number || "Must be positive number", function (value) {
          // Validation logic
          if((!value && value!=0) || value < 0) {
            return false;
          }
          return true;
        }).required(language?.required || "Required"),

      })
    ),
  });
}


export const boqAddInitalValue = {
  contractStartDate: new Date().toISOString(),
  contractDate: new Date().toISOString(),
  completionDate: new Date().toISOString(),
  actualDate: new Date().toISOString(),
  category:"",
  items: [],
}

export const boqAddValidationSchema = (language={})=>{
  return Yup.object().shape({
    contractStartDate: Yup.string().trim().required(language?.contract_start_date_required || "Contract start date is required"),
    contractDate: Yup.string().trim().required(language?.contract_date_required || "Contract date is required"),
    completionDate: Yup.string().trim().required(language?.completion_date_required || "Completion date is required"),
    actualDate: Yup.string().trim().required(language?.actual_date_required || "Actual date is required"),
    category:Yup.string().trim().required("Category is required"),
  items: Yup.array().of(
      Yup.object().shape({
        orderNo:Yup.string().trim().required(language?.order_no_required || "Order no is required"),
        nameAndDetails:Yup.string().trim().required(language?.name_and_details_required || "Name and details is required"),
        unitType:Yup.string().trim().required(language?.unit_required || "Unit is required"),
        quantity:Yup.string().test('quantity', language?.quantity_greater_than_zero || 'Quantity must be greater than zero', function (value) {
            if (!value || Number(value) <= 0) {    
              return false
            }
            return true
        }),
        unitPrice:Yup.string().test('unitPrice', language?.rate_greater_than_zero || 'Rate must be greater than zero', function (value) {
            if (!value || Number(value) <= 0) {
              return false
            }
            return true
        }),
      })
    ),
  });
}


export const ginAddInitalValue = {
  vendorId: "",
  createDate: new Date().toISOString(),
  dueDate: "",
  remarks:"",
  items: [],
  signature:"",
}

export const ginAddValidationSchema = (language={})=>{
  return Yup.object().shape({
    vendorId: Yup.string().trim().required(language?.vendor_id_required || 'Vendor ID is required'),
    createDate: Yup.string().trim().required(language?.date_of_issue_required || 'Date of issue is required'),
    signature: Yup.string().trim().required(language?.signature_required || 'Signature is required'),
    items: Yup.array().of(
      Yup.object().shape({
        inventoryItemId: Yup.string().trim().required(language?.item_id_required || 'Item ID is required'),
        // quantity: Yup.number().typeError('Quantity must be a number').positive('Quantity must be a positive number').required('Quantity is required'),
        quantity: Yup.string()
        .test('quantity', language?.quantity_greater_than_zero || 'Quantity must be greater than zero', function (value) {
          const { selected } = this.parent;
          if (selected) {
            if (!value || Number(value) <= 0) {
              return false;
            }
          }
          return true;
        })
        .test('availableQty', language?.quantity_not_exceed_available || 'Quantity must not exceed available quantity', function (value) {
          const { availableQty, selected,maxReturnQty } = this.parent;
          if (selected && !maxReturnQty) {
            if (value && Number(value) > Number(availableQty)) {
              return false;
            }
          }
          return true;
        })
        .test('maxReturnQty', language?.quantity_not_exceed_issue || 'Quantity must not exceed to issue quantity', function (value) {
          const { availableQty, selected,maxReturnQty } = this.parent;
          if (selected && maxReturnQty) {
            if (value && Number(value) > Number(maxReturnQty)) {
              return false;
            }
          }
          return true;
        }),
      
        
  
        // unitPrice: Yup.string().test('unitPrice', 'UnitPrice must be greater than zero', function (value) {
        //   const { selected } = this.parent;
        //   if (selected) {
        //     if (!value || Number(value) <= 0) {
        //       return false
        //     }
        //   }
        //   return true;
        // }),
      })
    ),
  });
}

export const addItemInitialValue = {
  _id: "",
  isDisabled: true,
  name: "",
  localName: "",
  description: "",
  unitType: "",
  hsnCode: "",
  tax: "18",
  unitPrice: "",
  totalQuantity: "",
  otherUnit:"",
  
}

export const addItemValidationSchema =  (language={})=>{
  return Yup.object().shape({
    name: Yup.string().trim().required(language?.name_required || 'Name is required'),
    localName: Yup.string().trim().required(language?.local_name_required || 'Local Name is required'),
    description: Yup.string().trim().required(language?.description_required || 'Description is required'),
    // hsnCode: Yup.string().trim().required('HSN Code is required'),
    unitType: Yup.string().trim().required(language?.unit_type_required || 'Unit Type is required'),
    // ...(paths.includes('challan') ? {
    //   unitPrice: Yup.number().typeError('Unit price must be a number').positive('Unit price must be a positive number').required('Unit price is required'),
    //   totalQuantity: Yup.number().typeError('Quantity must be a number').positive('Quantity must be a positive number').required('Quantity is required')
    // }
    //   : {}),
    otherUnit:Yup.string().trim().test('other-unit', language?.other_unit_required || 'Other unit is required ', function (value) {
      if (this.parent.unitType?.toLowerCase() == 'other') {
        return value && value.trim() !== '';
      }
      return true;
    }),
  });
}



export const addGinItemInitialValue = {
  isDisabled: true,
  name: "",
 itemDetails:"",
  localName:"",
  description:"",
  itemType:"",
  availableQty:"",
  hsnCode:"",
  unitType:"",
  tax:"",
  inventoryItemId:"",
}

export const addGinItemValidationSchema = (language={})=>{
  return Yup.object().shape({
    name: Yup.string().trim().required(language?.name_required || 'Name is required'),
  });
}

export const vendorAddInitailValue = {
  isDisabled: true,
  name: "",
  type:"",
  gstNumber: "",
  panNumber: "",
  address1: "",
  address2: "",
  city: "",
  state: "",
  pinCode: "",
  bankName: "",
  bankAccountNo: "",
  phone: "",
  email: "",
  IFSC:"",
  otherCity:"",
  specialization:"",
  nickName:"",
}

export const vendorAddValidationSchema = (language={})=>{
  return Yup.object().shape({
    name: Yup.string().trim().required(language?.name_required || 'Name is required'),
    type: Yup.string().trim().required(language?.type_required || 'Type is required'),
    // gstNumber: Yup.string().trim().required('GST is required'),
    gstNumber: Yup.string().trim().test('check-vendor', language?.gst_required || 'GST is required ', function (value) {
      if (this.parent.type?.toLowerCase() == 'vendor') {
        return value && value.trim() !== '';
      }
      return true;
    })
    .matches(/^[A-Za-z0-9]+$/, language?.gst_invalid || 'GST must contain only letters and numbers'),
    panNumber: Yup.string().trim().test('check-vendor-pan', language?.pan_required || 'PAN is required ', function (value) {
      if (this.parent.type?.toLowerCase() != 'vendor') {
        return value && value.trim() !== '';
      }
      return true;
    })
    .matches(/^[A-Za-z0-9]+$/, language?.pan_invalid || 'PAN must contain only letters and numbers')
    .length(10, language?.pan_length_invalid || 'PAN must have 10 characters'),
    address1: Yup.string().trim().required(language?.address1_required || 'Address1 is required'),
    address2: Yup.string().trim().required(language?.address2_required || 'Address2 is required'),
    city: Yup.string().trim().required(language?.city_required || 'City is required'),
    state: Yup.string().trim().required(language?.state_required || 'State is required'),
    pinCode: Yup.string().trim().required(language?.pin_code_required || 'Pin code is required').matches(/^\+?[1-9]\d{5}$/, language?.invalid_pin_code || 'Invalid pin code'),
    bankName: Yup.string().trim().test('check-vendor-bankName', language?.bank_name_required || 'Bank name is required', function (value) {
      if (this.parent.type?.toLowerCase() == 'vendor') {
        return value && value.trim() !== '';
      }
      return true;
    }),
    bankAccountNo: Yup.number().typeError(language?.invalid_number || 'Account number must be a number').test('check-vendor-bnk-acc', language?.account_number_required || 'Account number is required', function (value) {
      if (this.parent.type?.toLowerCase() == 'vendor') {
        console.log("va",value);
        return value && String(value)?.trim() !== '';
      }
      return true;
    }),
    phone: Yup.string()
      .required(language?.phone_required || 'Phone is required')
      .matches(/^\+?[1-9]\d{9,11}$/, language?.invalid_number || 'Invalid phone number'),
    email: Yup.string().email().required(language?.email_required || 'Email is required'), 
    IFSC: Yup.string().trim().test('check-vendor-ifsc', language?.bank_ifsc_required || 'Bank IFSC is required ', function (value) {
      if (this.parent.type?.toLowerCase() == 'vendor') {
        
        return value && value.trim() !== '';
      }
      return true;
    }),
    otherCity: Yup.string().trim().test('other-city', language?.other_city_name_required || 'Other city name is required ', function (value) {
      if (this.parent.city?.toLowerCase() == '+add city') {
        return value && value.trim() !== '';
      }
      return true;
    }),
  });
}


export const challanInitalValue = {
  id: "",
  truckNumber: "",
  taxInvoiceNumber: "",
  taxInvoiceDate: "",
  items: [{
    itemId: "",
    quantity: "",
    unitPrice: "",
    isBreakage: false,
    breakage: "",
    isTrack:true,
  }],
  truckFrontSide: "",
  truckBackSide: "",
  beforeUnload: "",
  afterUnload: "",
  challanFrontSide: "",
  challanBackSide: "",
  invoiceFrontSide: "",
  invoiceBackSide: "",
  otherFiles: [{ description: "", image: "" }, { description: "", image: "" }, { description: "", image: "" }, { description: "", image: "" }, { description: "", image: "" }],
  signature: "",
  remarks:"",
}

export const challanValidationSchema = (language={})=>{
  return Yup.object().shape({
    id: Yup.string().trim().required(language?.id_required || 'ID is required'),
    // truckNumber: Yup.string().trim().required('Truck number is required'),
    truckNumber: Yup.string().test('truckNumber-required', language?.truck_number_required || 'Truck number is required', function (value) {
      const isVendor = this.parent.type?.toLowerCase()=="vendor";
      if (isVendor && !value) {
        return false;
      }
      return true;
    }),
    // taxInvoiceNumber: Yup.string().trim().required('Tax invoice number is required'),
    taxInvoiceDate: Yup.date().required(language?.tax_invoice_date_required || 'Tax invoice date is required'),
    // truckFrontSide: Yup.string().trim().required('Truck front side image is required'),
    // truckBackSide: Yup.string().trim().required('Truck back side image is required'),
    // taxInvoiceDate: Yup.string().test('taxInvoiceDate-required', 'Tax invoice number is required', function (value) {
    //   const isVendor = this.parent.type?.toLowerCase()=="vendor";
    //   if (isVendor && !value) {
    //     return false;
    //   }
    //   return true;
    // }),
    truckFrontSide: Yup.string().test('truckFrontSide-required', language?.truck_front_side_image_required || 'Truck front side image is required', function (value) {
      const isVendor = this.parent.type?.toLowerCase()=="vendor";
      if (isVendor && !value) {
        return false;
      }
      return true;
    }),
    truckBackSide: Yup.string().test('truckBackSide-required', language?.truck_back_side_image_required || 'Truck back side image is required', function (value) {
      const isVendor = this.parent.type?.toLowerCase()=="vendor";
      if (isVendor && !value) {
        return false;
      }
      return true;
    }),
    // beforeUnload: Yup.string().trim().required('Before unload image is required'),
    // afterUnload: Yup.string().trim().required('After unload image is required'),
    challanFrontSide: Yup.string().trim().required(language?.challan_front_side_image_required || 'Challan front side image is required'),
    challanBackSide: Yup.string().trim().required(language?.challan_back_side_image_required || 'Challan back side image is required'),
    // invoiceFrontSide: Yup.string().trim().required('Invoice front side image is required'),
    // invoiceBackSide: Yup.string().trim().required('Invoice back side image is required'),
    otherFiles: Yup.array().of(
      Yup.object().shape({
        description: Yup.string().test('description-required', language?.description_required || 'Description is required', function (value) {
          const image = this.parent.image;
          if (image && image.length > 0) {
            return !!value;
          }
          return true;
        }),
        image: Yup.string()
      })
    ),
    signature: Yup.string().trim().required(language?.signature_required || 'Signature is required'),
    // items: Yup.array().min(1, 'At least one item is required'),
    items: Yup.array().min(1, language?.at_least_one_item_required || 'At least one item is required').of(
      Yup.object().shape({
        itemId: Yup.string().trim().required(language?.item_id_required || 'Item ID is required'),
        quantity: Yup.number().typeError(language?.quantity_positive_number || 'Quantity must be a number').positive(language?.quantity_greater_than_zero || 'Quantity must be a positive number').required(language?.quantity_required || 'Quantity is required'),
        // quantity: Yup.string().test('quantity', 'Quantity must be a positive number', function (value) {
        //   const { selected } = this.parent;
        //   if (selected) {
        //     if (!value || Number(value) <= 0) {
        //       return false
        //     }
        //   }
        //   return true;
        // }),
  
        unitPrice: Yup.number().typeError(language?.unit_price_must_be_number || 'Unit Price must be a number').positive(language?.unit_price_positive_number || 'Unit Price must be a positive number').required(language?.unit_price_required || 'Unit Price is required'),
      })
    ),
  });
}

export const uploadInitialValue = {
  documents: [{
    file: "",
    description: "",
    name: "",
  }]
}

export const uploadValidationSchema = (language={})=>{
  return Yup.object().shape({
    documents: Yup.array().of(
      Yup.object().shape({
        file: Yup.string().trim().required(language?.file_required || 'File is required'),
        description: Yup.string(),
        name: Yup.string().trim().required(language?.name_required || 'Name is required'),
      })
    )
  });
}


export const voucherInitialValue = {
  voucherNumber: "-",
  vendorType: "vendor",
  name: "",
  challanNumber: "",
  voucherType: "cash",
  expenseType: "outward",
  amount: "",
  signature: "",
  bankName: "",
  chequeNumber: "",
  chequeDate: "",
  remarks: "",
  isVisibleForOwnerOnly: false,
};



export const voucherValidationSchema = (language={})=>{
  return Yup.object().shape({
    vendorType: Yup.string().trim().required(language?.vendor_type_required || "Vendor type is required"),
    name: Yup.string().trim().required(language?.name_required || "Name is required"),
    challanNumber: Yup.string().trim().test('challan-number', language?.challan_number_required || 'Challan number is required ', function (value) {
      // if (this.parent.voucherType?.toLowerCase() !== 'cash') {
      //   return value && value.trim() !== '';
      // }
      if (this.parent.vendorType?.toLowerCase() === 'vendor') {
        return value && value.trim() !== '';
      }
  
      return true;
    }),
    voucherType: Yup.string().trim().required(language?.voucher_type_required || "Voucher type is required"),
    expenseType: Yup.string().trim().required(language?.expense_type_required || "Expense type is required"),
    amount: Yup.number().required(language?.required || "Amount is required").typeError(language?.amount_must_be_number || 'Amount must be a number'),
    signature: Yup.string().trim().test('cheque-number', language?.signature_required || 'Signature is required', function (value) {
      if (this.parent.voucherType?.toLowerCase() === 'cash') {
        return value && value.trim() !== '';
      }
      return true;
    }),
    bankName: Yup.string().trim().test('bank-name', language?.bank_name_required || 'Bank name is required', function (value) {
      if (this.parent.voucherType?.toLowerCase() !== 'cash') {
        return value && value.trim() !== '';
      }
      return true;
    }),
    chequeNumber: Yup.string().trim().test('cheque-number', language?.cheque_number_required || 'Cheque number is required ', function (value) {
      if (this.parent.voucherType?.toLowerCase() !== 'cash') {
        return value && value.trim() !== '';
      }
      return true;
    }),
    chequeDate: Yup.date().test('cheque-date', language?.cheque_date_required || 'Cheque date is required ', function (value) {
      if (this.parent.voucherType?.toLowerCase() !== 'cash') {
        return value instanceof Date && !isNaN(value);
      }
      return true;
    }),
  });
}

export const voucherFilterInitialvalues = {
  vendorType: "",
  name: "",
  challanNumber: "",
  voucherType: "",
  expenseType: "",
  dateType: "",
  to: "",
  from: "",
  status:""
}

export const voucherFilterValidationSchema = (language={})=>{
  return Yup.object().shape({
    to: Yup.string().trim().test('to', language?.range_required || 'Range is required ', function (value) {
      if (this.parent.dateType?.toLowerCase() === '3') {
        return value && value.trim() !== '';
      }
      return true;
    }),
    from: Yup.string().trim().test('from', language?.range_required || 'Range is required ', function (value) {
      if (this.parent.dateType?.toLowerCase() === '3') {
        return value && value.trim() !== '';
      }
      return true;
    }),
  })
}

export const reportFilterInitialValue = {
  uniqueItemId:"",
  reportType: "",
  vendorId: "",
  serialNumber: "",
  itemName: "",
  status: "",
  to: "",
  from: ""
}

export const reportValidationSchema = (language={})=>{
  return Yup.object().shape({
    to: Yup.string().trim().required(language?.date_to_required || 'Date to is required '),
    from: Yup.string().trim().required(language?.date_from_required || 'Date from is required '),
    reportType: Yup.string().trim().required(language?.report_type_required || 'Report type is required'),
    serialNumber: Yup.string().trim().test('serialNumber', language?.invalid_number || 'Invalid Number ', function (value) {
      if (value) {
        return validatePOChallanOrVoucherNumber.test(value);
      }
      return true;
    }),
  });
}


export const inventoryReportFilterInitialValue = {
  item: "",
  vendor: "",
  to: "",
  from: ""
}

export const inventoryReportValidationSchema = (language={})=>{
  return Yup.object().shape({
    to: Yup.string().trim().required(language?.date_to_required || 'Date to is required '),
    from: Yup.string().trim().required(language?.date_from_required || 'Date from is required '),
  });
}


export const inventoryItemInitialValue = {
  _id: "",
  isDisabled: true,
  name: "",
  localName: "",
  description: "",
  unitType: "",
  hsnCode: "",
  tax: "18",
  unitPrice: "",
  quantity: "",
  minQty:0,
  addedQty:0,
  isTrack:true,
  itemType:"fixed"
}

export const inventoryItemValidationSchema = (language={})=>{
  return Yup.object().shape({
    name: Yup.mixed().required(language?.required || 'Name is required'),
    // hsnCode: Yup.string().trim().required('HSN Code is required'),
    itemType: Yup.string().trim().required(language?.asset_type_required || 'Asset Type is required'),
    isTrack: Yup.boolean(),
    minQty: Yup.number().min(0,language?.min_qty_alert_zero || "Min Qty Alert is 0").required(language?.min_qty_alert_required || 'Min Qty Alert is required'),
  });
}