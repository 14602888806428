import React, { useEffect, useState } from 'react'
import { useUserContext } from '../../context/userContext';
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import Spinwheel from '../../components/common/Spinwheel';
import { challanApproveOrRejectApi, getChallansApi, submitBackApproveChallanApi } from '../../utils/ApiEndpointFunctions';
import toast from 'react-hot-toast';
import ConfirmationModal from '../../components/modal/ConfirmationModal';
import { formatPrice } from '../../utils/HelperFuncitons';
import { useFormik } from 'formik';
import Filter from '../../components/modal/Filter';
import moment from 'moment';
import ReactPaginate from 'react-paginate';
import StatusModal from '../../components/modal/StatusModal';

function Challan(props) {
  const { userDetails,langPreference } = useUserContext();
  const [challans, setChallans] = useState([])
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate();
  const [selectedChallan, setSelectedChallan] = useState({id:null,challanNo:null,status:null});
  const challanFilter = "challanfilter"
  const statusModalId = "statusModal"
  const approveConfirmModal = "approveConfirmModal"
  const rejectConfirmModal = "rejectConfirmModal";
  const limit = 10;
  const [page, setPage] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
    const [confirmationMsg, setConfirmationMsg] = useState("")
  // let [selectedPage, setSelectedPage] = useState(searchParams.get("page") == null ? 1 : searchParams.get("page"));
  const location = useLocation()

  const [totalCount, setTotalCount] = useState(null);

  const getChallans = async (filterReset) => {
    setLoading(true);
    try {
      let filter = filterReset === "reset" ? ["", "", "", "", "", ""] : [
        filterFormik.values?.uniqueItemId,
        `${filterFormik.values?.to ? new Date(filterFormik.values?.to).getTime() + "-" : ""}${filterFormik.values?.from ? new Date(filterFormik.values?.from).getTime() : ""}`,
        filterFormik.values?.hsnCode,
        filterFormik.values?.number,
        filterFormik.values?.status,
        filterFormik.values?.vendorId,
      ];
      const page = searchParams.get("page") || 1
      const res = await getChallansApi(...filter, limit, page);
      if (res.status === 200) {
        setChallans(res.data?.data?.challan);
        setTotalCount(res.data?.data?.totalCount);
        setPage(Math.ceil(res.data?.data?.totalCount / limit));
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  const handlePageClick = (event) => {
    let currentPage = event.selected + 1;
    // selectedPage = currentPage;
    // getChallans();
    // setSelectedPage(currentPage)
    // navigate(`${location.pathname}?${searchParams.get("tab") ? "tab=" + searchParams.get("tab") + "&" : ""}page=${selectedPage}`)

    const newParams = new URLSearchParams(searchParams);
    newParams.set("page", currentPage);

    if (newParams.has("tab")) {
      newParams.set("tab", newParams.get("tab"));
    }

    setSearchParams(newParams);
  };

  const statusHandler = (status) => {

    switch (status) {
      case "submitted":
        return <button className='sbm cursor-text text-capitalize '>submitted</button>
      case "rejected":
        return <button className='reject cursor-text text-capitalize'>Rejected</button>;
      case "approved":
        return <button className='approve cursor-text text-capitalize'>Approved</button>;
      default:
        return <button className='sbm cursor-text text-capitalize '>{status}</button>
    }
  }

  const approveChallanHandler = async () => {
    try {
      const payload ={
         id: selectedChallan?.id, status: selectedChallan?.status || 'approved'
      }
      const res = payload?.status=='approved' ? await challanApproveOrRejectApi(payload) :await submitBackApproveChallanApi(payload)
      if (res.status === 200) {
        toast.success(res.data?.message)
        setConfirmationMsg(res.data?.message || `Challan ${selectedChallan.challanNo || ""} is successfully ${selectedChallan?.status || "Approve"}.`)
        document.getElementById(approveConfirmModal).click()
        document.getElementById("statusModalBtn").click()
        getChallans()
      }
    } catch (error) {
      console.log(error)
      toast.error("something went wrong");
    }
  }

  const rejectChallanHandler = async () => {
    try {
      const res = await challanApproveOrRejectApi({ id: selectedChallan?.id, status: "rejected" })
      if (res.status === 200) {
        toast.success(res.data?.message)
        document.getElementById(rejectConfirmModal).click()
        getChallans()
      }
    } catch (error) {
      console.log(error)
      toast.error("something went wrong");
    }
  }

  const filterFormik = useFormik({
    initialValues: {
      Item: "",
      hsnCode: "",
      number: "",
      status: "",
      vendorName: "",
      uniqueItemId:"",
      vendorId:"",
      to: "",
      from: "",
      itemId:""
    }
  })

  // useEffect(() => {
  //   getChallans()
  // }, [])

  const handleFilterChallan = () => {
    if (searchParams.get('page') == 1) {
      getChallans()
    } else {
      setSearchParams({ "page": 1 })
    }
  }

  useEffect(() => {
    getChallans()
},[searchParams.get('page')])

  return (
    <>

      <div className="main-content">
        <div className="container-fluid">
          <div className="row">
            <div className="main-content">
              {props.type !== "dashboard" && <div className="dash-nav1">
                <div className="d-flex justify-content-between align-items-center">
                  <h3 className="mb-0 font-blue">{langPreference?.challan || "Challan"}</h3>
                </div>
              </div>}
              <div className=" ">
                <div className="d-flex align-items-center justify-content-end">
                  <div className="dropdown">
                    <button className="btn btn-dark me-2" type="button" disabled={!Object.values(filterFormik?.values).filter(Boolean).length} onClick={() => { filterFormik.resetForm(); getChallans("reset") }} >
                      {langPreference?.reset_filter || "Reset filter"}
                    </button>
                    <button className="btn filter  dropdown-toggle" type="button" data-bs-toggle="modal" data-bs-target={`#${challanFilter}`} >
                      <i className="bi bi-funnel me-2"></i>{langPreference?.filter || "Filter"}
                    </button>
                    {/* <ul className="dropdown-menu">
                    <li><a className="dropdown-item" href="#">Action</a></li>
                    <li><a className="dropdown-item" href="#">Another action</a></li>
                    <li><a className="dropdown-item" href="#">Something else here</a></li>
                  </ul> */}
                  </div>

                  {/* <!-- <form >
                                   <input className="form-control" type="search" placeholder="" aria-label="Search">
                               </form> --> */}
                </div>
                <div className="table-responsive" style={{ overflowX: "inherit" }}>
                  <table className="table d-table mt-4">
                    <thead>
                      <tr>
                        <th scope="col">{langPreference?.created_date || "CREATED DATE"}</th>
                        <th scope="col">{langPreference?.vendor_name || "VENDOR NAME"}</th>
                        <th scope="col">{langPreference?.po_number || "PO NUMBER"}</th>
                        <th scope="col">{langPreference?.challan_number || "CH NUMBER"}</th>
                        <th scope="col">{langPreference?.gst_number || "GST NUMBER"}</th>
                        <th scope="col">{langPreference?.item_counts || "ITEMS COUNT"}</th>
                        <th scope="col">{langPreference?.amount || "AMOUNT"}</th>
                        <th scope="col">{langPreference?.status || "STATUS"}</th>
                        <th scope="col">{langPreference?.action || "ACTION"}</th>
                      </tr>
                    </thead>
                    {
                      loading ?
                        <tbody >
                          <tr>
                            <td colSpan={12}>
                              <div className="d-flex justify-content-center">
                                <Spinwheel />
                              </div>
                            </td>
                          </tr>
                        </tbody> :
                        
                          <tbody className="table-group-divider">
                            {challans && challans.length > 0 ? challans?.map((ele, index) => {

                              return (
                                <tr key={index}>
                                  <td >{ele?.createDate ? moment(ele?.createDate).format("DD/MM/YYYY") : "-"}</td>
                                  <td>{ele?.purchaseOrder.vendor?.name ? ele?.purchaseOrder.vendor?.name : "-"}</td>
                                  <td>{ele?.purchaseOrder.poNumber ? ele?.purchaseOrder.poNumber : "-"}</td>
                                  <td>{ele?.challanNumber ? ele?.challanNumber : "-"}</td>
                                  <td>{ele?.purchaseOrder.vendor.gstNumber ? ele?.purchaseOrder.vendor.gstNumber : "-"}</td>
                                  <td>{ele?.challanItems?.length}</td>
                                  <td>{formatPrice(ele?.amount)}</td>
                                  <td>{statusHandler(ele?.status)}</td>
                                  <td>
                                    <div>
                                      <div className="dropdown">
                                        <button className="dots dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false"><i className="bi bi-three-dots-vertical"></i></button>
                                        <ul className="dropdown-menu">
                                          <li className='dropdown-item' onClick={() => { navigate(`/challan/view/${ele?._id}`) }}>{langPreference?.view || "View"}</li>
                                          {(ele?.status === "created" || ele?.status === "submitted") && userDetails?.roleId?.permission?.includes("challanUpdate") && <li className='dropdown-item' onClick={() => { navigate(`/challan/Edit/${ele?._id}`) }}>{langPreference?.edit || "Edit"}</li>}
                                          {ele?.status === "submitted" && userDetails?.roleId?.permission?.includes("challanApprove") && <li className='dropdown-item' data-bs-toggle="modal" data-bs-target={`#${approveConfirmModal}`} onClick={() => { setSelectedChallan({id:ele?._id,challanNo:ele?.challanNumber,status:"approved"}) }} >{langPreference?.approve || "Approve"}</li>}
                                          {ele?.status === "approved" && userDetails?.roleId?.permission?.includes("submitBackApproveChallan") && <li className='dropdown-item' data-bs-toggle="modal" data-bs-target={`#${approveConfirmModal}`} onClick={() => { setSelectedChallan({id:ele?._id,challanNo:ele?.challanNumber,status:"declined"}) }} >{langPreference?.reject || "Decline"}</li>}
                                        </ul>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              )
                            }) : <tr className='text-center'>
                              <td colSpan="12">
                                <h1>{langPreference?.data_not_found || "Data Not Found"}</h1>
                              </td>
                            </tr>}
                          </tbody> 
                    }
                  </table>
                </div>
              </div>


            </div>
          </div>
        </div>
      </div>
      {totalCount > limit ? (
        <div className="d-flex justify-content-center">
          <ReactPaginate
            nextLabel={`${langPreference?.next_page || "Next"} >`}
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            marginPagesDisplayed={1}
            pageCount={page}
            previousLabel={`< ${langPreference?.previous_page || "Previous"}>`}
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName={"active"}
            forcePage={searchParams.get('page')? Number(searchParams.get('page'))-1 : 0}
          />
        </div>
      ) : (
        ""
      )}
      <Filter modalId={challanFilter} formik={filterFormik} clickHandler={handleFilterChallan} isSiteItem={true}/>
      <ConfirmationModal modalId={approveConfirmModal} handleConfirm={approveChallanHandler} 
      message={selectedChallan?.status=="approved" ? langPreference?.approve_confirmation : (selectedChallan?.status=="declined" ? langPreference?.confirm_decline : <>Are you sure you want to <br /> <span className='text-capitalize'>{selectedChallan?.status || "Approve"}</span>  ?</>)} />
      <ConfirmationModal modalId={rejectConfirmModal} handleConfirm={rejectChallanHandler} 
      message={langPreference?.confirm_reject || <>Are you sure you want to <br /> Reject ?</>} />
      <button className='d-none' id="statusModalBtn" data-bs-toggle="modal" data-bs-target={`#${statusModalId}`} />
      <StatusModal
        modalId={statusModalId}
        message={confirmationMsg}
      />
    </>
  )
}

export default Challan