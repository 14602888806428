import React, { useEffect, useState } from 'react'
import { approveOrRejectVendorDprApi, getVendorDprApi } from '../../utils/ApiEndpointFunctions';
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import { MONTHS } from '../../utils/constant';
import Spinwheel from '../../components/common/Spinwheel';
import { useUserContext } from '../../context/userContext';
import toast from 'react-hot-toast';
import ConfirmationModal from '../../components/modal/ConfirmationModal';
import Filter from '../../components/modal/Filter';
import { useFormik } from 'formik';
import moment from 'moment/moment';
import ReactPaginate from 'react-paginate';
import { formatPrice } from '../../utils/HelperFuncitons';
import StatusModal from '../../components/modal/StatusModal';

export default function ViewDprTable(props) {
  const [dpr, setDpr] = useState([])
  const [loading, setLoading] = useState({ dpr: true })
  const navigate = useNavigate();
  const { userDetails,langPreference } = useUserContext();
  const confirmModalId = "conFirmModalID"
  const filterModalId = "filterModalID"
  const statusModalId = "statusModal"
  const [selectDpr, setSelectDpr] = useState({id:null,dprNo:null,status:null});
  const limit = 10;
  const [page, setPage] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const [confirmMessage,setConfirmMessage] = useState("")
  // let [selectedPage, setSelectedPage] = useState(searchParams.get("page") == null ? 1 : searchParams.get("page"));
  const location = useLocation()

  const [totalCount, setTotalCount] = useState(null);

  const getAllDprList = async (filterReset) => {
    setLoading({ ...loading, dpr: true });
    let filter = filterReset === "reset" ? ["", "", "", "", "", ""] : [
      filterFormik.values?.uniqueItemId,
      `${filterFormik.values?.to ? new Date(filterFormik.values?.to).getTime() + "-" : ""}${filterFormik.values?.from ? new Date(filterFormik.values?.from).getTime() : ""}`,
      filterFormik.values?.hsnCode,
      filterFormik.values?.number,
      filterFormik.values?.status,
      filterFormik.values?.vendorId,
    ];
    try {
      const page = searchParams.get("page") || 1
      const res = await getVendorDprApi(...filter, limit, page);
      if (res.status === 200) {
        setDpr(res.data?.data?.dpr);
        setTotalCount(res.data?.data?.totalCount);
        setPage(Math.ceil(res.data?.data?.totalCount / limit));
      }
    } catch (error) {
      console.log(error)
    }
    setLoading({ ...loading, dpr: false });
  }

  const handlePageClick = (event) => {
    let currentPage = event.selected + 1;

    const newParams = new URLSearchParams(searchParams);
    newParams.set("page", currentPage);

    if (newParams.has("tab")) {
      newParams.set("tab", newParams.get("tab"));
    }

    setSearchParams(newParams);
  };


  const handlePoApproval = async () => {
    try {
      const res = await approveOrRejectVendorDprApi({ id: selectDpr.id,status:selectDpr?.status })
      if (res.status === 200) {
        // toast.success(res.data?.message)
        setConfirmMessage(res.data?.message || `DPR ${selectDpr.dprNo || ""} is successfully  ${selectDpr?.status}.`)
        document.getElementById(confirmModalId).click()
        document.getElementById("statusModalBtn").click()
        getAllDprList()
      }
    } catch (error) {
      console.log(error)
      toast.error("something went wrong");
    }
  }


  const handleFilterPo = () => {
    if (searchParams.get('page') == 1) {
      getAllDprList()
    } else {
      setSearchParams({ "page": 1 })
    }
  }

  const filterFormik = useFormik({
    initialValues: {
      Item: "",
      hsnCode: "",
      number: "",
      status: "",
      vendorName: "",
      to: "",
      from: "",
      itemId:""
    }
  })


  useEffect(() => {
      getAllDprList()
  },[searchParams.get('page')])

  console.log("filterFormik",filterFormik?.values);
  

  return (
    <>

      <div className="main-content">
        <div className="container-fluid">
          <div className="row">
            <div className="main-content">

              {props.type !== "dashboard" && <div className="dash-nav1">
                <div className="d-flex justify-content-between align-items-center">
                  <h3 className="mb-0 font-blue">{langPreference?.daily_progress_report || "Daily Progress Report"}</h3>
                  {userDetails?.roleId?.permission?.includes("dprWrite") && <button onClick={() => { navigate("/dpr/add"); }} className="create-order mb-3"> {langPreference?.create_dpr || "Create DPR"}</button>}
                </div>
              </div>}
              <div className="d-flex justify-content-end align-items-end">
                <div className="d-flex align-items-center justify-content-end">
                  <div className="dropdown">
                    <button className="btn btn-dark me-2" type="button" disabled={!Object.values(filterFormik?.values).filter(Boolean).length} onClick={() => { filterFormik.resetForm(); getAllDprList("reset") }} >
                    {langPreference?.reset_filter || "Reset filter"}
                    </button>
                    <button className="btn filter  dropdown-toggle" type="button" data-bs-toggle="modal" data-bs-target={`#${filterModalId}`}  >
                      <i className="bi bi-funnel me-2"></i>{langPreference?.filter || "Filter"}
                    </button>
                  </div>
                </div>
              </div>
              {/* <!-- table --> */}
              <div className="table-responsive" style={{ overflowX: "inherit" }}>
                <table className="table d-table mt-4">
                  <thead>
                    <tr>
                      <th scope="col">{langPreference?.dpr_number || "DPR NUMBER"}</th>
                      <th scope="col">{langPreference?.item_counts || "ITEMS COUNT."}</th>
                      {/* <th scope="col">AMOUNT</th> */}
                      <th scope="col">{langPreference?.subcontractor_name || "Sub-contractor NAME"}</th>
                      <th scope="col">{langPreference?.gst_number || "GST NUMBER"}</th>
                      <th scope="col">{langPreference?.created_date || "CREATED DATE"}</th>
                      <th scope="col">{langPreference?.status || "STATUS"}</th>
                      <th scope="col">{langPreference?.action || "ACTION"}</th>
                    </tr>
                  </thead>
                  {
                    loading.dpr ?
                      <tbody >
                        <tr>
                          <td colSpan={12}>
                            <div className="d-flex justify-content-center">
                              <Spinwheel />
                            </div>
                          </td>
                        </tr>
                      </tbody> :
                     
                        <tbody>
                          {dpr?.length > 0 ? dpr?.map((ele, index) => {
                            return (
                              <tr key={index}>
                                <td>{ele?.dprNumber}</td>
                                <td>{ele?.items?.length}</td>
                                {/* <td>{formatPrice(ele?.amount)}</td> */}
                                <td>{ele?.vendor?.name}</td>
                                <td>{ele?.vendor?.gstNumber}</td>
                                <td>{moment(ele?.createDate).format("DD/MM/YYYY")}</td>
                                <td><button className={` cursor-text text-capitalize ${ele?.status?.toLowerCase() == "approved" ? "approve" : (ele?.status?.toLowerCase() == "rejected" ? "reject" : "sbm")} `}>{ele?.status}</button></td>
                                <td>
                                  <div className="d-flex justify-content-start align-items-center">
                                    <div>
                                      {userDetails?.roleId?.permission.includes("poReject") && ele?.status?.toLowerCase() !== "reject" && <button className="reject">Reject</button>}
                                    </div>
                                    <div>
                                      <div className="dropdown">
                                        <button className="dots dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false"><i className="bi bi-three-dots-vertical"></i></button>
                                        <ul className="dropdown-menu">
                                          {userDetails?.roleId?.permission.includes("dprRead") && <li className='dropdown-item' onClick={() => { navigate(`/dpr/view/${ele?._id}`) }} >{langPreference?.view || "View"}</li>}
                                          {(((userDetails?.roleId?.permission.includes("dprUpdate")) && ele?.status?.toLowerCase() != "approved" && ele?.status?.toLowerCase() != "rejected") || ele?.status?.toLowerCase() == "created") && <li className='dropdown-item' onClick={() => { navigate(`/dpr/update/${ele?._id}`) }} >{langPreference?.edit || "Edit"}</li>}
                                          {userDetails?.roleId?.permission.includes("dprApprove") && ele?.status?.toLowerCase() != "created" && ele?.status?.toLowerCase() != "approved" && ele?.status?.toLowerCase() != "rejected" &&
                                            <li className='dropdown-item' data-bs-toggle="modal" data-bs-target={`#${confirmModalId}`} onClick={() => { setSelectDpr({id:ele?._id,dprNo:ele?.dprNumber,status:"approved"}) }} >{langPreference?.approve || "Approve"}</li>}
                                            {userDetails?.roleId?.permission.includes("dprDecline") && ele?.status?.toLowerCase() == "approved"  && <li className='dropdown-item' data-bs-toggle="modal" data-bs-target={`#${confirmModalId}`} onClick={() => { setSelectDpr({id:ele?._id,dprNo:ele?.dprNumber,status:"rejected"}) }} >{langPreference?.reject ||"Reject"}</li>}
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                              </tr>)
                          }) : <tr className='text-center'>
                            <td colSpan="12">
                              <h1>{langPreference?.data_not_found || "Data Not Found"}</h1>
                            </td>
                          </tr>}
                        </tbody>}
                </table>
              </div>
              {totalCount > limit ? (
                <div className="d-flex justify-content-center">
                  <ReactPaginate
                    nextLabel={`${langPreference?.next_page || "Next"} >`}
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={3}
                    marginPagesDisplayed={1}
                    pageCount={page}
                    previousLabel={`< ${langPreference?.previous_page || "Previous"}>`}
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    breakLabel="..."
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    containerClassName="pagination"
                    activeClassName={"active"}
                    forcePage={searchParams.get('page')? Number(searchParams.get('page'))-1 : 0}
                  />
                </div>
              ) : (
                ""
              )}
              <ConfirmationModal modalId={confirmModalId} handleConfirm={handlePoApproval} message={selectDpr?.status=="approved" ? langPreference?.approve_confirmation : langPreference?.confirm_reject || <>Are you sure you want to <br /> {selectDpr?.status=="approved" ? "approve" : "reject"} ?</>} />
              <Filter modalId={filterModalId} formik={filterFormik} clickHandler={handleFilterPo} filterType={"dpr"} isSiteItem={true}/>
              <button className='d-none' id="statusModalBtn" data-bs-toggle="modal" data-bs-target={`#${statusModalId}`}/>
              <StatusModal 
                modalId={statusModalId}
                message={confirmMessage}
              />

            </div>
          </div>
        </div>
      </div>
    </>
  )
}
