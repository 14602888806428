import React, { useEffect, useState } from 'react'
import { useUserContext } from '../../context/userContext';

const ConfirmationModal = (props) => {
    const {modalId,message,handleConfirm,title,reasonRequired} = props;
    const [reason,setReason] = useState({value:"",error:false});
      const { userDetails,langPreference } = useUserContext();

const handleConfirmation = () => {
    (!reasonRequired || (reason?.value?.trim() && reasonRequired)) && handleConfirm(reason.value)
    if(!reason.value.trim() && reasonRequired){
        setReason({...reason,error:true})
    }
}

useEffect(()=>{
    setReason({value:"",error:false})
},[message])

    return (
        <div
            className="modal fade"
            id={modalId}
            tabIndex="-1"
            aria-labelledby="deleteModalLabel"
            aria-hidden="true"
        >
            <div className="modal-dialog modal-sm modal-dialog-centered" style={{maxWidth:"490px"}}>
                <div className="modal-content">
                    <div className="modal-header border-0">
                        <button
                            id = "confirmModalCloseBtn"
                            type="button"
                            className="btn-close block-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        ></button>
                    </div>
                    <div className="modal-body pe-0 ps-0 block-user">
                        <div className='fs-5 mb-2'>{title}</div>
                        {/* <img src="/images/blockuser.png" alt="" width={202} srcSet="" /> */}
                        <h4 className="mb-0 px-2 text-break">
                            {message}
                        </h4>
                    </div>
                    {reasonRequired && <div className='w-100 d-flex align-items-center flex-column my-2'>
                        <label htmlFor="reason" className={`form-label`}>{langPreference?.reason || "Reason"}<span className='req-error'>*</span></label>
                        <input
                            type="text"
                            className={`form-control w-75 ${reason?.error && "border-danger"}`}
                            name="reason"
                            value={reason.value}
                            placeholder={langPreference?.reason || "Reason"}
                            onChange={(e)=> setReason({error:false,value:e.target.value})}
                            style={{ height: "30px" }}
                        />
                    </div>}
                    
                    <div className="modal-footer pe-0 ps-0 border-0 justify-content-center pt-0 mb-3" style={{padding:"1.25rem"}}>
                        {props?.okbtn === "no" ? <button
                            type="button"
                            className="btn-custom w-auto text-white"
                            data-bs-dismiss="modal"
                        >
                            Ok
                        </button>: <>
                        <button
                            type="button"
                            className="btn-custom w-auto text-white"
                            onClick={handleConfirmation}
                            data-bs-dismiss={(!reasonRequired || (reason?.value?.trim() && reasonRequired)) ? "modal" :""}
                        >
                          {langPreference?.yes || "Yes"}  
                        </button>
                        <button
                            type="button"
                            className="btn-custom w-auto bg-secondary "
                            data-bs-dismiss="modal"
                        >
                        {langPreference?.no || "No"}  
                        </button>
                        </>}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ConfirmationModal