import React from 'react'
import { useUserContext } from '../../context/userContext'

const StatusModal = ({ modalId, message,handleClose }) => {
    const {langPreference} = useUserContext()
    return (
        <>
            <div className="modal fade" id={`${modalId}`} tabIndex="-1" aria-labelledby="approvalModalLabel" aria-hidden="true" data-bs-backdrop="static">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header border-0 ">
                            {/* <h5 className="modal-title" id="approvalModalLabel">Approved Successfully</h5> */}

                            <button type="button" id="confirmModalCloseBtn" className="btn-close1" data-bs-dismiss="modal" onClick={()=>handleClose && handleClose()} aria-label="Close"><i className="bi bi-x-lg"></i></button>
                        </div>
                        <div className="modal-body text-center">
                            {/* <i className="bi bi-check2-circle text-success" style={{ fontSize: "3em" }}></i> */}
                            <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 193.712 192.389">
                                <g id="Group_9388" data-name="Group 9388" transform="translate(-69.196 -155.889)">
                                    <path id="Path_3734" data-name="Path 3734" d="M64.524,0c37.821,0,86.386,36.384,86.386,75.789s-41.523,67.924-79.343,67.924S0,118.518,0,79.113,26.7,0,64.524,0Z" transform="translate(262.909 207.391) rotate(111)" fill="rgba(191,232,212,0.26)" />
                                    <path id="Path_3733" data-name="Path 3733" d="M55.947,0c32.793,0,74.9,31.548,74.9,65.714s-36,58.9-68.8,58.9S0,102.763,0,68.6,23.154,0,55.947,0Z" transform="translate(238.765 194.533) rotate(97)" fill="rgba(191,232,212,0.37)" />
                                    <path id="Path_3726" data-name="Path 3726" d="M48.663,0c28.524,0,65.151,27.441,65.151,57.159S82.5,108.387,53.975,108.387,0,89.385,0,59.666,20.139,0,48.663,0Z" transform="matrix(0.799, 0.602, -0.602, 0.799, 156.208, 174.248)" fill="rgba(191,232,212,0.52)" />
                                    <g id="Group_8020" data-name="Group 8020" transform="translate(70.964 159.504)">
                                        <path id="Path_3739" data-name="Path 3739" d="M9812.817,3729.09s9.136,4.12,11.644-8.06,10.388-9.673,10.388-9.673" transform="translate(-9660.288 -3697.967)" fill="none" stroke="#df1b1b" strokeLinecap="round" strokeWidth="3" />
                                        <path id="Path_3740" data-name="Path 3740" d="M9799.54,3867.4s9.221,2.412,7.66,9.363" transform="translate(-9658.949 -3726.372)" fill="none" stroke="#87ade5" strokeLinecap="round" strokeWidth="3" />
                                        <path id="Path_3741" data-name="Path 3741" d="M9645.477,3825.789s-6.355,9.146-13.021,2.635,8.835-8.681,4.03,0-15.5,6.51-15.5,6.51" transform="translate(-9620.985 -3717.956)" fill="none" stroke="#f3bf62" strokeLinecap="round" strokeWidth="3" />
                                        <circle id="Ellipse_1871" data-name="Ellipse 1871" cx="4.941" cy="4.941" r="4.941" transform="translate(23.29 56.824)" fill="#fac160" />
                                        <path id="Path_3742" data-name="Path 3742" d="M6.588,0A6.588,6.588,0,1,1,0,6.588,6.588,6.588,0,0,1,6.588,0Z" transform="translate(72.703 0)" fill="#064095" />
                                        <circle id="Ellipse_1873" data-name="Ellipse 1873" cx="3.294" cy="3.294" r="3.294" transform="translate(93.291 14)" fill="#fac160" />
                                        <circle id="Ellipse_1874" data-name="Ellipse 1874" cx="4.941" cy="4.941" r="4.941" transform="translate(158.351 74.119)" fill="#031227" />
                                        <circle id="Ellipse_1875" data-name="Ellipse 1875" cx="6.588" cy="6.588" r="6.588" transform="translate(108.115 156.473)" fill="#4f89df" />
                                        <circle id="Ellipse_1876" data-name="Ellipse 1876" cx="3.294" cy="3.294" r="3.294" transform="translate(93.291 153.179)" fill="#00b880" />
                                        <circle id="Ellipse_1877" data-name="Ellipse 1877" cx="4.941" cy="4.941" r="4.941" transform="translate(36.467 140.826)" fill="#00b880" />
                                    </g>
                                    <path id="Path_3762" data-name="Path 3762" d="M10808.922,910.145l14.938-10.838,14.647,15.818,34.858-31.929,10.546,11.717-45.4,45.4Z" transform="translate(-10678.697 -661.171)" fill="#fff" />
                                    <path id="Icon_ionic-md-checkmark-circle" data-name="Icon ionic-md-checkmark-circle" d="M48.324,3.375A44.949,44.949,0,1,0,93.273,48.324,45.081,45.081,0,0,0,48.324,3.375ZM39.1,72.29,16.06,49.253l6.461-6.461,16.6,16.6,35.03-35.03L80.61,30.82Z" transform="translate(120.242 203.51)" fill="#00b880" />
                                </g>
                            </svg>
                            <p className="mt-3">{message}</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={()=>handleClose && handleClose()}>{langPreference?.done || "Done"}</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default StatusModal