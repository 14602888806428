import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
// import Logo from "./"
import { useLocation } from 'react-router-dom';
import { useUserContext } from '../../context/userContext';

export default function MainSidebar() {
  const navigate = useNavigate();
  const location = useLocation()
  const token = localStorage.getItem("token")
  const { userDetails,langPreference } = useUserContext();

  const handleLogout = () => {
    localStorage.removeItem("token");

    window.location.reload();
    navigate("/");
  }

  const path = location.pathname
  return (
    <>
      {token && <div className="side-nav">
        <div className="nav flex-column nav-pills " id="v-pills-tab" role="tablist" aria-orientation="vertical">
          <Link to="/dashboard" className={`nav-link ${path.includes("dashboard") ? "active" : ""}`}> <i className="bi bi-house-door-fill"></i>{langPreference?.dashboard || "Dashboard"} </Link>
          {userDetails?.roleId?.permission.includes("poRead") &&
            <Link to="/purchase" className={`nav-link ${path.includes("purchase") ? "active" : ""}`}><span className='pr-2'><img src='/purchase_order.png' className='tab-icon'/></span> {langPreference?.purchase_order || "Purchase Order"}</Link>
          }
          {userDetails?.roleId?.permission.includes("challanRead") &&
            <Link to="/challan" className={`nav-link ${path.includes("challan") ? "active" : ""}`} ><span className='pr-2'><img src='/challan.png' className='tab-icon'/></span> {langPreference?.challan || "Challan"}</Link>
          }
          {userDetails?.roleId?.permission.includes("voucherRead") &&
            <Link to="/voucher" className={`nav-link ${path.includes("voucher") ? "active" : ""}`} ><span className='pr-2'><img src='/voucher.png' className='tab-icon'/></span> {langPreference?.voucher || "Voucher"}</Link>
          }
            {userDetails?.roleId?.permission.includes("cashbookRead") &&
          <Link to="/cashbook" className={`nav-link ${path.includes("cashbook") ? "active" : ""}`} ><span className='pr-2'><img src='/my_cashbook.png' className='tab-icon'/></span>{langPreference?.my_cashbook || "My Cashbook"}</Link>}
          {userDetails?.roleId?.permission.includes("uploadRead") && <Link to="/upload" className={`nav-link ${path.includes("upload") ? "active" : ""}`}><span className='pr-2'><img src='/upload.png' className='tab-icon'/></span> {langPreference?.upload || "Upload"}</Link>}
          {userDetails?.roleId?.permission.includes("reportRead") && <Link to="/report" className={`nav-link ${path.includes("report") && !path.includes("dlr") && !path.includes("inventory") ? "active" : ""}`}><span className='pr-2'><img src='/my_cashbook.png' className='tab-icon'/></span> {langPreference?.reports || "Reports"}</Link>}

         

          {(userDetails?.roleId?.permission.includes("inventoryRead") || userDetails?.roleId?.permission.includes("ginRead")) && <div class="accordion accordion-flush" id="accordionFlushExample">
            <div class="accordion-item">
              <h2 class="accordion-header" id="flush-headingOne">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                {langPreference?.inventory_management || "Inventory management"}
                </button>
              </h2>
              <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                <div class="accordion-body">
                  {userDetails?.roleId?.permission.includes("inventoryRead") && <Link to="/inventory" className={`nav-link ${path.includes("inventory") ? "active" : ""}`}><span className='pr-2'><img src='/inventory.png' className='tab-icon' /></span> {langPreference?.inventory_setup || "Inventory Setup"}</Link>}

                  {userDetails?.roleId?.permission.includes("ginRead") && <Link to="/gin" className={`nav-link ${path.includes("gin") && !path.includes("goods-return") ? "active" : ""}`}><span className='pr-2'><img src='/goods.png' className='tab-icon' /></span> {langPreference?.goods_issued_note || "Goods Issued Note"}</Link>}
                  {userDetails?.roleId?.permission.includes("ginRead") && <Link to="/goods-return" className={`nav-link ${path.includes("goods-return") ? "active" : ""}`}><span className='pr-2'><img src='/goods.png' className='tab-icon' /></span> {langPreference?.goods_return_note || "Goods Return Note"}</Link>}
                </div>
              </div> 
            </div>
          </div>}


          {/* {userDetails?.roleId?.permission.includes("inventoryRead") && <Link to="/inventory" className={`nav-link ${path.includes("inventory") ? "active" : ""}`}><span className='pr-2'><img src='/inventory.png' className='tab-icon'/></span> Inventory management</Link>}
          
          {userDetails?.roleId?.permission.includes("ginRead") && <Link to="/gin" className={`nav-link ${path.includes("gin") ? "active" : ""}`}><span className='pr-2'><img src='/goods.png' className='tab-icon'/></span> GIN</Link>} */}
          {(userDetails?.roleId?.permission.includes("dprRead") || userDetails?.roleId?.permission.includes("dlrRead")) && <div class="accordion accordion-flush" id="daily_report">
            <div class="accordion-item">
              <h2 class="accordion-header" id="flush-headingOne">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne-daily_report" aria-expanded="false" aria-controls="flush-collapseOne">
                {langPreference?.daily_report || "Daily report"}
                </button>
              </h2>
              <div id="flush-collapseOne-daily_report" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#daily_report">
                <div class="accordion-body">
               {userDetails?.roleId?.permission.includes("dprRead") && <Link to="/dpr" className={`nav-link ${path.includes("dpr") ? "active" : ""}`}><span className='pr-2'><img src='/dpr.png' className='tab-icon'/></span>  {langPreference?.daily_progress_report || "Daily Progress Report"}</Link>}
              {userDetails?.roleId?.permission.includes("dlrRead") && <Link to="/dlr" className={`nav-link ${path.includes("dlr") ? "active" : ""}`}><span className='pr-2'><img src='/dpr.png' className='tab-icon'/></span>  {langPreference?.daily_labour_report || "Daily Labour Report"}</Link>}
                </div>
              </div> 
            </div>
          </div>}


          {userDetails?.roleId?.permission.includes("boqRead") && <Link to="/boq" className={`nav-link ${path.includes("boq") ? "active" : ""}`}><span className='pr-2'><img src='/dpr.png' className='tab-icon'/></span> {langPreference?.boq || "BOQ"}</Link>}
          {/* <div className="logout">
            <Link to="/" data-bs-toggle="modal"
              data-bs-target="#exampleModalout1" className="nav-link">
              <i className="bi bi-box-arrow-left"></i>
              Log Out
            </Link>
          </div> */}

        </div>
        {/* Log out popup code start here */}

      </div>}
    </>

  )
}
