import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { dprAddInitalValue, dprAddValidationSchema } from '../../utils/Validation'
import { useUserContext } from '../../context/userContext';
import { createVendorDprApi } from '../../utils/ApiEndpointFunctions';
import toast from 'react-hot-toast';
import ConfirmationModal from '../modal/ConfirmationModal';
import Spinwheel from './Spinwheel';

export default function DprItemComp({ values,position,isCopy,_id, dprId,itemCopyHandler,removeItemHandler,addLoader,details,handleSave,onCancel}) {
   const { userDetails, langPreference } = useUserContext();
   const [isSaveDraft, setIsSaveDraft] = useState({status:false,index:null})
   const [seleteRemoveableItem, setSelectRemoveablItem] = useState({saveId:null,index:null})
   const removeItemModalId = `removeDprItem${position}ModalId`

      

   const dprFormik = useFormik({
      initialValues: (values && details) ? {...details,items:[values]} : dprAddInitalValue,
      onSubmit: ()=>{},
      validationSchema: dprAddValidationSchema(langPreference)
   })


   
   const checkHandler = (e, ele, index, type) => {
      let items = dprFormik.values.items.map((item, i) => {
         if (item.itemId === ele.itemId && (index === i)) {
            return {
            ...item,
            ...(type == "isDiameter" ? { isDiameter: e.target.checked } : { selected: e.target.checked }),
            // selected: e.target.checked,
          }
        } else {
           return item;
         }
      });
  
      dprFormik.setFieldValue("items", items)
   }

   const dprSaveSubmit = async (value, index) => {
      let payload = JSON.parse(JSON.stringify(details));
    
      payload.items = [value]

      if(_id){
         payload._id = _id
      }
  
      try {
        const res =  await createVendorDprApi({...payload,isSaveDraft:true});
        if (res.status === 200) {
          toast.success(res.data.message);
          setIsSaveDraft({status:false,index:null})
          handleSave(res?.data?.data,{
            ...value,isSaved: true,
            saveId: res.data?.data?.items[0]?.saveId},position)

          let updatedList = dprFormik?.values?.items?.map((ele, ind) => {
            if (ind === index) {
              return {
                ...ele,
                isSaved: true,
                saveId: res.data?.data?.items[0]?.saveId
              }
            }
            return ele
          })
  
          dprFormik?.setFieldValue("items", updatedList)
          console.log("updatedList",updatedList,dprFormik?.values?.items)
  
        }
      } catch (error) {
        console.log("error",error);
        
        error && error.response ?
          toast.error(error.response?.data?.message) :
          toast.error("something went wrong");
          setIsSaveDraft({status:false,index:null})
      }
  
    }
   
   const checkSaveValidation = (btn,index) => {
      dprFormik.handleSubmit()
      const errors = Object.keys(dprFormik.errors)
      console.log("checkSaveValidation",errors);
      const value = dprFormik?.values?.items?.filter((ele,ind)=>index==ind)?.[0]
      if (errors?.length > 0) {
        if(dprFormik?.errors?.items?.filter((ele,ind)=>index==ind)?.[0]){       
          dprFormik.handleSubmit()
        }else{
          if(!value){
            toast.error("Please select item")
          } 
        }   
      }else{
         dprSaveSubmit(value, index)
         setIsSaveDraft({status:true,index:index})
      }
    }

    const handleAddItemQty =(itemIndex, qtyIndex)=>{
      let newItemQty = {
        length: "0.00",
        breath: "0.00",
        height: "1.00",
        number1: "1",
        number2: "1",
        diameter: "0.00",
        remarks: "",
        subPart: "",
      }
    
       let newList = dprFormik.values.items.map((item,ind)=>{
        if(itemIndex==ind){
          let updatedItemQty = []
          item?.itemQty?.forEach((ele,index)=>{
            if(qtyIndex==index){
              updatedItemQty.push(ele,newItemQty)
            }else{
              updatedItemQty.push(ele)
            }
          })
          return {
            ...item,
            itemQty:updatedItemQty
          }
        }else{
          return item
        }
       })
       dprFormik.setFieldValue("items",newList)
    }

    const handleRemoveItemQty =(itemIndex, qtyIndex)=>{
      let newList = dprFormik.values.items.map((item,ind)=>{
        if(itemIndex==ind){
          return {
            ...item,
            itemQty:item?.itemQty?.filter((ele,index)=>qtyIndex!=index)
          }
        }else{
          return item
        }
       }) 
       dprFormik.setFieldValue("items",newList)
    }

    const getItemVolumeQty = (e, values) => {
      if (e?.selected && values) {
        const {isDiameter} = e
        const { number1, number2, length, breath, height, diameter } = values
        if (isDiameter) {
          return Number((Number(number1 || 0) * Number(number2 || 0) * Number(height || 0)).toFixed(2))
        } else {
          return Number(Number(number1 || 0) * Number(number2 || 0) * Number(length || 0) * Number(breath || 0) * Number(height || 0)).toFixed(2)
        }
      }
      return "0.00"
    }
  
    const getVolumeQty = (e) => {
      if (e?.selected) {
        const {isDiameter,itemQty} = e
        let totalQty = 0;
        itemQty?.forEach(ele=>{
          // if(itemQty){}
          const { number1, number2, length, breath, height, diameter } = ele
          if (isDiameter) {
            totalQty+= Number((Number(number1 || 0) * Number(number2 || 0) * Number(height || 0)).toFixed(2))
          } else {
            totalQty+= Number(Number(Number(number1 || 0) * Number(number2 || 0) * Number(length || 0) * Number(breath || 0) * Number(height || 0)).toFixed(2))
          }
        })
        return Number(totalQty).toFixed(2)
      }
      return "0.00"
    }

   useEffect(() => {
      if (values) {
         dprFormik.setValues(details)
         dprFormik.setFieldValue("items",[values])
         dprFormik?.validateForm()
      }
   }, [values])

   console.log("position",values);
   

   return (
      <div>
         {dprFormik?.values?.items?.map((ele, index) => (
            <div className='mb-3' key={index + "sdkjfdkj"}>
               {/* First Row: Basic Details */}
               <div className='d-flex justify-content-between'>
                  <div >
                     {!dprId && <input
                        type="checkbox"
                        className="form-check-input"
                        checked={dprFormik?.values?.items?.[index]?.selected}
                        name={`items[${index}].selected`}
                        onChange={(e) => checkHandler(e, ele, index)}
                     />}
                     <span className='fw-bold'>
                        {ele?.itemDetails?.name}
                     </span>
                  </div>

                  <span >
                     <i
                        className="bi bi-trash3 point"
                        data-bs-toggle="modal"
                        data-bs-target={`#${removeItemModalId}`}
                        onClick={() => {
                           setSelectRemoveablItem({ saveId: ele?.saveId, index: index })
                        }}
                     ></i>
                  </span>
               </div>
               {/* Second Row: Description */}
               <div>
                  <div className='d-flex justify-content-between'>
                     <div className='d-flex gap-2'>
                        <label htmlFor='isDiameter'>{langPreference?.description || "Description"}: </label>
                        {ele?.itemDetails?.description}</div>

                  </div>

                  <div className='d-flex align-items-center gap-2 w-100'>
                     <div className='w-100'>
                        <label htmlFor='commodityPart'>{langPreference?.commodity_part || "Commodity Part"} <span className='req-error'>*</span></label>
                        <input
                           type="text"
                           className="form-control"
                           name={`items[${index}].commodityPart`}
                           value={dprFormik?.values?.items?.[index]?.commodityPart}
                           onChange={dprFormik.handleChange}
                           onBlur={dprFormik.handleBlur}
                           disabled={!dprFormik?.values?.items?.[index]?.selected}
                        />
                        {dprFormik?.touched?.items?.[index]?.commodityPart && dprFormik?.errors?.items?.[index]?.commodityPart ? (<div className='req-error'>{dprFormik?.errors?.items?.[index]?.commodityPart}</div>) : null}
                     </div>
                     <div className='w-100'>
                        <label htmlFor='commoditySubPart'>{langPreference?.commodity_sub_part || "Commodity Sub Part"}</label>
                        <input
                           type="text"
                           className="form-control"
                           name={`items[${index}].commoditySubPart`}
                           value={dprFormik?.values?.items?.[index]?.commoditySubPart}
                           onChange={dprFormik.handleChange}
                           onBlur={dprFormik.handleBlur}
                           disabled={!dprFormik?.values?.items?.[index]?.selected}
                        />
                        {dprFormik?.touched?.items?.[index]?.commoditySubPart && dprFormik?.errors?.items?.[index]?.commoditySubPart ? (<div className='req-error'>{dprFormik?.errors?.items?.[index]?.commoditySubPart}</div>) : null}
                     </div>
                     <div className='w-100 mt-1'>
                        <label htmlFor='workDescription'>{langPreference?.work_description || "Work Description"}</label>
                        <input
                           className="form-control w-100"
                           name={`items[${index}].workDescription`}
                           value={dprFormik?.values?.items?.[index]?.workDescription}
                           onChange={dprFormik.handleChange}
                           onBlur={dprFormik.handleBlur}
                           disabled={!dprFormik?.values?.items?.[index]?.selected}
                        />
                        {dprFormik?.touched?.items?.[index]?.workDescription &&
                           dprFormik?.errors?.items?.[index]?.workDescription ? (
                           <div className="req-error">
                              {dprFormik?.errors?.items?.[index]?.workDescription}
                           </div>
                        ) : null}
                     </div>
                  </div>
                  <div className='d-flex justify-content-between'>
                     <div>
                        <input
                           type="checkbox"
                           className="form-check-input"
                           checked={dprFormik?.values?.items?.[index]?.isDiameter}
                           name={`items[${index}].isDiameter`}
                           onChange={(e) => checkHandler(e, ele, index, "isDiameter")}
                           disabled={!dprFormik?.values?.items?.[index]?.selected}
                        />
                        <label htmlFor='isDiameter'>{langPreference?.diameter_required || "Diameter required"}</label>
                     </div>
                     {dprFormik?.values?.items?.[index]?.isDiameter &&
                        <div>
                           <span className='fw-bold'>{langPreference?.item_type || "Item type"}: </span>
                           <span>{langPreference?.circular || "Circular"}</span>
                        </div>}
                  </div>
               </div>

               {/* Third Row: Dimensions */}
               <div className='w-100 mt-1'>
                  <div className="table-responsive" style={{ overflowX: "auto" }}>
                     <table className="table table-bordered" style={{ minWidth: "200px" }}>
                        <thead>
                           <tr>
                              <th style={{ minWidth: "100px" }}>{langPreference?.sub_part || "Sub Part"} <span className='req-error'>*</span></th>
                              <th>{langPreference?.no1 || "No1"} <span className='req-error'>*</span></th>
                              <th>{langPreference?.no2 || "No2"} <span className='req-error'>*</span></th>
                              {!dprFormik?.values?.items?.[index]?.isDiameter && <>
                                 <th>{langPreference?.length || "L"} <span className='req-error'>*</span></th>
                                 <th>{langPreference?.breadth || "B"} <span className='req-error'>*</span></th>
                              </>}
                              <th>{langPreference?.height || "H"} <span className='req-error'>*</span></th>
                              {dprFormik?.values?.items?.[index]?.isDiameter && <th>{langPreference?.diameter || "D"} <span className='req-error'>*</span></th>}
                              <th>{langPreference?.qty || "QTY"} </th>
                              <th style={{ minWidth: "100px" }}>{langPreference?.remarks || "Remarks"}</th>
                           </tr>
                        </thead>
                        <tbody>
                           {ele?.itemQty?.map((qty, qtyIndex) => (
                              <tr key={qtyIndex} style={{ height: "50px" }}>
                                 <td style={{ width: "150px !important" }}>
                                    <input
                                       type="text"
                                       className="form-control"
                                       name={`items[${index}].itemQty[${qtyIndex}].subPart`}
                                       value={dprFormik?.values?.items?.[index]?.itemQty?.[qtyIndex]?.subPart}
                                       onChange={dprFormik.handleChange}
                                       onBlur={dprFormik.handleBlur}
                                       disabled={!dprFormik?.values?.items?.[index]?.selected}
                                       style={{ height: "30px" }}
                                    />
                                    {dprFormik?.touched?.items?.[index]?.itemQty?.[qtyIndex]?.subPart && dprFormik?.errors?.items?.[index]?.itemQty?.[qtyIndex]?.subPart ? (<div className='req-error'>{dprFormik?.errors?.items?.[index]?.itemQty?.[qtyIndex]?.subPart}</div>) : null}
                                 </td>
                                 <td>
                                    <input
                                       type="text"
                                       className="form-control"
                                       name={`items[${index}].itemQty[${qtyIndex}].number1`}
                                       value={dprFormik?.values?.items?.[index]?.itemQty?.[qtyIndex]?.number1}
                                       onChange={dprFormik.handleChange}
                                       onBlur={dprFormik.handleBlur}
                                       disabled={!dprFormik?.values?.items?.[index]?.selected}
                                       style={{ height: "30px" }}
                                    />
                                    {dprFormik?.touched?.items?.[index]?.itemQty?.[qtyIndex]?.number1 && dprFormik?.errors?.items?.[index]?.itemQty?.[qtyIndex]?.number1 ? (<div className='req-error'>{dprFormik?.errors?.items?.[index]?.itemQty?.[qtyIndex]?.number1}</div>) : null}
                                 </td>
                                 <td>
                                    <input
                                       type="text"
                                       className="form-control"
                                       name={`items[${index}].itemQty[${qtyIndex}].number2`}
                                       value={dprFormik?.values?.items?.[index]?.itemQty?.[qtyIndex]?.number2}
                                       onChange={dprFormik.handleChange}
                                       onBlur={dprFormik.handleBlur}
                                       disabled={!dprFormik?.values?.items?.[index]?.selected}
                                       style={{ height: "30px" }}
                                    />
                                    {dprFormik?.touched?.items?.[index]?.itemQty?.[qtyIndex]?.number2 && dprFormik?.errors?.items?.[index]?.itemQty?.[qtyIndex]?.number2 ? (<div className='req-error'>{dprFormik?.errors?.items?.[index]?.itemQty?.[qtyIndex]?.number2}</div>) : null}
                                 </td>
                                 {!dprFormik?.values?.items?.[index]?.isDiameter && <>
                                    <td>
                                       <input
                                          type="text"
                                          className="form-control"
                                          name={`items[${index}].itemQty[${qtyIndex}].length`}
                                          value={dprFormik?.values?.items?.[index]?.itemQty?.[qtyIndex]?.length}
                                          onChange={(e) => { (e.target?.value?.split(".")[1]?.length < 3 || e.target?.value?.split(".")[1]?.length === undefined) && dprFormik.handleChange(e) }}
                                          onBlur={dprFormik.handleBlur}
                                          disabled={!dprFormik?.values?.items?.[index]?.selected}
                                          style={{ height: "30px" }}
                                       />
                                       {dprFormik?.touched?.items?.[index]?.itemQty?.[qtyIndex]?.length && dprFormik?.errors?.items?.[index]?.itemQty?.[qtyIndex]?.length ? (<div className='req-error'>{dprFormik?.errors?.items?.[index]?.itemQty?.[qtyIndex]?.length}</div>) : null}
                                    </td>
                                    <td>
                                       <input
                                          type="text"
                                          className="form-control"
                                          name={`items[${index}].itemQty[${qtyIndex}].breath`}
                                          value={dprFormik?.values?.items?.[index]?.itemQty?.[qtyIndex]?.breath}
                                          onChange={(e) => { (e.target?.value?.split(".")[1]?.length < 3 || e.target?.value?.split(".")[1]?.length === undefined) && dprFormik.handleChange(e) }}
                                          onBlur={dprFormik.handleBlur}
                                          disabled={!dprFormik?.values?.items?.[index]?.selected}
                                          style={{ height: "30px" }}
                                       />
                                       {dprFormik?.touched?.items?.[index]?.itemQty?.[qtyIndex]?.breath && dprFormik?.errors?.items?.[index]?.itemQty?.[qtyIndex]?.breath ? (<div className='req-error'>{dprFormik?.errors?.items?.[index]?.itemQty?.[qtyIndex]?.breath}</div>) : null}
                                    </td>
                                 </>}
                                 <td>
                                    <input
                                       type="text"
                                       className="form-control"
                                       name={`items[${index}].itemQty[${qtyIndex}].height`}
                                       value={dprFormik?.values?.items?.[index]?.itemQty?.[qtyIndex]?.height}
                                       onChange={(e) => { (e.target?.value?.split(".")[1]?.length < 3 || e.target?.value?.split(".")[1]?.length === undefined) && dprFormik.handleChange(e) }}
                                       onBlur={dprFormik.handleBlur}
                                       disabled={!dprFormik?.values?.items?.[index]?.selected}
                                       style={{ height: "30px" }}
                                    />
                                    {dprFormik?.touched?.items?.[index]?.itemQty?.[qtyIndex]?.height && dprFormik?.errors?.items?.[index]?.itemQty?.[qtyIndex]?.height ? (<div className='req-error'>{dprFormik?.errors?.items?.[index]?.itemQty?.[qtyIndex]?.height}</div>) : null}
                                 </td>

                                 {dprFormik?.values?.items?.[index]?.isDiameter && (
                                    <td>
                                       <input
                                          type="text"
                                          className="form-control"
                                          name={`items[${index}].itemQty[${qtyIndex}].diameter`}
                                          value={dprFormik?.values?.items?.[index]?.itemQty?.[qtyIndex]?.diameter}
                                          onChange={dprFormik.handleChange}
                                          onBlur={dprFormik.handleBlur}
                                          disabled={!dprFormik?.values?.items?.[index]?.selected}
                                          style={{ height: "30px" }}
                                       />
                                       {dprFormik?.touched?.items?.[index]?.itemQty?.[qtyIndex]?.diameter && dprFormik?.errors?.items?.[index]?.itemQty?.[qtyIndex]?.diameter ? (<div className='req-error'>{dprFormik?.errors?.items?.[index]?.itemQty?.[qtyIndex]?.diameter}</div>) : null}
                                    </td>
                                 )}
                                 <td>{getItemVolumeQty(dprFormik?.values?.items?.[index], dprFormik?.values?.items?.[index]?.itemQty?.[qtyIndex])}</td>
                                 <td style={{ width: "450px !important" }} className='d-flex gap-2'>
                                    <input
                                       type="text"
                                       className="form-control"
                                       name={`items[${index}].itemQty[${qtyIndex}].remarks`}
                                       value={dprFormik?.values?.items?.[index]?.itemQty?.[qtyIndex]?.remarks}
                                       onChange={dprFormik.handleChange}
                                       onBlur={dprFormik.handleBlur}
                                       disabled={!dprFormik?.values?.items?.[index]?.selected}
                                       style={{ height: "30px" }}
                                    />
                                    {dprFormik?.touched?.items?.[index]?.itemQty?.[qtyIndex]?.remarks && dprFormik?.errors?.items?.[index]?.itemQty?.[qtyIndex]?.remarks ? (<div className='req-error'>{dprFormik?.errors?.items?.[index]?.itemQty?.[qtyIndex]?.remarks}</div>) : null}

                                 </td>
                                 <td>
                                    <div className='d-flex gap-2'>
                                       {dprFormik?.values?.items?.[index]?.itemQty.length > 1 && <button className='add-vendor' disabled={!dprFormik?.values?.items?.[index]?.selected} onClick={() => handleRemoveItemQty(index, qtyIndex)}>-</button>}
                                       {<button className='add-vendor' disabled={!dprFormik?.values?.items?.[index]?.selected} onClick={() => handleAddItemQty(index, qtyIndex)}>+</button>}
                                    </div>
                                 </td>

                              </tr>
                           ))}
                           <tr>
                              <td><span className='fw-bold'>{langPreference?.total_quantity || "Total Quantity"}</span></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              {!dprFormik?.values?.items?.[index]?.isDiameter && <td></td>}
                              <td>{getVolumeQty(dprFormik?.values?.items?.[index])}</td>
                              <td>
                                 <span className='fw-bold'>{ele?.itemDetails?.unitType}</span>
                              </td>
                              <td></td>
                           </tr>
                        </tbody>
                     </table>
                  </div>
               </div>
               <div className='d-flex justify-content-end'>
                  <div className='d-flex gap-2'>
                     {onCancel &&  <button className="add-vendor mb-sm-0 mb-sm-2 px-2 cursor-pointer" disabled={(addLoader || isSaveDraft?.status)} onClick={onCancel}>{langPreference?.cancel || "Cancel"}</button>}
                     {isCopy && dprFormik?.values?.items?.[index]?.selected && <button type="button" disabled={(addLoader || isSaveDraft?.status)} className="add-vendor mb-sm-0 mb-sm-2 px-2" onClick={() => {
                        itemCopyHandler(ele, position)
                     }}>
                        {langPreference?.copy || "Copy"}
                     </button>}
                     {dprFormik?.values?.items?.[index]?.selected && <button type="button" disabled={(addLoader || isSaveDraft?.status)} className={`add-vendor ${!dprFormik?.values?.items?.[index]?.isSaved && "border-danger text-danger"} mb-sm-0 mb-sm-2 px-2`} onClick={() => {
                        if (dprFormik.values?.items.filter(item => item.selected)?.length) {
                           checkSaveValidation("submitModalBtn1", index)
                        } else toast.error(langPreference?.please_select_items || "Please select items")
                     }}>
                        {(addLoader || (isSaveDraft?.status && isSaveDraft?.index == index)) ? <Spinwheel sizeClass={"spin-small"} /> : langPreference?.save || "Save"}
                     </button>}

                  </div>
               </div>

               <hr />
            </div>
         ))}
     <ConfirmationModal modalId={removeItemModalId} handleConfirm={()=>removeItemHandler(seleteRemoveableItem?.saveId,position,removeItemModalId)} message={langPreference?.are_you_sure_to_delete || <>Are you sure you want to delete</>} />
      </div>
   )
}
