import React, { useEffect, useState } from 'react'
import { approveOrRejectApi, getVoucherByIdApi, getVoucherRemainingAmountApi,downloadVoucherPdf } from '../../utils/ApiEndpointFunctions'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useUserContext } from '../../context/userContext';
import toast from 'react-hot-toast';
import Spinwheel from '../../components/common/Spinwheel';
import moment from 'moment';
import { S3_URL } from '../../utils/BaseUrl';
import { formatPrice, isImageFile } from '../../utils/HelperFuncitons';
import ConfirmationModal from '../../components/modal/ConfirmationModal';
import StatusModal from '../../components/modal/StatusModal';
import { useLocation } from 'react-router-dom';

function ViewVoucher() {
  const location = useLocation();
  const params = useParams();
  const { userDetails,langPreference } = useUserContext();
  const { id } = params;
  const [voucherDetaits, setVoucherDetaits] = useState();
  const navigate = useNavigate();
  const confirmModalId = "conFirmModalID"
  const statusModalId = "statusModalID"
  const [confirmationMsg, setConfirmationMsg] = useState('')
  const [confirmModalMsg, setConfirmModalMsg] = useState(<>Are you sure you want to <br /> Submit ?</>)
  const [isSubmitvoucher, setIsSubmitvoucher] = useState(false)
  const [okbtn, setOkbtn] = useState("")
  const [submitLoader, setSubmitLoader] = useState(false)
  const [download,setDownload] = useState(false)
  const [voucherStatus, setVoucherStatus] = useState();



  const getVoucherById = async () => {
    try {
      const res = await getVoucherByIdApi(id)
      if (res.status === 200) {
        const voucher = res.data?.data?.Voucher
        
        setVoucherDetaits(voucher);
      }
    } catch (error) {
      console.log(error)
      toast.error("Something Went Wrong")
    }

  }


  const handleVoucherApprove = async (value) => {
    try {
      
      const res = await approveOrRejectApi({ id, status:voucherStatus,reason:value||"" })
      if (res.status === 200) {
        // toast.success(res.data?.message)
        document.getElementById(confirmModalId).click()
        setConfirmationMsg(res?.data?.message || `Voucher ${voucherDetaits?.voucherNumber} is successfully ${voucherStatus}.`)
        document.getElementById("statusModalBtn").click()
        if(id){
          getVoucherById()
        }
      }
    } catch (error) {
      console.log(error)
      toast.error("something went wrong");
    }
  }
  const checkValidation = async () => {
    try {
      setSubmitLoader(true)
      const res = await getVoucherRemainingAmountApi({
        challanOrPONumber: voucherDetaits?.challanNumber,
        expenseType: voucherDetaits?.expenseType,
        amount: voucherDetaits?.amount
      })

      if (res?.status == 200) {
        if (res?.data?.data?.status == 1) {
          setIsSubmitvoucher(true)
        } else {
          setOkbtn("no")
        }
        setConfirmModalMsg(<span className='p-3'>{res?.data?.message}</span>)
        setSubmitLoader(false)
      }

      document.getElementById("submitModalBtn1").click()
    } catch (err) {
      console.log(err)
      if (err.response && err.response.data) {
        toast.error(err.response.data.message)
      } else {
        toast.error("Something went wrong")

      }
      setSubmitLoader(false)
    }
  }

  const handleConfirmation = (value)=>{
    if(isSubmitvoucher || voucherStatus=="rejected"){
      handleVoucherApprove(value) 
    }else{
      document.getElementById(confirmModalId).click()
    }
  }

  useEffect(() => {
    if (id) {
      getVoucherById()
    }
  }, [])

  const getDownloadVoucher =async(id)=>{
    try {
      setDownload(true)
      const response = await downloadVoucherPdf(id)
      const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', voucherDetaits?.voucherNumber ? `${voucherDetaits.voucherNumber}.pdf` : 'voucher.pdf'); // or any other filename
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setDownload(false)
      toast.success("Successfully downloaded");
    } catch (error) {
      setDownload(false)
      toast.error("Failed to download");
    }
  }

  return (
    <>
      <div className="main-content">
        <div className="container-fluid">
          <div className="row">
            <div className="main-content">
              <div className="back"><span onClick={() => navigate(location?.state?.path ? location?.state?.path : -1, { state: { ...location?.state}})} className="back cursor-pointer" ><i className="bi bi-chevron-left"></i> {langPreference?.back || "Back"}</span></div>
              <div className="row d-flex justify-content-center">
                <div className="col-xxl-11 border border-2 p-5 col-lg-10 col-md-12">
                  <div className="">
                    <div>
                      {/* <div className="d-flex justify-content-between mb-1">
                            <div className='fs-5 fw-bold'>Preview</div>
                          </div> */}
                      <div>
                        <div className='fs-5 fw-bold'>
                          {voucherDetaits?.name}
                        </div>

                        <div className='mt-3 row row-cols-2'>
                          <label>{langPreference?.voucher_number || "Voucher number"}</label>
                          <div>{voucherDetaits?.voucherNumber}</div>
                        </div>
                        <div className='mt-3 row row-cols-2'>
                          <label>{langPreference?.created_date || "Created date"}</label>
                          <div>{moment(voucherDetaits?.createdAt).format("DD/MM/YYYY")}</div>
                        </div>

                        <div className='mt-3 row row-cols-2'>
                          <label>{langPreference?.challan_po_number || "PO/Challan number"}</label>
                          <div>{voucherDetaits?.challanNumber ? <Link to={voucherDetaits?.challanDetails ? `/challan/view/${voucherDetaits?.challanDetails?._id}` : `/purchase/view/${voucherDetaits?.poDetails?._id}`}>{voucherDetaits?.challanNumber}</Link> : ""}</div>
                        </div>

                        <div className='mt-3 row row-cols-2'>
                          <label>{langPreference?.status || "Status"}</label>
                          <div className='text-capitalize'>{voucherDetaits?.status}</div>
                        </div>
                        <hr />

                        <div className='mt-3 row row-cols-2'>
                          <label>{langPreference?.name || "Name"}</label>
                          <div>{voucherDetaits?.name}</div>
                        </div>

                        <div className='mt-3 row row-cols-2'>
                          <label>{langPreference?.issuer_type || "Issuer type"}</label>
                          <div className='text-capitalize'>{voucherDetaits?.vendorType}</div>
                        </div>

                        <div className='mt-3 row row-cols-2'>
                          <label>{langPreference?.voucher_type || "Voucher type"}</label>
                          <div className={voucherDetaits?.voucherType?.toLowerCase() === "upi" ? "text-uppercase" : "text-capitalize"}>{voucherDetaits?.voucherType}</div>
                        </div>

                        <div className='mt-3 row row-cols-2'>
                          <label>{langPreference?.expenses_type || "Expenses type"}</label>
                          <div className='text-capitalize'>{voucherDetaits?.expenseType}</div>
                        </div>

                        <div className='mt-3 row row-cols-2'>
                          <label>{langPreference?.amount || "Amount"}</label>
                          <div>{formatPrice(Number(voucherDetaits?.amount))}</div>
                        </div>

                        <div className='mt-3 row row-cols-2'>
                          <label>{langPreference?.remarks || "Remarks"}</label>
                          <div>{voucherDetaits?.remarks}</div>
                        </div>
                        <hr />
                        <div>
                          {voucherDetaits?.createdBy?.firstName && <div className="row row-cols-2 mt-3">
                            <div className="fw-bold">{langPreference?.created_by || "Created By"}</div>
                            <div className='text-capitalize'>{voucherDetaits?.createdBy?.firstName || ""} {voucherDetaits?.createdBy?.lastName || ""}</div>
                          </div>}
                          {voucherDetaits?.createdAt && <div className="row row-cols-2 mt-3">
                            <div className="fw-bold">{langPreference?.created_on || "Created On"}</div>
                            <div className='text-capitalize'>{voucherDetaits?.createdAt && moment(voucherDetaits?.createdAt).format("DD/MM/YYYY HH:mm")}</div>
                          </div>}
                          {voucherDetaits?.status!="created" && voucherDetaits?.submittedBy?.firstName && <div className="row row-cols-2 mt-3">
                            <div className="fw-bold">{langPreference?.submitted_by || "Submitted By"}</div>
                            <div className='text-capitalize'>{voucherDetaits?.submittedBy?.firstName || ""} {voucherDetaits?.submittedBy?.lastName || ""}</div>
                          </div>}
                          {voucherDetaits?.status!="created" && voucherDetaits?.submittedOn && <div className="row row-cols-2 mt-3">
                            <div className="fw-bold">{langPreference?.submitted_on || "Submitted On"}</div>
                            <div className='text-capitalize'>{voucherDetaits?.submittedOn && moment(voucherDetaits?.submittedOn).format("DD/MM/YYYY HH:mm")}</div>
                          </div>}
                          {voucherDetaits?.approvedBy?.firstName && <div className="row row-cols-2 mt-3">
                            <div className="fw-bold">{langPreference?.approved_by || "Approved By"}</div>
                            <div className='text-capitalize'>{voucherDetaits?.approvedBy?.firstName || ""} {voucherDetaits?.approvedBy?.lastName || ""}</div>
                          </div>}
                          {voucherDetaits?.approvedOn && <div className="row row-cols-2 mt-3">
                            <div className="fw-bold">{langPreference?.approved_on || "Approved On"}</div>
                            <div className='text-capitalize'>{voucherDetaits?.approvedOn && moment(voucherDetaits?.approvedOn).format("DD/MM/YYYY HH:mm")}</div>
                          </div>}
                        </div>
                        <hr/>
                        {voucherDetaits?.photo && <>
                          <div className="d-flex justify-content-between mb-1">
                            <div className='fs-6 fw-bold'>{langPreference?.file_photo || "Photo/File"}</div>
                          </div>
                          <div className="border border-2 d-flex justify-content-center align-items-center">
                            {/* <img src={S3_URL + voucherDetaits?.photo} alt="" className='img-fluid' style={{ maxHeight: "300px"}}/> */}
                              { isImageFile(voucherDetaits?.photo) ?  <img src={S3_URL + encodeURIComponent(voucherDetaits?.photo)} alt="" className='img-fluid' style={{ maxHeight: "300px",minHeight: "300px" }} /> : <div><Link target='_blank' to={S3_URL + encodeURIComponent(voucherDetaits?.photo)}><i class="bi bi-file-text-fill fs-1"  ></i></Link></div>}
                          </div>
                        </>}
                        <hr/>

                        {voucherDetaits?.voucherType?.toLowerCase() !== "cash" ? <>
                        
                          <div className="d-flex justify-content-between mb-1">
                            <div className='fs-5 fw-bold'>{langPreference?.bank_details || "Bank details"}</div>
                          </div>

                          <div className='mt-3 d-flex justify-content-between  form-details'>
                            <label>{langPreference?.bank_name || "Bank name"}</label>
                            <div>{voucherDetaits?.bankName}</div>
                          </div>

                          <div className='mt-3 d-flex justify-content-between  form-details'>
                            <label>{langPreference?.cheque_number || "Cheque number"}</label>
                            <div>{voucherDetaits?.chequeNumber}</div>
                          </div>

                          <div className='mt-3 d-flex justify-content-between  form-details'>
                            <label>{langPreference?.cheque_date || "Cheque date"}</label>
                            <div>{voucherDetaits?.chequeDate ? moment(voucherDetaits?.chequeDate).format("DD/MM/YYYY") : "-"}</div>
                          </div>
                        </>
                          :
                          <>
                            <div className="d-flex justify-content-between mb-1">
                              <div className='fs-6 fw-bold'>{langPreference?.signature_recipient || "Signature of recipient"}</div>
                            </div>

                            <div className="border border-2 d-flex justify-content-center align-items-center">
                              <img src={S3_URL + voucherDetaits?.signature} alt="" className='img-fluid' style={{maxHeight:"300px"}} />
                            </div>
                          </>}
                      </div>
                      <hr />
                      <div className="d-flex justify-contend-start gap-2">
                        {userDetails?.roleId?.permission.includes("voucherApprove") && !voucherDetaits?.isApproveReject && voucherDetaits?.status?.toLowerCase() !== "approved" && <div className="d-flex justify-content-between gap-2 mt-3">
                          <div className="btn btn-primary" disabled={submitLoader} onClick={(e) => { setOkbtn(""); checkValidation(e) ;setVoucherStatus('approved');setConfirmModalMsg(langPreference?.approve_confirmation || <h1>Are you sure you want to <br/> Approve ?</h1>)}}>{submitLoader ? <Spinwheel sizeClass={"spin-small"} /> : langPreference?.approve ||  "Approve"}</div>
                        </div>}
                    {userDetails?.roleId?.permission.includes("voucherUpdate") && !voucherDetaits?.isApproveReject && voucherDetaits?.status?.toLowerCase() !== "approved" && <div className="d-flex justify-content-between gap-2 mt-3"> <button className='btn btn-primary' onClick={() => { navigate(`/voucher/add/${id}`) }} >{langPreference?.edit || "Edit"}</button></div>}
                    <div className="d-flex justify-contend-start gap-2">
                          {(voucherDetaits?.status?.toLowerCase() === "approved" || voucherDetaits?.isApproveReject)  && <div className="d-flex justify-content-between gap-2">
                            <button className="btn create-order text-white" disabled={download} onClick={() => !download && id && getDownloadVoucher(id)}>{langPreference?.download ||"Download"}</button>
                          </div>}
                          {userDetails?.roleId?.permission.includes("voucherApproveReject") && !voucherDetaits?.isReversalVoucher && voucherDetaits?.status?.toLowerCase() == "approved" &&
                           <button className="btn create-order text-white"  data-bs-toggle="modal" data-bs-target={`#${confirmModalId}`}  onClick={(e) => {setVoucherStatus('rejected');setConfirmModalMsg(langPreference?.confirm_reject || <h1>Are you sure you want to <br/> Reject ?</h1>) }} >{langPreference?.reject || "Reject"}</button>}
                        </div>
                  </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button className='d-none' id="submitModalBtn1" data-bs-toggle="modal" data-bs-target={`#${confirmModalId}`} />
        <ConfirmationModal modalId={confirmModalId} handleConfirm={handleConfirmation} message={confirmModalMsg} okbtn={okbtn} reasonRequired={voucherStatus=='rejected'}/>
        <button className='d-none' id="statusModalBtn" data-bs-toggle="modal" data-bs-target={`#${statusModalId}`} />
        <StatusModal
          modalId={statusModalId}
          message={confirmationMsg}
          handleClose={()=>navigate("/voucher")}
        />
      </div>
    </>
  )
}

export default ViewVoucher