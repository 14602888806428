import React, { useEffect, useState } from 'react'
import Spinwheel from '../../components/common/Spinwheel';
import toast from 'react-hot-toast';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useUserContext } from '../../context/userContext';
import { getAllNotificationApi, getProfileApi, markAllNotificationsAsClearApi, markAllNotificationsAsSeenApi, markAsAcknowledgeNotificationApi } from '../../utils/ApiEndpointFunctions';
import ReactPaginate from 'react-paginate';
import { removeToken } from '../../utils/HelperFuncitons';
import moment from 'moment';


function ViewNotification(props) {
  const [notification, setNotification] = useState([])
  const [loading, setLoading] = useState(true)
  const [totalCount, setTotalCount] = useState(null);
  const [page, setPage] = useState(0);
  // let [selectedPage, setSelectedPage] = useState(1);
  const navigate = useNavigate();
  const { userDetails,langPreference } = useUserContext();
  const limit = 10;
  const [search, setSearch] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const inventoryItemModalId = "inventoryItemModalId"
  const [disableItem,setDisableItem] = useState(false)
  const [clearLoading,setClearLoading] = useState(false)
  const { setUserDetails } = useUserContext();

    const getUserDetails = async () => {
      try {
        const res = await getProfileApi();
        if (res.status === 200) {
          setUserDetails(res?.data.data)
        } else {
          removeToken();
        }
      } catch (error) {
        removeToken();
      }
    }

  const getNotificationDetails = async (resetUser) => {
    setLoading(true);
    try {
      const page = searchParams.get("page") || 1
      const res = await getAllNotificationApi(limit, page,search);
      if (res.status === 200) {
        const totalData = res.data?.data?.totalData
        const notificationData = res.data?.data?.notifications
        setTotalCount(totalData);
        setPage(Math.ceil(totalData / limit));
        setNotification(notificationData);
        if(notificationData?.filter(ele=>!ele?.isSeen.includes(userDetails?._id))?.length>0){
          handleSeenNotification()
        }
      }
    } catch (error) {
      console.log(error)
    }
    setLoading(false);
  }


  const handlePageClick = (event) => {
    let currentPage = event.selected + 1;
    setSearchParams({"page": currentPage})
  };


 const handleAcknowlege =async(_id)=>{
   try {
      const res = await markAsAcknowledgeNotificationApi({_id})
      if (res.status === 200) {
        toast.success(res?.data?.message)
        getNotificationDetails()
      }
    } catch (error) {
      console.log(error)
      toast.error(error?.response?.data?.message || "Failed to update")
    }
 }

 const handleClearNotification =async()=>{
   try {
      setClearLoading(true)
      const res = await markAllNotificationsAsClearApi()
      if (res.status === 200) {
        toast.success(res?.data?.message)
        getNotificationDetails()
      }
    } catch (error) {
      console.log(error)
      toast.error(error?.response?.data?.message || "Failed to update")
    }
    setClearLoading(false)
 }

 const handleSeenNotification =async()=>{
  try {
     const res = await markAllNotificationsAsSeenApi()
     if (res.status === 200) {
      getUserDetails()
     }
   } catch (error) {
     console.log(error)
     toast.error(error?.response?.data?.message || "Failed to update")
   }
}



  useEffect(() => {
    getNotificationDetails()
  }, [searchParams.get('page')])
  return (
    <>
      <div className="main-content">
        <div className="container-fluid">
          <div className="row">
            <div className="main-content">
              {props.type !== "dashboard" && <div className="dash-nav1">
                <div className="back"><span onClick={() => navigate(-1)} className="back cursor-pointer" ><i className="bi bi-chevron-left"></i> {langPreference?.back || "Back"}</span></div>
                <div className="d-flex justify-content-between align-items-center">
                  <h3 className="mb-0 font-blue">{langPreference?.notifications || "Notifications"}</h3>
                </div>
              </div>}
              <div className="d-flex justify-content-end align-items-center">
                <div className="d-flex align-items-center">
                  {notification?.length>0 && <div>
                <button type="button" disabled={clearLoading} className="create-order" onClick={handleClearNotification}>
              {clearLoading ? <Spinwheel sizeClass={"spinner-border-sm"} /> : langPreference?.clear || "Clear"}
            </button>
                  </div>}


                 

                </div>
              </div>
              <table className="table d-table mt-4">
                <thead>
                  <tr>
                    <th scope="col-1">{langPreference?.title || "Title"}</th>
                    <th scope="col-1">{langPreference?.notification || "Notification"}</th>
                    <th scope="col-1">{langPreference?.time || "Time"}</th>
                    <th scope="col-1">{langPreference?.acknowledge || "Acknowledge"}</th>
                    <th scope="col-2">{langPreference?.action || "Action"}</th>
                  </tr>
                </thead>
                {
                  loading ?
                    <tbody >
                      <tr>
                        <td colSpan={12}>
                          <div className="d-flex justify-content-center">
                            <Spinwheel />
                          </div>
                        </td>
                      </tr>
                    </tbody> :
                    <>
                      <tbody>

                        {notification?.length > 0 ? notification?.map((ele, index) => {
                          return (
                            <tr key={index}>
                              <td>{ele?.title}</td>
                              <td>{ele?.body}</td>
                              <td>{ele?.createdAt ? moment(ele?.createdAt).fromNow(false) :"-"}</td>
                              <td ><button className={`${ele?.isAcknowledge ?"approve" : "reject" }  cursor-text text-capitalize `}>{ele?.isAcknowledge ? "Yes" :"No"}</button> </td>
                              <td>
                                <div>
                                 {!ele?.isAcknowledge && userDetails?.roleId?.permission.includes("notificationWrite") && <div className="dropdown">
                                    <button className="dots dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false"><i className="bi bi-three-dots-vertical"></i></button>
                                    <ul className="dropdown-menu">
                                      {<li className='dropdown-item' onClick={() => handleAcknowlege(ele?._id)}>{langPreference?.acknowledge || "Acknowledge"}</li>}
                                    </ul>
                                  </div>}
                                
                                </div>
                              </td>
                            </tr>)
                        }) : <tr className='text-center'>
                          <td colSpan="12">
                            <h1>{langPreference?.data_not_found || "Data Not Found"}</h1>
                          </td>
                        </tr>}
                      </tbody></>}
              </table>

            </div>
          </div>

          {totalCount > limit ? (
            <div className="d-flex justify-content-center">
              <ReactPaginate
                nextLabel="Next >"
                onPageChange={handlePageClick}
                pageRangeDisplayed={3}
                marginPagesDisplayed={1}
                pageCount={page}
                previousLabel="< Previous"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakLabel="..."
                breakClassName="page-item"
                breakLinkClassName="page-link"
                containerClassName="pagination"
                activeClassName={"active"}
                forcePage={searchParams.get('page')? Number(searchParams.get('page'))-1 : 0}
              />
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    
    </>
  )
}

export default ViewNotification