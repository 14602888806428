import React, { useEffect } from 'react';
import { Toaster } from 'react-hot-toast';

import Mainroute from './routes/Mainroute';
import { useUserContext } from './context/userContext';
import { getProfileApi, getUserLanguageApi } from './utils/ApiEndpointFunctions';
import { removeToken, setLanguage } from './utils/HelperFuncitons';
import { clearCacheData } from './utils/HelperFuncitons';

function App() {
  const token = localStorage.getItem("token")
  const { setUserDetails,setLangPreference } = useUserContext();

  const getUserDetails = async () => {
    try {
      const res = await getProfileApi();
      if (res.status === 200) {
        setUserDetails(res?.data.data)
        setLanguage(res?.data?.data?.language)
      } else {
        removeToken();
      }
    } catch (error) {
      removeToken();
    }
  }

  const getUserLanguagePreference = async () => {
    try {
      const { data } = await getUserLanguageApi();
      if (data && data.data) {
        setLangPreference(data.data)
      }
    } catch (error) {
      console.log("languagePreference error",error);
      
    }
  }

  useEffect(() => {
    if (token) {
      clearCacheData()
      getUserDetails()
      getUserLanguagePreference()
    }
  }, [token])


  return (
    <div className="App">
      <Mainroute />
      <Toaster
        position="top-right"
        reverseOrder={false}
      />
    </div>
  );
}

export default App;
