import React, { useEffect, useState } from 'react'
import DatePicker from "react-datepicker";
import Select from 'react-select';
import { getvendorsApi,getSiteItemApi, getInventoryApi } from '../../utils/ApiEndpointFunctions';
import { useUserContext } from '../../context/userContext';

function FilterInventoryReport(props) {
  const { modalId, formik, clickHandler,isSiteItem } = props
  const [vendors, setVendors] = useState()
  const [items, setItems] = useState()
  const {langPreference} = useUserContext()


    const getVendors = async (inputValue, cb) => {
      try {
        const type = "Sub-Contractor"
        const res = await getvendorsApi(inputValue,"","",type)
        if (res.status === 200) {
          let fetchedItem = res.data?.data?.vendors?.map(ele => ({ label: ele.name, value: ele?._id }))
          setVendors(fetchedItem);
        }
      } catch (error) {
        console.log(error)
      }
    };


    const getItems = async (inputValue, cb) => {
      try {
        const res = isSiteItem ? await getSiteItemApi(inputValue) :  await getInventoryApi(inputValue)
        if (res.status === 200) {
          let fetchedItem = []
          if(isSiteItem){
            fetchedItem = res.data?.data?.data?.map(ele => ({ label: ele?.details.name, value: ele?.details?.uniqueItemId }))
          }else{
            fetchedItem = res.data?.data?.data?.map(ele => ({ label: ele.name, value: ele?._id }))
          }
          setItems(fetchedItem);
        }
      } catch (error) {
        console.log(error)
      }
    };

  useEffect(() => {
    getVendors()
    getItems()
  }, [])

  const handleSelectDate = (e, type) => {
    formik.setFieldValue(type, e)
  };
  

  return (
    <>
      <div
        className="modal fade"
        id={modalId}
        tabIndex="-1"
        aria-labelledby="deleteModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog  modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-0">

              <button type="button" id="confirmModalCloseBtn" className="btn-close1" data-bs-dismiss="modal" aria-label="Close"><i className="bi bi-x-lg"></i></button>
            </div>
            <div className="modal-body ">
              <div className="">
                <div className="bb">
                  <h5>{langPreference?.filter || "Filter"}</h5>
                </div>
                <form className="my-fx">
                <div className="ed-box">
                    <label htmlFor="to" className="form-label">{langPreference?.date || "Date"} <span className='req-error'>*</span></label>
                    <DatePicker
                      selectsRange={true}
                      dateFormat="dd/MM/yyyy"
                      className={`form-control cursor-pointer ${((formik?.touched?.to && formik?.errors?.to)||(formik?.touched?.from && formik?.errors?.from)) && "border-danger"}`}
                      startDate={ formik.values?.to ? formik.values?.to :null }
                      endDate={formik.values?.from ?formik.values?.from :null}
                      // maxDate={new Date(new Date(formik.values?.to ? formik.values?.to : new Date()).setTime(new Date(formik.values?.to ? formik.values?.to : new Date()).getTime() + (1 * 1000 * 60 * 60 * 24 * 180)))}
                      onChange={(update) => {
                        handleSelectDate(update[0], "to")
                        handleSelectDate(update[1], "from")
                      }}
                      isClearable={true}
                      placeholderText='from - to'
                      showDisabledMonthNavigation
                    />
                    <div>
                     <div className='text-danger'>{(formik?.touched?.from && formik?.errors?.from) || (formik?.touched?.to && formik?.errors?.to)}</div>
                    </div>
                  </div>
                  <div className="ed-box">
                    <label htmlFor="vendorId" className="form-label">{langPreference?.subcontractor_name || "Sub-contractor name"}</label>
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      value={formik?.values?.vendor || null}
                      placeholder="Select "
                      isSearchable={true}
                      name="vendor"
                      options={vendors}
                      onChange={(e)=>formik.setFieldValue("vendor", e) }
                    />
                  </div>

                  <div className="ed-box">
                    <label htmlFor="Item" className="form-label">{langPreference?.item || "Item"}</label>
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      value={formik?.values?.item || null}
                      placeholder="Select "
                      isSearchable={true}
                      name="item"
                      options={items}
                      onChange={(e) => {formik.setFieldValue("item", e) }}
                    />
                  </div>


                  <div className="d-flex mt-4 justify-content-end align-items-center">
                    <div className="save" onClick={(e) => { clickHandler()}}>
                      {langPreference?.apply || "Apply"}
                    </div>
                  </div>

                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default FilterInventoryReport