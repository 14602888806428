import React, { useEffect, useState } from 'react'
import { getDprByIdApi, approveOrRejectVendorDprApi, downloadDprById, removeDprItemByIdApi } from '../../utils/ApiEndpointFunctions'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useUserContext } from '../../context/userContext';
import ConfirmationModal from '../../components/modal/ConfirmationModal';
import toast from 'react-hot-toast';
import Spinwheel from '../../components/common/Spinwheel';
import { formatPrice, getRoundOff } from '../../utils/HelperFuncitons';
import moment from 'moment';
import StatusModal from '../../components/modal/StatusModal';
import DprItemComp from '../../components/common/DpritemComp';


function ViewDpr() {
  const params = useParams();
  const { userDetails,langPreference } = useUserContext();
  const { id } = params;
  let addressKey = ["address1", "address2", "city", "state", "pinCode"]
  const [dprDetails, setDprDetails] = useState();
  const navigate = useNavigate();
  const confirmModalId = "conFirmModalID"
  const statusModalId = "statusModal"
  const [updatedStatus,setUpdatedStatus] = useState(null)
  const [confirmationMsg, setConfirmationMsg] = useState('')
  const [navigationRoute, setNavigationRoute] = useState("")
  const [download,setDownload] = useState(false)
  const [selectItemId,setSelectItemId]= useState({id:null,load:false})
  const location = useLocation();
  


  const getVendorDprById = async (id) => {
    try{
      const res = await getDprByIdApi(id)
      
      if (res.status === 200) {
        const podata = res.data?.data
        setDprDetails(podata);
        setSelectItemId({id:null,load:false})
      }
    }catch(err){
      console.log(err)
      if (err.response && err.response.data) {
        toast.error(err.response.data.message)
      } else {
        toast.error("Something went wrong")
      }
    }
  }
  

  const handlePoApproval = async () => {
    try {
      const res = await approveOrRejectVendorDprApi({ id: id,status:updatedStatus })
      if (res.status === 200) {
        // toast.success(res.data?.message)
        document.getElementById(confirmModalId).click()
        setConfirmationMsg(res?.data?.message || `DPR ${dprDetails?.dprNumber || ""} is successfully ${updatedStatus}.`)
        document.getElementById("statusModalBtn").click()
        setNavigationRoute('')
        getVendorDprById(id)
      }
    } catch (error) {
      console.log(error)
      toast.error("something went wrong");
    }
  }





  const addressFormatter = (vendor) => {
    let address = ""

    addressKey.forEach((ele, index) => {
      if (vendor?.[ele]) {
        address += vendor?.[ele] + ((addressKey.length - 1) === index ? "" : ", ");
      }
    });
    return address
  }

  const handleCloseStatusModal = () => {
    if(navigationRoute){
      navigate(navigationRoute)
    }
  }

  useEffect(() => {
    if (id) {
      getVendorDprById(id)
    }
  }, [])

  const getDownloadDpr =async()=>{
    try {
      setDownload(true)
      const response = await downloadDprById(id)
      const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', dprDetails?.dprNumber ? `${dprDetails.dprNumber}.xlsx` :'DPR.xlsx'); // or any other filename
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setDownload(false)
      toast.success("Successfully downloaded");
    } catch (error) {
      setDownload(false)
      toast.error("Failed to download");
    }
  }

 const formatItemValue = (value)=>{
  return {
    ...value,
    selected: true,
    isSaved: true,
    saveId:value?._id,
    itemQty: value?.itemQty?.map(e=> ({
      ...e,
       length: Number(e.length).toFixed(2),
       breath: Number(e.breath).toFixed(2),
       height: Number(e.height).toFixed(2),
       number1: Number(e.number1),
       number2: Number(e.number2),
       diameter: Number(e.diameter).toFixed(2),
   })),
  }
 }

 const removeItemHandler = async (saveId,position,btn) => {
  try {
    if (saveId) {
      setSelectItemId({...selectItemId,load:true})
      const res = await removeDprItemByIdApi(saveId)
      if (res.status === 200) {
        toast.success(res.data?.message)
        document.getElementById(btn)?.click()
        getVendorDprById(id)
      }
    } 
  } catch (error) {
    console.log(error)
    toast.error("something went wrong");
  }
  setSelectItemId({...selectItemId,load:false})
}



  return (
    <>
      <div className="main-content">
        <div className="container-fluid">
          <div className="row">
            <div className="back"><span onClick={() => navigate(location?.state?.path ? location?.state?.path : -1, { state: { ...location?.state } })} className="back cursor-pointer" ><i className="bi bi-chevron-left"></i> {langPreference.back || "Back"}</span></div>
            <div className="main-content">
              
              <div className="row d-flex justify-content-center">
                <div className="card col-lg-10 col-md-12 col-xxl-11 my-3 p-5">
                  <div>
                    <div className='fs-3 fw-normal'>
                      {langPreference.site_details || "Site details"}
                    </div>
                    <div className="row row-cols-2">
                      <div className="">{langPreference.site_name || "Site name"}</div>
                      <div>{dprDetails?.site?.[0]?.name}</div>
                    </div>
                    {/* <div className="row row-cols-2">
                      <div className="">Site address</div>
                      <div>{dprDetails?.site?.[0]?.addressId?.address1}</div>
                    </div> */}
                    <hr />
                  </div>
                  <div className="d-flex justify-content-between">
                    
                    <div className="">
                      <h1 className='text-capitalize'>
                        {dprDetails?.vendor?.name ? dprDetails?.vendor?.name : ""}

                      </h1>
                    </div>
                   
                  </div>
                  
                  <div className="row row-cols-2 mt-3">
                    <div className="fw-bold">{langPreference.dpr_number || "DPR Number"}</div>
                    <div>{dprDetails?.dprNumber}</div>
                  </div>
                  <div className="row row-cols-2 mt-3">
                    <div className="fw-bold">{langPreference.date || "Date"}</div>
                    <div>{moment(dprDetails?.createDate).format("DD/MM/YYYY")}</div>
                  </div>
                  {/* <div className="row row-cols-2 mt-3">
                    <div className="fw-bold">GST Number</div>
                    <div>{dprDetails?.vendor?.gstNumber}</div>
                  </div>
                  {dprDetails?.vendor?.type!="Vendor" && <div className="row row-cols-2 mt-3">
                    <div className="fw-bold">PAN number</div>
                    <div className="light">{dprDetails?.vendor?.panNumber}</div>
                  </div>} */}
            
                  <div className="row row-cols-2 mt-3">
                    <div className="fw-bold">{langPreference.status || "Status"}</div>
                    <div className='text-capitalize'>{dprDetails?.status}</div>
                  </div>
                  
                  <hr/>
                  <h3>
                    {langPreference.items || "Items"} ({dprDetails?.items?.length || 0})
                  </h3>
                  {dprDetails?.items?.length > 0 && dprDetails?.items?.map((ele, index) => 
                    <>
                         <div className='d-flex justify-content-between'>
                           <span className='fw-bold'>
                              {selectItemId?.id!=ele?._id && ele?.itemDetails?.name}
                            </span>
                            <span>
                              {selectItemId?.id!=ele?._id && <i onClick={() => {setSelectItemId({id:ele?._id,load:false}) }} className="bi bi-pencil-square point"></i>  
                            }
                            </span>
                        </div>
                        {selectItemId?.id==ele?._id ? <DprItemComp values={formatItemValue(ele)} position={index} dprId={dprDetails?._id} details={dprDetails} removeItemHandler={removeItemHandler} handleSave={()=> getVendorDprById(id)} addLoader={selectItemId?.load} onCancel={()=>setSelectItemId({id:null,load:false})} /> :
                        <div>
                        {/* Second Row: Description */}
                        <div>
                          <div className='d-flex justify-content-between'>
                          <div className='d-flex gap-2'>
                            <span htmlFor='isDiameter'>{langPreference.description || "Description"}: </span>
                            {ele?.itemDetails?.description}</div>
                          </div>
                      
                          <div className='d-flex gap-2 w-100'>
                          <div className='w-100'>
                          <div className='d-flex gap-2'>
                            <span htmlFor='isDiameter'>{langPreference.commodity_part || "Commodity Part"}: </span>
                            {ele?.commodityPart}</div>
                         </div>
                          <div className='w-100'>
                          <div className='d-flex gap-2'>
                            <span htmlFor='isDiameter'>{langPreference.commodity_sub_part || "Commodity Sub Part"}: </span>
                            {ele?.commoditySubPart}</div>
                        </div>
                        
                          </div>
                          <div className='w-100 mt-1'>
                            <span htmlFor='workDescription'>{langPreference.work_description || "Work Description"}: </span>
                            {ele?.workDescription}</div>
                        {ele.isDiameter && <div className='w-100 mt-1'>
                            <span htmlFor='workDescription'>{langPreference.item_type || "Item type"}: </span>
                          <span>{langPreference.circular || "Circular"}</span></div>}
                        </div>

                      <div className='w-100 mt-1'>
                        <div className="table-responsive">
                          <table className="table table-bordered" >
                            <thead>
                              <tr>
                                <th >{langPreference.sub_part || "Sub Part"}</th>
                                <th>{langPreference.no1 || "No1"}</th>
                                <th>{langPreference.no2 || "No2"}</th>
                                {!ele?.isDiameter && <>
                                  <th>{langPreference.L || "L"}</th>
                                  <th>{langPreference.B || "B"}</th>
                                </>}
                                <th>{langPreference.H || "H"}</th>
                                {ele?.isDiameter && <th>{langPreference.D || "D"}</th>}
                                <th>{langPreference.qty || "QTY"}</th>
                                <th >{langPreference.remarks || "Remarks"}</th>
                              </tr>
                            </thead>
                            <tbody>
                              
                              {ele?.itemQty?.map((item, qtyIndex) => (
                                <tr key={qtyIndex} >
                                  <td >{item?.subPart} </td>
                                  <td >{item?.number1} </td>
                                  <td >{item?.number2} </td>
                                  {!ele?.isDiameter && <>
                                  <td >{item?.length ? item?.length?.toFixed(2) : 0.00} </td>
                                  <td >{item?.breath ? item?.breath?.toFixed(2) : 0.00} </td>
                                  </>}
                                  <td >{item?.height ? item?.height?.toFixed(2) : 0.00} </td>
                                  {ele?.isDiameter && <td >{item?.diameter ? item?.diameter?.toFixed(2) : 0.00} </td> }
                                  <td>{item?.quantity ? item?.quantity?.toFixed(2) : 0.00 }</td>
                                  <td >{item?.remarks} </td>

                                </tr>
                              ))}
                              <tr>
                                <td><span className='fw-bold'>{langPreference.total_quantity || "Total Quantity"}</span></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                {!ele?.isDiameter && <td></td>}
                                <td>{ele?.quantity ? ele?.quantity?.toFixed(2) : 0.00}</td>
                                <td>
                                <td><span className='fw-bold'>{ele?.itemDetails?.unitType}</span></td>
                                </td>
                              </tr>
                            </tbody>
                          </table>

                          
                         
                        </div>
                      </div>
                        </div> }
 
                      <hr />
                      
                    </>
                  )}
                
                           
                  {dprDetails?.remarks && <>
                    
                    <div className='fs-3 fw-normal'>
                      {langPreference.remarks || "Remarks"}:
                    </div>
                    <div className=''>{dprDetails?.remarks}</div>
                    <hr />
                    </>}

                  <div>
                    {dprDetails?.createdBy?.firstName && <div className="row row-cols-2 mt-3">
                      <div className="fw-bold">{langPreference.created_by || "Created By"}</div>
                      <div className='text-capitalize'>{dprDetails?.createdBy?.firstName || ""} {dprDetails?.createdBy?.lastName || ""}</div>
                    </div>}
                    {dprDetails?.createdAt && <div className="row row-cols-2 mt-3">
                      <div className="fw-bold">{langPreference.created_on || "Created On"}</div>
                      <div className='text-capitalize'>{dprDetails?.createdAt && moment(dprDetails?.createdAt).format("DD/MM/YYYY HH:mm")}</div>
                    </div>}
                    {dprDetails?.status != "created" && dprDetails?.submittedBy?.firstName && <div className="row row-cols-2 mt-3">
                      <div className="fw-bold">{langPreference.submitted_by || "Submitted By"}</div>
                      <div className='text-capitalize'>{dprDetails?.submittedBy?.firstName || ""} {dprDetails?.submittedBy?.lastName || ""}</div>
                    </div>}
                    {dprDetails?.status != "created" && dprDetails?.submittedOn && <div className="row row-cols-2 mt-3">
                      <div className="fw-bold">{langPreference.submitted_on || "Submitted On"}</div>
                      <div className='text-capitalize'>{dprDetails?.submittedOn && moment(dprDetails?.submittedOn).format("DD/MM/YYYY HH:mm")}</div>
                    </div>}
                    {dprDetails?.approvedBy?.firstName && <div className="row row-cols-2 mt-3">
                      <div className="fw-bold">{langPreference.approved_by || "Approved By"}</div>
                      <div className='text-capitalize'>{dprDetails?.approvedBy?.firstName || ""} {dprDetails?.approvedBy?.lastName || ""}</div>
                    </div>}
                    {dprDetails?.approvedOn && <div className="row row-cols-2 mt-3">
                      <div className="fw-bold">{langPreference.approved_on || "Approved On"}</div>
                      <div className='text-capitalize'>{dprDetails?.approvedOn && moment(dprDetails?.approvedOn).format("DD/MM/YYYY HH:mm")}</div>
                    </div>}
                  </div>

                    
                  <div className="d-flex align-items-center justify-contend-start gap-2">
                  {!["approved","rejected"]?.includes(dprDetails?.status)   && userDetails?.roleId?.permission.includes("dprApprove") && <div className="d-flex justify-content-between gap-2 mt-3">
                      <div className="btn btn-primary"  onClick={()=>navigate(`/dpr/update/${dprDetails?._id}`)}>{langPreference.edit || "Edit"}</div>
                    </div>}

                    {dprDetails?.status!== "created" && dprDetails?.status!== "approved" && dprDetails?.status!== "rejected" && userDetails?.roleId?.permission.includes("dprApprove") && <div className="d-flex justify-content-between gap-2 mt-3">
                      <div className="btn btn-primary" data-bs-toggle="modal" data-bs-target={`#${confirmModalId}`} onClick={()=>setUpdatedStatus("approved")}>{langPreference.approve || "Approve"}</div>
                    </div>}
                    {dprDetails?.status== "approved" && userDetails?.roleId?.permission.includes("dprDecline") && <div className="d-flex justify-content-between gap-2 mt-3">
                      <div className="btn btn-primary" data-bs-toggle="modal" data-bs-target={`#${confirmModalId}`} onClick={()=>setUpdatedStatus("rejected")}>{langPreference.reject || "Reject"}</div>
                    </div>}

                      {dprDetails?.status?.toLowerCase() === "approved" && <div className="d-flex justify-content-between gap-2 mt-3">
                      <button className="btn create-order text-white" disabled={download} onClick={()=>!download && id && getDownloadDpr(id)}>{langPreference.download || "Download"}</button>
                    </div>}


                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ConfirmationModal modalId={confirmModalId} handleConfirm={handlePoApproval} message={updatedStatus=="approved" ? langPreference?.approve_confirmation : langPreference?.confirm_reject ||  <>Are you sure you want to <br /> {updatedStatus} ?</>} />
      <button className='d-none' id="statusModalBtn" data-bs-toggle="modal" data-bs-target={`#${statusModalId}`} />
      <StatusModal
        modalId={statusModalId}
        message={confirmationMsg}
        handleClose={handleCloseStatusModal}
      />
    </>
  )
}

export default ViewDpr