import React, { useEffect, useState } from "react";
import { useUserContext } from '../../context/userContext';
import { updateUserLanguageApi } from '../../utils/ApiEndpointFunctions';
import { toast } from 'react-hot-toast'
import { setLanguage } from '../../utils/HelperFuncitons';

 const LanguagePreferenceSetting = () => {
  const {userDetails,setUserDetails,setLangPreference,langPreference } = useUserContext();
  const [saving,setSaving] = useState(false)

  const handleLanguagePreferance =async()=>{
   try {
      setSaving(true)
       const lang = userDetails?.language=='en' ? 'hi' :'en'
       const res = await updateUserLanguageApi({lang})
       if(res?.status==200 && res?.data?.data){
           setLangPreference(res?.data?.data)
           toast.success(res?.data?.message)
           setUserDetails({...userDetails,language:lang})
           setLanguage(lang)
       }
       setSaving(false)
   } catch (error) {
       console.log("error",error);
       toast.error(error.response?.data?.message || "something went wrong") 
       setSaving(false)
   }
    }


  return (
    <>
      <div className="main-content">
        <div className="container-fluid">
          <div className="row">
            <div className="main-content">
              <div className="dash-nav1">
              <div className="back cursor-pointer" onClick={() => { window.history.back() }}><i className="bi bi-chevron-left"></i> {langPreference?.back || "Back"}</div>
                <div className="d-flex justify-content-between align-items-center">
                  <h3 className="mb-0 font-blue"> {langPreference?.change_language || "Change language" }</h3>
                </div>
              </div>

              <div className="row row-cols-12 row-cols-md-4 gap-2 mt-5">
                       <div className="card">
                          <div class="form-check card-body">
                             <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" disabled={saving}  onChange={(e)=>{!saving && handleLanguagePreferance("en")}} checked={userDetails?.language=="en"}/>
                             <label class="form-check-label" for="flexRadioDefault1">
                                English
                             </label>
                          </div>
                       </div>
                       <div className="card">
                       <div class="form-check card-body">
                          <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" disabled={saving} onChange={(e)=>{!saving && handleLanguagePreferance("hi")}} checked={userDetails?.language=="hi"} />
                          <label class="form-check-label" for="flexRadioDefault2">
                             Hindi
                          </label>
                       </div>
                       </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
};

export default LanguagePreferenceSetting;
