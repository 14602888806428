import React, { useEffect, useState } from 'react'
import DatePicker from "react-datepicker";
import Select from 'react-select';
import { getInventoryApi, getItemSuggestionApi, getSiteItemApi, getvendorsApi } from '../../utils/ApiEndpointFunctions';
import moment from 'moment';
import { useUserContext } from '../../context/userContext';

function Filter(props) {
  const { modalId, formik, clickHandler,filterType,isSiteItem } = props
  const {langPreference} = useUserContext()
  const [vendors, setVendors] = useState()
  const [items, setItems] = useState()
  const isGin = filterType=="gin"
  const isDpr = filterType=="dpr"
  const isDlr = filterType=="dlr"
  const isBoq = filterType=="boq"
  const isGoodsReturn = filterType=="goodsReturn"
  const hasNoHsn = !["boq","dlr"]?.includes(filterType)
  const hasRejectOption = ["dpr","dlr"]?.includes(filterType)
  const hasInventoryItem = ["goodsReturn","gin"].includes(filterType)
  const hasSubContractorList = ["gin","dpr","dlr","goodsReturn"].includes(filterType)
  

  const getVendors = async () => {
    try {
      let type = hasSubContractorList ? "Sub-Contractor":""
      const res = await getvendorsApi("","","",type)
      if (res.status === 200) {
        let fetchedItem = res.data?.data?.vendors?.map(ele => ({ label: ele.name, value: ele }))
        setVendors(fetchedItem);
      }
    } catch (error) {
      console.log(error)
    }
  }
  const getItems = async () => {
    try {
      const res = hasInventoryItem ? await getInventoryApi() : (isSiteItem ? await getSiteItemApi() : await getItemSuggestionApi())
      if (res.status === 200) {
        let fetchedItem = []
        if(hasInventoryItem){
          fetchedItem= res.data?.data?.data?.map(ele => ({ label: ele.name, value: ele }))
        }else if(isSiteItem){
          fetchedItem= res.data?.data?.data?.map(ele => ({ label: ele?.details?.name, value: ele?.details?.uniqueItemId }))
        }else{
          fetchedItem= res.data?.data?.items?.map(ele => ({ label: ele.name, value: ele }))
        }
        setItems(fetchedItem);
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getVendors()
    getItems()
  }, [])

  const handleSelectDate = (e, type) => {
    formik.setFieldValue(type, e)
  };
  
  
  return (
    <>
      <div
        className="modal fade"
        id={modalId}
        tabIndex="-1"
        aria-labelledby="deleteModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog  modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-0">

              <button type="button" id="confirmModalCloseBtn" className="btn-close1" data-bs-dismiss="modal" aria-label="Close"><i className="bi bi-x-lg"></i></button>
            </div>
            <div className="modal-body ">
              <div className="">
                <div className="bb">
                  <h5>{langPreference?.filter || "Filter"}</h5>
                </div>
                <form className="my-fx">

                  <div className="ed-box">
                    <label htmlFor="number" >{isGin ? langPreference?.gin_number || "GIN number" : (isDpr ? langPreference?.dpr_number || "DPR number" : (isBoq ? langPreference?.boq_number || "BOQ number" : (isGoodsReturn ? langPreference?.grn_number || "GRN number" : (isDlr ? langPreference?.dlr_number || "DLR number" : langPreference?.po_number || "PO number"))))} </label>
                    <input type="text" className="form-control" id="number" placeholder="Enter number" name='number' value={formik?.values?.number} onChange={formik?.handleChange} onBlur={formik?.handleBlur} />
                  </div>
                    {!isBoq && <div className="ed-box">
                    <label htmlFor="vendorName" className="form-label">{(isDpr || isGin || isGoodsReturn || isDlr) ? langPreference?.subcontractor_name ||  "Sub-contractor" : langPreference?.vendor_sub_contractor_name ||  "Vendor/Sub-contractor"}</label>
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      value={formik?.values?.vendorName ? { label: formik?.values?.vendorName } : null}
                      placeholder={langPreference?.select || "Select "}
                      isSearchable={true}
                      name="state"
                      options={vendors}
                      onChange={(e) => {
                        formik.setFieldValue("vendorName", e.label)
                        formik.setFieldValue("vendorId", e.value?._id)
                      }}
                    />
                  </div>}


                  {hasNoHsn &&<div className="ed-box">
                    <label htmlFor="hsnCode" className="form-label">{langPreference?.hsn_code || "HSN code"}</label>
                    <input type="text" className="form-control" id="hsnCode" placeholder="Enter hsn" name='hsnCode' value={formik?.values?.hsnCode} onChange={formik?.handleChange} onBlur={formik?.handleBlur} />

                  </div>}
                  <div className="ed-box">
                    <label htmlFor="to" className="form-label">{langPreference?.date || "Date"}</label>
                    <DatePicker
                      selectsRange={true}
                      dateFormat="dd/MM/yyyy"
                      className='form-control cursor-pointer'
                      startDate={ formik.values?.to ? formik.values?.to :null }
                      endDate={formik.values?.from ?formik.values?.from :null}
                      maxDate={new Date(new Date(formik.values?.to ? formik.values?.to : new Date()).setTime(new Date(formik.values?.to ? formik.values?.to : new Date()).getTime() + (1 * 1000 * 60 * 60 * 24 * 180)))}
                      onChange={(update) => {
                        handleSelectDate(update[0], "to")
                        handleSelectDate(update[1], "from")
                      }}
                      isClearable={true}
                      placeholderText='from - to'
                      showDisabledMonthNavigation
                    />
                  </div>
                  {!isBoq &&
                  <div className="ed-box">
                    <label htmlFor="Item" className="form-label">{langPreference?.items || "Item"}</label>
                    {/* <input type="text" className="form-control" id="Item" placeholder="Enter item" name='Item' value={formik?.values?.Item} onChange={formik?.handleChange} onBlur={formik?.handleBlur} /> */}
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      value={formik?.values?.Item ? { label: formik?.values?.Item } : null}
                      placeholder={langPreference?.select || "Select "}
                      isSearchable={true}
                      name="Item"
                      options={items}
                      onChange={(e) => {
                        formik.setFieldValue("Item", e.label)
                        if(isSiteItem){
                          formik.setFieldValue("uniqueItemId", e.value || "")
                        }else{
                          formik.setFieldValue("itemId", e.value?.name || "")
                          formik.setFieldValue("ItemId", e.value?._id || "")
                        }
                      }}
                    />
                  </div>}

                  <div className="ed-box">
                    <label htmlFor="status" className="form-label">{langPreference?.status || "Status"}</label>
                    <select className="form-select" name='status' aria-label="Default select example" value={formik?.values?.status} onChange={formik?.handleChange} onBlur={formik?.handleBlur}  >
                      <option value="">{langPreference?.select || "Select "}</option>
                      {(!isGin && !isDlr) &&<option value="created">Created</option>}
                      <option value="submitted">Submitted</option>
                      <option value="approved">Approved</option>
                      {hasRejectOption && <option value="rejected">Rejected</option>}
                    </select>
                  </div>

                  <div className="d-flex mt-4 justify-content-end align-items-center">
                    <button type='submit' className="save" onClick={(e) => {e.preventDefault(); clickHandler(); document.getElementById(modalId).click() }}>
                      {langPreference?.apply || "Apply"}
                    </button>
                  </div>

                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Filter