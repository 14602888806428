import React from 'react'
import { BrowserRouter, Navigate, Route, Routes, useLocation } from 'react-router-dom'
import Login from "../pages/login/Login"
import Dashboard from '../pages/dashbaord/Dashboard';
import Otpverification from '../pages/login/Otpverification';
import Sidebar from '../components/Layout/Sidebar';
import Header from '../components/common/Header';
import AddPurchaseOrder from '../pages/purchageOrder/AddPurchaseOrder';
import Challan from '../pages/challan/Challan';
import ViewPurchaseOrder from '../pages/purchageOrder/ViewPurchaseOrder';
import AddChallan from '../pages/challan/AddChallan';
import ViewChallan from '../pages/challan/ViewChallan';
import Home from '../pages/home';
import DashboardTemplate from '../components/common/dashboardTemplate';
import Upload from '../pages/upload/Upload';
import AddOrUpdateUpload from '../pages/upload/AddOrUpdateUpload';
import PurchaseTable from '../pages/purchageOrder/PurchaseTable';
import Voucher from '../pages/voucher/Voucher';
import AddOrUpdateVoucher from '../pages/voucher/AddOrUpdateVoucher';
import ViewVoucher from '../pages/voucher/ViewVoucher';
import ManageSites from '../pages/user/ManageSites';
import Cashbook from '../pages/cashbook/cashbook';
import Reports from '../pages/report/ReportMain';
import { useUserContext } from '../context/userContext';
import TermsAndCondition from '../pages/termsAndCondition';
import Privacy from '../pages/privacy';
import ViewInventory from '../pages/inventory/view';
import AddGIN from '../pages/GIN/AddGIN';
import GinTable from '../pages/GIN/GinTable';
import ViewGin from '../pages/GIN/ViewGin';
import GoodsReturnTable from '../pages/GIN/GoodReturnTable';
import ViewNotification from '../pages/notification/viewNotification';
import InventoryReport from '../pages/inventory/InventoryReport';
import AddDailyProgressReport from '../pages/DPR/addDpr';
import ViewDprTable from '../pages/DPR/ViewDprTable';
import ViewDpr from '../pages/DPR/ViewDpr';
import AddSiteBOQ from '../pages/BOQ/AddSiteBoq';
import ViewAllBoq from '../pages/BOQ/AllBoq';
import ViewSiteBoq from '../pages/BOQ/viewBoq';
import LanguagePreferenceSetting from '../pages/user/languagePreference';
import AddOrUpdateDLR from '../pages/DPR/DLR/AddOrUpdateDLR';
import ViewDLR from '../pages/DPR/DLR/ViewDLR';
import ViewDLRTable from '../pages/DPR/DLR/ViewDLRTable';
import DLRReport from '../pages/DPR/DLR/DLRReport';

function PrivateRoute({ children }) {
  const auth = localStorage.getItem("token");
  return auth ? <DashboardTemplate>{children}</DashboardTemplate>  : <Navigate to="/" />;
}

function PublicRoute({ children }) {
  const auth = localStorage.getItem("token");
  return !auth ? children : <Navigate to="/dashboard" />;
}

export default function Mainroute() {
  const { userDetails } = useUserContext();
  const auth = localStorage.getItem("token");
  return (
    <>

      <BrowserRouter>
        {/* <Sidebar /> */}
        {/* <Header /> */}


        <Routes>
          <Route exact path="/signin" element={<PublicRoute><Login /></PublicRoute>} />
          <Route exact path="/otp-verification" element={<PublicRoute><Otpverification /></PublicRoute>} />
          <Route exact path="/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
          <Route exact path="/purchase" element={<PrivateRoute><PurchaseTable /></PrivateRoute>} />
          <Route exact path="/purchase/add" element={<PrivateRoute><AddPurchaseOrder /></PrivateRoute>} />
          <Route exact path="/purchase/update/:purchaseOrderId" element={<PrivateRoute><AddPurchaseOrder /></PrivateRoute>} />
          <Route exact path="/purchase/view/:id" element={<PrivateRoute><ViewPurchaseOrder /></PrivateRoute>} />
          <Route exact path="/challan" element={<PrivateRoute><Challan /></PrivateRoute>} />
          <Route exact path="/challan/view/:id" element={<PrivateRoute><ViewChallan /></PrivateRoute>} />
          <Route exact path="/challan/Edit/:id" element={<PrivateRoute><AddChallan /></PrivateRoute>} />
          <Route exact path="/upload" element={<PrivateRoute><Upload /></PrivateRoute>} />
          <Route exact path="/upload/add" element={<PrivateRoute><AddOrUpdateUpload /></PrivateRoute>} />
          <Route exact path="/voucher" element={<PrivateRoute><Voucher /></PrivateRoute>} />
          <Route exact path="/voucher/add/:id?" element={<PrivateRoute><AddOrUpdateVoucher /></PrivateRoute>} />
          <Route exact path="/voucher/view/:id" element={<PrivateRoute><ViewVoucher /></PrivateRoute>} />
          <Route  exact path="/manage-sites" element={<PrivateRoute><ManageSites /></PrivateRoute>} />
          <Route  exact path="/change-language" element={<PrivateRoute><LanguagePreferenceSetting /></PrivateRoute>} />
          {userDetails?.roleId?.permission.includes("cashbookRead") && <Route exact path="/cashbook" element={<PrivateRoute><Cashbook /></PrivateRoute>} />}
          {userDetails?.roleId?.permission.includes("reportRead") && <Route exact path="/report" element={<PrivateRoute><Reports /></PrivateRoute>} />}
          {userDetails?.roleId?.permission.includes("inventoryRead") && <Route exact path="/inventory" element={<PrivateRoute><ViewInventory /></PrivateRoute>} />}
          {userDetails?.roleId?.permission.includes("inventoryRead") && <Route exact path="/inventory/report" element={<PrivateRoute><InventoryReport /></PrivateRoute>} />}
          {userDetails?.roleId?.permission.includes("ginRead") && <Route exact path="/gin" element={<PrivateRoute><GinTable /></PrivateRoute>} />}
          {userDetails?.roleId?.permission.includes("ginRead") && <Route exact path="/goods-return" element={<PrivateRoute><GoodsReturnTable /></PrivateRoute>} />}
          {userDetails?.roleId?.permission.includes("ginWrite") && <Route exact path="/gin/add" element={<PrivateRoute><AddGIN /></PrivateRoute>} />}
          {userDetails?.roleId?.permission.includes("ginUpdate") && <Route exact path="/gin/update/:ginId" element={<PrivateRoute><AddGIN /></PrivateRoute>} />}
          {userDetails?.roleId?.permission.includes("goodReturnWrite") && <Route exact path="/gin/:type/:ginId" element={<PrivateRoute><AddGIN /></PrivateRoute>} />}
          {userDetails?.roleId?.permission.includes("ginRead") && <Route exact path="/gin/view/:id" element={<PrivateRoute><ViewGin /></PrivateRoute>} />}
          {userDetails?.roleId?.permission.includes("notificationRead") && <Route exact path="/notification" element={<PrivateRoute><ViewNotification /></PrivateRoute>} />}
          {userDetails?.roleId?.permission.includes("dprRead") && <Route exact path="/dpr" element={<PrivateRoute><ViewDprTable /></PrivateRoute>} />}
          {userDetails?.roleId?.permission.includes("dprWrite") && <Route exact path="/dpr/add" element={<PrivateRoute><AddDailyProgressReport /></PrivateRoute>} />}
          {userDetails?.roleId?.permission.includes("dprUpdate") && <Route exact path="/dpr/update/:dprId" element={<PrivateRoute><AddDailyProgressReport /></PrivateRoute>} />}
          {userDetails?.roleId?.permission.includes("dprRead") && <Route exact path="/dpr/view/:id" element={<PrivateRoute><ViewDpr /></PrivateRoute>} />}
          {userDetails?.roleId?.permission.includes("dlrRead") && <Route exact path="/dlr" element={<PrivateRoute><ViewDLRTable /></PrivateRoute>} />}
          {userDetails?.roleId?.permission.includes("dlrWrite") && <Route exact path="/dlr/add" element={<PrivateRoute><AddOrUpdateDLR /></PrivateRoute>} />}
          {userDetails?.roleId?.permission.includes("dlrUpdate") && <Route exact path="/dlr/update/:dlrId" element={<PrivateRoute><AddOrUpdateDLR /></PrivateRoute>} />}
          {userDetails?.roleId?.permission.includes("dlrRead") && <Route exact path="/dlr/view/:id" element={<PrivateRoute><ViewDLR /></PrivateRoute>} />}
          {userDetails?.roleId?.permission.includes("dlrRead") && <Route exact path="/dlr/report" element={<PrivateRoute><DLRReport /></PrivateRoute>} />}
          {userDetails?.roleId?.permission.includes("boqWrite") && <Route exact path="/boq/add" element={<PrivateRoute><AddSiteBOQ /></PrivateRoute>} />}
          {userDetails?.roleId?.permission.includes("boqUpdate") && <Route exact path="/boq/update/:boqId" element={<PrivateRoute><AddSiteBOQ /></PrivateRoute>} />}
          {userDetails?.roleId?.permission.includes("boqRead") && <Route exact path="/boq" element={<PrivateRoute><ViewAllBoq /></PrivateRoute>} />}
          {userDetails?.roleId?.permission.includes("boqRead") && <Route exact path="/boq/view/:id" element={<PrivateRoute><ViewSiteBoq /></PrivateRoute>} />}
          {!auth && <Route path="/*" element={<PublicRoute><Login /></PublicRoute>} />} 
          <Route exact path="/" element={<PublicRoute><Home /></PublicRoute>} />
          <Route exact path="/termsandconditions" element={<TermsAndCondition />} />
          <Route exact path="/privacypolicy" element={<Privacy />} />

        </Routes>

      </BrowserRouter>
      <div id="recaptcha-container" ></div>
    </>
  )

}
