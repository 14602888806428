import React from "react";
import { createContext, useContext, useState } from "react";

export const userContext = createContext({
    userDetails: {},
    langPreference:{}

});

export function UserContextProvider({ children }) {
    const [userDetails, setUserDetails] = useState({});
    const [langPreference,setLangPreference] = useState({})
    

    return (
        <userContext.Provider value={{ userDetails, setUserDetails,langPreference,setLangPreference }}>
            {children}
        </userContext.Provider>
    );
}

export function useUserContext() {
    return  useContext(userContext);
}

