import { debounce } from 'lodash';
import React, { useEffect, useState } from 'react'
import AsyncCreatableSelect from 'react-select/async-creatable';
import AsyncSelect from 'react-select/async';
import DatePicker from "react-datepicker";
import {  createVendorApi, deleteItemByIdApi, deleteVendorDprItemByIdApi, getVendorDprItemsApi, getvendorsApi, updateVendorByIdApi, getDprByIdApi, submitVendorDprApi, removeDprItemByIdApi } from '../../utils/ApiEndpointFunctions';
import ItemsAddModal from '../../components/modal/ItemsAddModal';
import { useFormik } from 'formik';
import { addItemInitialValue, addItemValidationSchema, dprAddInitalValue, dprAddValidationSchema, vendorAddInitailValue, vendorAddValidationSchema } from '../../utils/Validation';
import Spinwheel from '../../components/common/Spinwheel';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import VendorAddModal from "../../components/modal/VenderAddModal";
import ConfirmationModal from '../../components/modal/ConfirmationModal';
import { formatPrice, getRoundOff } from '../../utils/HelperFuncitons';
import { useUserContext } from '../../context/userContext';
import moment from 'moment';
import StatusModal from '../../components/modal/StatusModal';
import Select from 'react-select';
import DprItemComp from '../../components/common/DpritemComp';

function AddDailyProgressReport() {
  const [addItemLoader, setAddItemLoader] = useState(false)
  const [addLoader, setAddLoader] = useState(false)
  const [submitLoader, setSubmitLoader] = useState(false)
  const [convertLoader, setConvertLoader] = useState(false)
  const addItemModalId = 'addItemModalId';
  const addVendorModalId = 'addVendorModalId';
  const ItemDeleteModalId = 'itemDeleteModalId';
  const submitConfirmModal = "submitConfirmModal1"
  const statusModalId = "statusModal1"
  const navigate = useNavigate();
  const params = useParams();
  const { dprId } = params;
  const [selectItemId, setSelectItemId] = useState();
  const [dprDetails, setDprDetails] = useState({})
  const { userDetails,langPreference } = useUserContext();
  const [selectedItems, setSelectedItems] = useState([]);
  const [confirmationMsg, setConfirmationMsg] = useState('');
  const [isSaveDraft, setIsSaveDraft] = useState({status:false,index:null})
  const [seleteRemoveableItem, setSelectRemoveablItem] = useState()

  //items =================================================================

  const handleItemDelete = async () => {
    try {
      const res = await deleteItemByIdApi(selectItemId)
      if (res.status === 200) {
        toast.success(res.data?.message)
        document.getElementById(ItemDeleteModalId).click()
        let items = dprFormik?.values?.items?.filter(ele => { return (ele.itemId !== selectItemId) })
        dprFormik.setFieldValue("items", items)
      }
    } catch (error) {
      console.log(error)
      toast.error("something went wrong");
    }
  }

  const removeItemHandler = async (saveId,index,btn) => {
    console.log("ave",saveId,index);
    try {

      // console.log(seleteRemoveableItem)
      if (saveId) {
        const res = await removeDprItemByIdApi(saveId)
        if (res.status === 200) {
          toast.success(res.data?.message)
          document.getElementById(btn)?.click()
          let items = dprFormik?.values?.items?.filter((ele, ind) => { return (ind !== index) })
          dprFormik.setFieldValue("items", items)
        }
        
      } else {

        let items = dprFormik?.values?.items?.filter((ele, ind) => { return (ind !== index) })
        dprFormik.setFieldValue("items", items)
      }
    } catch (error) {
      console.log(error)
      toast.error("something went wrong");
    }
  }


  const itemSelectHandler = (e) => {

    const item = {
      selected: true,
      isSaved: false,
      itemId: e?._id,
      // vendorDprItemId: e?._id,
      quantity: '',
      unitPrice: '',
      unitType: e?.unitType || "",
      otherUnit: "",
      workDescription: "",
      commodityPart:"",
      commoditySubPart:"",
      isDiameter: false,
      itemDetails: e,
      itemQty: [
        {
          length: "0.00",
          breath: "0.00",
          height: "1.00",
          number1: 1,
          number2: 1,
          diameter: "0.00",
          remarks: "",
          subPart: "",
        }
      ]
    }
    dprFormik.setFieldValue("items", [...dprFormik.values.items, item])
  }

  const itemsSubmit = async (values) => {

    try {
      console.log(values)
      setAddItemLoader(true)
      if (values?.unitType?.toLowerCase() != "other") {
        values.otherUnit = ""
      }
      itemSelectHandler(values)
      setSelectItemId('')
      document.getElementById(addItemModalId).click()
    } catch (error) {
      error && error.response ?
        toast.error(error.response?.data?.message) :
        toast.error("something went wrong");
    }
    setAddItemLoader(false)
  }

  const removeItem = async (vendorDprItemId) => {

    dprFormik.setFieldValue("items", [...dprFormik?.values?.items?.filter(ele => { return (ele.vendorDprItemId !== vendorDprItemId) })])
    document.getElementById(addItemModalId).click()
    try {
      const res = await deleteVendorDprItemByIdApi(vendorDprItemId, dprFormik.values.vendorId)
      if (res.status === 200) {
        toast.success(res.data?.message)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const itemFormik = useFormik({
    initialValues: addItemInitialValue,
    onSubmit: itemsSubmit,
    validationSchema: addItemValidationSchema(langPreference)
  })

  //vendor =================================================================

  const handleSelectDate = (e, eleType) => {
    if (eleType === 'dueDate') {
      dprFormik.setFieldValue("dueDate", new Date(e).toISOString())
    } else {
      dprFormik.setFieldValue("createDate", new Date(e).toISOString())
    }
  };

  const handleVendorSelect = (e) => {
    if (e.__isNew__) {
      VendorFormik.resetForm();
      VendorFormik.setFieldValue("name", e.value);
      dprFormik.resetForm();
      return document.getElementById("vendorCreate").click()
    } else {
      dprFormik.setFieldValue("vendorDetails", e.value)
      dprFormik.setFieldValue("vendorId", e.value?._id)
    }
  }





  const filtervendors = async (inputValue, cb) => {
    try {
      const type = "Sub-Contractor"
      const res = await getvendorsApi(inputValue, "", "", type)
      if (res.status === 200) {
        let fetchedItem = res.data?.data?.vendors?.map(ele => ({ label: ele.name, value: ele }))
        cb(fetchedItem);
      }
    } catch (error) {
      console.log(error)
    }
  };

  const vendorOnChange = debounce(filtervendors, 1000);

  const vendorOptions = (inputValue, callback) => {
    vendorOnChange(inputValue, callback);
  }


  const vendorSubmit = async (values) => {
    try {
      setAddItemLoader(true);
      if (values.city.toLowerCase() != "other") {
        values.otherCity = ""
      }
      const res = values?._id ? await updateVendorByIdApi({ ...values, siteId: userDetails?.siteId?._id }) : await createVendorApi({ ...values, siteId: userDetails?.siteId?._id });
      if (res.status === 200) {
        toast.success(res.data.message)
        const vendor = res.data?.data

        dprFormik.setFieldValue("vendorDetails", vendor)
        dprFormik.setFieldValue("type", vendor?.type)
        dprFormik.setFieldValue("vendorId", vendor?._id)
        document.getElementById(addVendorModalId).click()
      }
    } catch (error) {
      error && error.response ?
        toast.error(error.response?.data?.message) :
        toast.error("something went wrong");
    }
    setAddItemLoader(false);
  }

  const VendorFormik = useFormik({
    initialValues: vendorAddInitailValue,
    onSubmit: vendorSubmit,
    validationSchema: vendorAddValidationSchema(langPreference)
  })


  // purchase orders =================================

  const checkHandler = (e, ele, index, type) => {
    let items = dprFormik.values.items.map((item, i) => {
      if (item.itemId === ele.itemId && (index === i)) {
        return {
          ...item,
          ...(type == "isDiameter" ? { isDiameter: e.target.checked } : { selected: e.target.checked }),
          // selected: e.target.checked,
        }
      } else {
        return item;
      }
    });

    dprFormik.setFieldValue("items", items)
  }


  const dprSubmit = async (values) => {
    setAddLoader(true)
    try {
      // const res = values?._id ? await updateVendorDprApi({...payload,isSaveDraft:isSaveDraft?.status}) : await createVendorDprApi({...payload,isSaveDraft:isSaveDraft?.status});
      setIsSaveDraft({status:false,index:null})
      const res =  await submitVendorDprApi({_id:values._id,createDate:values?.createDate});
      if (res.status === 200) {
        // toast.success(res.data.message);
        document.getElementById(submitConfirmModal).click()
        setConfirmationMsg(res?.data?.message || `DPR ${res.data?.data?.dprNumber || ""} is successfully ${dprDetails?.status === "submitted" ? "updated." : (isSaveDraft?.status ? "Saved" : "submitted.")}`)
        dprFormik.setFieldValue("_id", res.data?.data?._id)
        document.getElementById('statusModalBtn').click()

      }
    } catch (error) {
      error && error.response ?
        toast.error(error.response?.data?.message) :
        toast.error("something went wrong");
    }
    setAddLoader(false)
  }

  const handleSumbitApproval = () => {
    if (dprFormik.values?.items.filter(item => item.selected)?.length) {
      let list = dprFormik.values?.items.filter(item => item.selected && !item.isSaved) || []
      if (list?.length) {
        toast.error(langPreference?.save_to_continue ||  `Save ${list?.[0]?.itemDetails?.name} to continue`)
      } else {
        checkValidation("submitModalBtn1")
        setIsSaveDraft({ status: false, index: null })
      }
    } else toast.error("Please select item")
  }

  

  const dprFormik = useFormik({
    initialValues: dprAddInitalValue,
    onSubmit: dprSubmit,
    validationSchema: dprAddValidationSchema(langPreference)
  })

  const getVendorDprById = async (id) => {
    const res = await getDprByIdApi(id)
    if (res.status === 200) {
      const podata = res.data?.data
      dprFormik.setFieldValue("_id", podata?._id)
      dprFormik.setFieldValue("vendorDetails", podata.vendor)
      dprFormik.setFieldValue("vendorId", podata.vendor._id)
      dprFormik.setFieldValue("type", podata.vendor.type)
      dprFormik.setFieldValue("createDate", podata?.createDate)
      dprFormik.setFieldValue("remarks", podata?.remarks || '')
      setSelectedItems(podata?.items)
      // setTimeout(() => {
      getItems(podata?.items, podata.vendor._id);
      // }, 100)
      setDprDetails(podata)
    }
  }

  const getItems = async (items = [], id) => {
    try {
      let selected = []
      if(items?.length || dprId){
       selected = items?.map(ele=>{ return {
          ...ele,
         itemQty: ele?.itemQty?.map(e=> ({
            ...e,
             length: Number(e.length).toFixed(2),
             breath: Number(e.breath).toFixed(2),
             height: Number(e.height).toFixed(2),
             number1: Number(e.number1),
             number2: Number(e.number2),
             diameter: Number(e.diameter).toFixed(2),
            
           
         })),
         selected: true,
          isSaved: true,
        }})
      }else{
        const res = await getVendorDprItemsApi("", "", "", id ? id : dprFormik.values.vendorId);
        if (res.status === 200) {
          let fetchedItem = res.data?.data?.data
          // console.log("fetched item", fetchedItem, items);
          fetchedItem?.map(item => {
              selected.push({
                selected: false,
                isSaved: false,
                itemId: item?.itemDetails?._id,
                vendorDprItemId: item?._id,
                quantity: '',
                unitPrice: item?.unitPrice,
                workDescription: "",
                commoditySubPart:"",
                commodityPart:"",
                isDiameter: false,
                itemDetails: item?.itemDetails,
                itemQty: [
                  {
                    length: "0.00",
                    breath: "0.00",
                    height: "1.00",
                    number1: "1",
                    number2: "1",
                    diameter: "0.00",
                    remarks: "",
                    subPart: "",
                  }
                ]
              })
            
          })
        }
      }

      dprFormik.setFieldValue("items", selected)
    } catch (error) {

    }
  }


  useEffect(() => {
    if (dprId) {
      getVendorDprById(dprId)
    }

  }, [])




  useEffect(() => {
    if (dprFormik.values.vendorId) {
      getItems(dprId ? selectedItems : [], dprFormik.values.vendorId)
    }
  }, [dprFormik.values.vendorId])



  const handleConfirmation = (isConvert) => {
    dprFormik.handleSubmit();
  }

  const checkValidation = (btn) => {
    const errors = Object.keys(dprFormik.errors)   
    if (errors?.length > 0) {
      dprFormik.handleSubmit()
    } else {
      document.getElementById(btn).click()
    }
  }


  const handleApprovalModalClose = () => {
    !isSaveDraft.status && navigate("/dpr")
  }



  const getVolumeQty = (e) => {
    if (e?.selected) {
      const {isDiameter,itemQty} = e
      let totalQty = 0;
      itemQty?.forEach(ele=>{
        // if(itemQty){}
        const { number1, number2, length, breath, height, diameter } = ele
        if (isDiameter) {
          totalQty+= Number((Number(number1 || 0) * Number(number2 || 0) * Number(height || 0)).toFixed(2))
        } else {
          totalQty+= Number(Number(Number(number1 || 0) * Number(number2 || 0) * Number(length || 0) * Number(breath || 0) * Number(height || 0)).toFixed(2))
        }
      })
      return Number(totalQty).toFixed(2)
    }
    return "0.00"
  }


  const itemCopyHandler = (ele, index) => {

    dprFormik.values.items?.splice(index + 1, 0, { ...ele, saveId: undefined,isSaved:false })
    dprFormik.setFieldValue("items", dprFormik.values.items )
  }



const handleSaveItem = (data,updatedItem,position)=>{
  if(data){
    dprFormik.setFieldValue("_id", data?._id)
    dprFormik.setFieldValue("dprNumber", data?.dprNumber)
    dprFormik.setFieldValue("status", data?.status)
  }
  if(updatedItem){
    let updatedList = dprFormik?.values?.items?.map((ele,ind)=>{
      if(position==ind){
        return {...updatedItem}
      }else{
        return ele
      }
    })
    dprFormik.setFieldValue("items",updatedList)
  }
}
console.log(dprFormik,"dprfdsf");
  return (
    <>
      <div className="dash-nav1">
        <div className="align-items-center align-items-baseline d-block d-lg-flex justify-content-between">
        <div className="back"><span onClick={() => navigate(-1)} className="back cursor-pointer" ><i className="bi bi-chevron-left"></i>{langPreference?.back ||"Back"}</span></div>
          <h3 className="mb-0 font-blue">{dprId ?  langPreference?.edit_dpr || "Edit DPR" : langPreference?.create_new_dpr || "Create New DPR"}</h3>
          {dprFormik.values.vendorId ? <div>            
            <button type="button" disabled={addLoader} className="mb-2 mb-sm-2 sbm-purchase" onClick={handleSumbitApproval}>
              {(addLoader && !isSaveDraft) ? <Spinwheel sizeClass={"spin-small"} /> : langPreference?.submit_for_approval || "Submit for approval"}
            </button>
          </div> : <div></div>}
            
        </div>
      </div>
      <div className="dash-table">
        <div className="container">
          <div className=''>
          <h6 className='mb-0 pb-0' style={{ border: "none" }}>{langPreference?.subcontractor_details ||"Sub-contractor details"}</h6>
          <div className='row row-cols-12 row-cols-xl-3'>
                <div className="form-details">
                    <label htmlFor="">{langPreference?.name ||"Name"}</label>
                    <div className='d-flex align-items-center justify-content-center mb-3 '>
                      <div className='cursor-pointer w-100' >
                        <AsyncSelect style={{ cursor: "pointer !important" }} isDisabled={dprId || dprFormik?.values?._id} className='col w-100' placeholder="Select vendor..." cacheOptions value={{ label: dprFormik.values?.vendorDetails?.name, value: dprFormik.values?.vendorDetails }} loadOptions={vendorOptions} defaultOptions onChange={(e) => { handleVendorSelect(e) }} />
                      </div>
                      {/* <i className="form-input bi bi-plus-square ms-2" style={{cursor:"pointer"}} data-bs-toggle="modal" data-bs-target={`#${addVendorModalId}`}></i> */}
                    </div>
                    {dprFormik?.touched?.vendorId && dprFormik?.errors?.vendorId ? (<div className='req-error'>{dprFormik?.errors?.vendorId}</div>) : null}
                  </div>
                  {dprFormik.values.vendorId && <>
                    <div className="form-details">
                      <div className="row">
                        <div className="col-lg-12">
                          <label htmlFor="startDate">{langPreference?.date ||"Date"}</label>
                          <DatePicker dateFormat="dd/MM/yyyy" placeholderText='Create date' className='form-select cursor-pointer' name='createDate' selected={dprFormik.values?.createDate} onChange={(e) => { handleSelectDate(e, "createDate") }} maxDate={new Date(dprFormik.values?.dueDate)} />
                          <span id="startDateSelected"></span>
                        </div>
                      </div>
                    </div>
              {dprFormik?.values?.type != "Vendor" ?
                <div className="form-details">
                  <label htmlFor="">{langPreference?.pan_number || "PAN number"}</label>
                  <input type="text" className="form-control" id="GST1" placeholder="Enter your GST number" disabled value={dprFormik.values?.vendorDetails?.panNumber} />
                </div>
                : <div className="form-details">
                  <label htmlFor="">{langPreference?.gst_number || "GST number"}</label>
                  <input type="text" className="form-control" id="GST1" placeholder="Enter your GST number" disabled value={dprFormik.values?.vendorDetails?.gstNumber} />
                </div>
              }
                  </>}
          </div>
          </div>
          <div className="row gap-3 gap-xl-0">
            {/* <div className="col-12 col-xl-3">
              <div className="vendor-detail ">
                <div className='d-flex align-items-center justify-content-between bb'>
                  <h6 className='mb-0 pb-0' style={{ border: "none" }}>{langPreference?.subcontractor_details ||"Sub-contractor details"}</h6>
                </div>
                <form action="">
                  <div className="form-details">
                    <label htmlFor="">{langPreference?.name ||"Name"}</label>
                    <div className='d-flex align-items-center justify-content-center mb-3 '>
                      <div className='cursor-pointer w-100' >
                        <AsyncSelect style={{ cursor: "pointer !important" }} isDisabled={dprId || dprFormik?.values?._id} className='col w-100' placeholder="Select vendor..." cacheOptions value={{ label: dprFormik.values?.vendorDetails?.name, value: dprFormik.values?.vendorDetails }} loadOptions={vendorOptions} defaultOptions onChange={(e) => { handleVendorSelect(e) }} />
                      </div>
                   
                    </div>
                    {dprFormik?.touched?.vendorId && dprFormik?.errors?.vendorId ? (<div className='req-error'>{dprFormik?.errors?.vendorId}</div>) : null}
                  </div>

                  {dprFormik.values.vendorId && <>
                    <div className="form-details">
                      <div className="row">
                        <div className="col-lg-12">
                          <label htmlFor="startDate">{langPreference?.date ||"Date"}</label>
                          <DatePicker dateFormat="dd/MM/yyyy" placeholderText='Create date' className='form-select cursor-pointer' name='createDate' selected={dprFormik.values?.createDate} onChange={(e) => { handleSelectDate(e, "createDate") }} maxDate={new Date(dprFormik.values?.dueDate)} />
                          <span id="startDateSelected"></span>
                        </div>
                      </div>
                    </div>
                    <div className="form-details">
                      <label htmlFor="">{langPreference?.gst_number ||"GST number"}</label>
                      <input type="text" className="form-control" id="GST1" placeholder="Enter your GST number" disabled value={dprFormik.values?.vendorDetails?.gstNumber} />
                    </div>
                    {dprFormik?.values?.type != "Vendor" &&
                      <div className="form-details">
                        <label htmlFor="">{langPreference?.pan_number ||"PAN number"}</label>
                        <input type="text" className="form-control" id="GST1" placeholder="Enter your GST number" disabled value={dprFormik.values?.vendorDetails?.panNumber} />
                      </div>}

                    <h6 className="mt-4">{langPreference?.address ||"Address"}</h6>
                    <div className="form-details">
                      <label htmlFor="">{langPreference?.address_flat ||"Flat, House no., Building, Company, Apartment"}</label>
                      <input type="text" className="form-control" disabled value={dprFormik.values?.vendorDetails?.vendorAddressId?.address1} />
                    </div>
                    <div className="form-details">
                      <label htmlFor="">{langPreference?.address_area ||"Area, Street, Sector, Village"}</label>
                      <input className="w-100 form-control" type="text" placeholder="Enter area..." disabled value={dprFormik.values?.vendorDetails?.vendorAddressId?.address2} />
                    </div>
                    <div className="form-details">
                      <label htmlFor="">{langPreference?.state ||"State"}</label>
                      <select className="form-control" aria-label="Small select example" disabled value={dprFormik.values?.vendorDetails?.vendorAddressId?.state}>
                        <option value={dprFormik.values?.vendorDetails?.vendorAddressId?.state}>{dprFormik.values?.vendorDetails?.vendorAddressId?.state}</option>
                      </select>
                    </div>
                    <div className="form-details">
                      <div className="row">
                        <div className="col-lg-6">
                          <label htmlFor="startDate">{langPreference?.city ||"City"}</label>
                          <select className="form-control" disabled aria-label="Small select example" value={dprFormik.values?.vendorDetails?.vendorAddressId?.city}>
                            <option value={dprFormik.values?.vendorDetails?.vendorAddressId?.city}>{dprFormik.values?.vendorDetails?.vendorAddressId?.city}</option>
                          </select>
                        </div>
                        <div className="col-lg-6">
                          <label htmlFor="endDate">{langPreference?.pincode ||"Pincode"}</label>
                          <input className="form-control" type="text" placeholder="Enter pin" disabled value={dprFormik.values?.vendorDetails?.vendorAddressId?.pinCode} />
                        </div>
                      </div>
                    </div>
                    <hr />
                  </>}
                </form>
              </div>
            </div> */}
            <div className="col-12 col-xl-12">
              <div className="items-detail-box position-relative ">
                <div className='d-flex justify-content-between align-items-center bb'>
                  <h6 className='mb-0 pb-0' style={{ border: "none" }}>{langPreference?.items_details ||"Items details"}</h6>
                </div>

                {dprFormik.values?.vendorId && (
                  <>
                    {dprFormik?.values?.items?.map((ele, index) => (
                      <div key={index+"dprItem"}>
                        <DprItemComp  values={ele} dprId={dprId} _id={dprId || dprFormik?.values?._id} isCopy={true}  position={index} itemCopyHandler={itemCopyHandler} removeItemHandler={removeItemHandler} addLoader={addLoader} details={dprFormik.values} handleSave={handleSaveItem}/>
                      </div>
                    ))}
                    <div className='d-flex justify-content-end'>
                    {dprFormik.values.vendorId && <button className="add-vendor mb-sm-0 mb-sm-2" data-bs-toggle="modal" data-bs-target={`#${addItemModalId}`} onClick={() => { itemFormik.resetForm(); setSelectItemId("") }}>
                    <i className="bi bi-plus"></i>{langPreference?.add || "Add Item"}
                  </button>}
                    </div>
                  </>
                )}

                <hr />
              </div>
            </div>
            {/* <div className="col-12 col-xl-3">
              <div className="order-review">
                <div className="d-flex bb justify-content-between align-items-center">
                  <h6>{langPreference?.preview || "Preview"}</h6>

                </div>
                {dprFormik.values.vendorId && <div className="org">
                  <div className="d-flex  justify-content-between align-items-center">
                    <h4 className="mb-3">{dprFormik.values?.vendorDetails?.name}</h4>
                 
                  </div>

                  {dprDetails?.dprNumber && <div className="d-flex justify-content-between align-items-center">
                    <p className="dark">{langPreference?.dpr_number || "DPR number"}</p>
                    <p className="light">{dprDetails?.dprNumber}</p>
                  </div>}

                  <div className="d-flex justify-content-between align-items-center">
                    <p className="dark">{langPreference?.date || "Date"}</p>
                    <p className="light">{moment(dprFormik.values?.createDate).format("DD/MM/YYYY")}</p>
                  </div>
                  {dprDetails?.status && <div className="d-flex justify-content-between align-items-center">
                    <p className="dark">{langPreference?.status || "Status"}</p>
                    <p className="light">{dprDetails?.status}</p>
                  </div>}
                  <div className="d-flex justify-content-between align-items-center">
                    <h6>{langPreference?.items || "Items"} ({langPreference?.count || "count"}: {dprFormik?.values?.items?.filter(item => item?.isSaved)?.length})</h6>
                  </div>
                  <table className="table">
                    <thead>
                      <tr>
                        <th style={{ width: "30%" }} scope="col">{langPreference?.name || "NAME"}</th>
                        <th scope="col">{langPreference?.qty || "QTY"}</th>
                        <th scope="col">{langPreference?.unit || "UNIT"}</th>
                      </tr>
                    </thead>
                    <tbody>

                      {dprFormik.values?.vendorId && <>
                        {dprFormik?.values?.items?.filter(item => item?.selected && item?.saveId)?.map((ele, index) => {
                          return (<tr>
                            <td style={{ overflowWrap: "anywhere" }}>{ele?.itemDetails?.name}</td>
                            <td>
                            {getVolumeQty(ele)}
                            </td>
                            <td>
                              {ele?.itemDetails?.unitType}
                            </td>
                          </tr>)
                        })
                        }
                      </>}
                    </tbody>
                  </table>
                </div>}
                {dprFormik.values.remarks && <div className="add-box">
                  <h5>{langPreference?.remarks || "Remarks"}:</h5>
                  <p className="mb-0">{dprFormik.values?.remarks}</p>
                </div>}
              </div>
            </div> */}
          </div>
        </div>
      </div>
      {dprFormik.values.vendorId &&
        <ItemsAddModal formik={itemFormik} modalId={addItemModalId} addLoader={addItemLoader} type="dpr" removeItem={removeItem} selectItem={selectItemId} vendorId={dprFormik?.values?.vendorId} listType={"Sub-Contractor"}/>
      }
      <VendorAddModal formik={VendorFormik} modalId={addVendorModalId} addLoader={addItemLoader} />
      <div className="col">
        <div className="btn btn-primary hidden" style={{ display: "none" }} data-bs-toggle="modal" id='vendorCreate' data-bs-target={`#${addVendorModalId}`} onClick={() => { itemFormik.resetForm() }} >Create Vendor</div>
      </div>
      <ConfirmationModal modalId={ItemDeleteModalId} handleConfirm={handleItemDelete} message={langPreference?.are_you_sure_to_delete || <>Are you sure you want to <br /> Delete ?</>} />
      {/* for po approval */}
      <button className='d-none' id="submitModalBtn1" data-bs-toggle="modal" data-bs-target={`#${submitConfirmModal}`} />
      <ConfirmationModal modalId={submitConfirmModal} handleConfirm={() => handleConfirmation(false)} message={langPreference?.submit_confirmation || <>Are you sure you want to <br /> Submit ?</>} />
      <button className='d-none' id="statusModalBtn" data-bs-toggle="modal" data-bs-target={`#${statusModalId}`} />
      <StatusModal
        modalId={statusModalId}
        message={confirmationMsg}
        handleClose={handleApprovalModalClose}
      />

    </>
  )
}

export default AddDailyProgressReport