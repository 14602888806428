import React, { useEffect, useState } from 'react'
import { downloadDprById, getDailyLabourReportByIdApi, approveOrRejectDailyLabourReportApi } from '../../../utils/ApiEndpointFunctions'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useUserContext } from '../../../context/userContext';
import ConfirmationModal from '../../../components/modal/ConfirmationModal';
import toast from 'react-hot-toast';
import Spinwheel from '../../../components/common/Spinwheel';
import moment from 'moment';
import StatusModal from '../../../components/modal/StatusModal';


function ViewDLR() {
  const params = useParams();
  const { userDetails,langPreference } = useUserContext();
  const { id } = params;
  let addressKey = ["address1", "address2", "city", "state", "pinCode"]
  const [details, setDetails] = useState();
  const navigate = useNavigate();
  const confirmModalId = "conFirmModalID"
  const statusModalId = "statusModal"
  const [updatedStatus,setUpdatedStatus] = useState(null)
  const [confirmationMsg, setConfirmationMsg] = useState('')
  const [navigationRoute, setNavigationRoute] = useState("")
  const [download,setDownload] = useState(false)
  const location = useLocation();
  

  const getDailyLabourReportById = async (id) => {
    try{
      const res = await getDailyLabourReportByIdApi(id)
      
      if (res.status === 200) {
        const podata = res.data?.data
        setDetails(podata);
      }
    }catch(err){
      console.log(err)
      if (err.response && err.response.data) {
        toast.error(err.response.data.message)
      } else {
        toast.error("Something went wrong")
      }
    }
  }
  

  const handleApproval = async () => {
    try {
      const res = await approveOrRejectDailyLabourReportApi({ id: id,status:updatedStatus })
      if (res.status === 200) {
        // toast.success(res.data?.message)
        document.getElementById(confirmModalId).click()
        setConfirmationMsg(res?.data?.message || `DLR ${details?.dprNumber || ""} is successfully ${updatedStatus}.`)
        document.getElementById("statusModalBtn").click()
        setNavigationRoute('')
        getDailyLabourReportById(id)
      }
    } catch (error) {
      console.log(error)
      toast.error("something went wrong");
    }
  }


  const handleCloseStatusModal = () => {
    if(navigationRoute){
      navigate(navigationRoute)
    }
  }

  useEffect(() => {
    if (id) {
      getDailyLabourReportById(id)
    }
  }, [])

  const getDownloadDpr =async()=>{
    try {
      setDownload(true)
      const response = await getDailyLabourReportByIdApi(id,true)
      const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', details?.dlrNumber ? `${details.dlrNumber}.xlsx` :'DLR.xlsx'); // or any other filename
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setDownload(false)
      toast.success("Successfully downloaded");
    } catch (error) {
      setDownload(false)
      toast.error("Failed to download");
    }
  }




  return (
    <>
      <div className="main-content">
        <div className="container-fluid">
          <div className="row">
            <div className="back"><span onClick={() => navigate(location?.state?.path ? location?.state?.path : -1, { state: { ...location?.state } })} className="back cursor-pointer" ><i className="bi bi-chevron-left"></i> {langPreference.back || "Back"}</span></div>
            <div className="main-content">
              
              <div className="row d-flex justify-content-center">
                <div className="card col-lg-10 col-md-12 col-xxl-11 my-3 p-5">
                  <div>
                    <div className='fs-3 fw-normal'>
                      {langPreference.site_details || "Site details"}
                    </div>
                    <div className="row row-cols-2">
                      <div className="">{langPreference.site_name || "Site name"}</div>
                      <div>{details?.site?.[0]?.name}</div>
                    </div>
                    {/* <div className="row row-cols-2">
                      <div className="">Site address</div>
                      <div>{details?.site?.[0]?.addressId?.address1}</div>
                    </div> */}
                    <hr />
                  </div>
                  <div className="row row-cols-2 mt-3">
                    <div className="fw-bold">{langPreference.dlr_number || "DLR Number"}</div>
                    <div>{details?.dlrNumber}</div>
                  </div>
                  <div className="row row-cols-2 mt-3">
                    <div className="fw-bold">{langPreference.date || "Date"}</div>
                    <div>{moment(details?.createDate).format("DD/MM/YYYY")}</div>
                  </div>
    
                  <div className="row row-cols-2 mt-3">
                    <div className="fw-bold">{langPreference.status || "Status"}</div>
                    <div className='text-capitalize'>{details?.status}</div>
                  </div>
                  
                  <hr/>
                  <h3>
                    {langPreference.items || "Items"} ({details?.items?.length || 0})
                  </h3>

                      <div className='w-100 mt-1'>
                        <div className="table-responsive">
                          <table className="table table-bordered" >
                            <thead>
                              <tr>
                                 <th colSpan={3}>{langPreference?.subcontractor_name ||"Subcontractor name"}</th>
                                  <th colSpan={3}>{langPreference?.item ||"Item"}</th>
                                  <th colSpan={1}>{langPreference?.no_of_labour ||"No of Labour"}</th>
                                  <th colSpan={1}>{langPreference?.technical ||"Technical"}</th>
                                  <th colSpan={1}>{langPreference?.non_technical ||"Non Technical"}</th>
                              </tr>
                            </thead>
                            <tbody>
                              
                              {details?.items?.map((item, qtyIndex) => (
                                <tr key={qtyIndex} >
                                  <td colSpan={3}>{item?.vendorName} </td>
                                  <td colSpan={3}>{item?.name} </td>
                                  <td >{item?.noOfLabour} </td>
                                  <td >{item?.noOfTechnical} </td>
                                  <td>{item?.noOfNonTechnical}</td>
                                </tr>
                              ))}
                               <tr>
                                  <td colSpan={3} style={{width:"200px"}}><span className='fw-bold'>{langPreference?.total_no_of_count || "Total No of count"}</span></td>
                                  <td colSpan={3} style={{width:"200px"}}></td>
                                  <td>{details?.totalLabour}</td>
                                  <td>{details?.totalTechnical}</td>
                                  <td>{details?.totalNonTechnical}</td>

                                </tr>
                            </tbody>
                          </table>

                          
                         
                        </div>
                      </div>
                      <hr />
                        
                  {details?.reason && <>
                    <div className='fs-5 fw-normal'>
                      {langPreference.reason_for_labor || "Reason for labor"}:
                    </div>
                    <div className=''>{details?.reason}</div>
                    <hr />
                    </>}

                  <div>
                    {details?.createdBy?.firstName && <div className="row row-cols-2 mt-3">
                      <div className="fw-bold">{langPreference.created_by || "Created By"}</div>
                      <div className='text-capitalize'>{details?.createdBy?.firstName || ""} {details?.createdBy?.lastName || ""}</div>
                    </div>}
                    {details?.createdAt && <div className="row row-cols-2 mt-3">
                      <div className="fw-bold">{langPreference.created_on || "Created On"}</div>
                      <div className='text-capitalize'>{details?.createdAt && moment(details?.createdAt).format("DD/MM/YYYY HH:mm")}</div>
                    </div>}
                    {details?.status != "created" && details?.submittedBy?.firstName && <div className="row row-cols-2 mt-3">
                      <div className="fw-bold">{langPreference.submitted_by || "Submitted By"}</div>
                      <div className='text-capitalize'>{details?.submittedBy?.firstName || ""} {details?.submittedBy?.lastName || ""}</div>
                    </div>}
                    {details?.status != "created" && details?.submittedOn && <div className="row row-cols-2 mt-3">
                      <div className="fw-bold">{langPreference.submitted_on || "Submitted On"}</div>
                      <div className='text-capitalize'>{details?.submittedOn && moment(details?.submittedOn).format("DD/MM/YYYY HH:mm")}</div>
                    </div>}
                    {details?.approvedBy?.firstName && <div className="row row-cols-2 mt-3">
                      <div className="fw-bold">{langPreference.approved_by || "Approved By"}</div>
                      <div className='text-capitalize'>{details?.approvedBy?.firstName || ""} {details?.approvedBy?.lastName || ""}</div>
                    </div>}
                    {details?.approvedOn && <div className="row row-cols-2 mt-3">
                      <div className="fw-bold">{langPreference.approved_on || "Approved On"}</div>
                      <div className='text-capitalize'>{details?.approvedOn && moment(details?.approvedOn).format("DD/MM/YYYY HH:mm")}</div>
                    </div>}
                  </div>

                    
                  <div className="d-flex align-items-center justify-contend-start gap-2">
                  {!["approved","rejected"]?.includes(details?.status)   && userDetails?.roleId?.permission.includes("dlrApprove") && <div className="d-flex justify-content-between gap-2 mt-3">
                      <div className="btn btn-primary"  onClick={()=>navigate(`/dlr/update/${details?._id}`)}>{langPreference.edit || "Edit"}</div>
                    </div>}

                    {details?.status!== "created" && details?.status!== "approved" && details?.status!== "rejected" && userDetails?.roleId?.permission.includes("dlrApprove") && <div className="d-flex justify-content-between gap-2 mt-3">
                      <div className="btn btn-primary" data-bs-toggle="modal" data-bs-target={`#${confirmModalId}`} onClick={()=>setUpdatedStatus("approved")}>{langPreference.approve || "Approve"}</div>
                    </div>}
                    {details?.status!== "created" && details?.status== "approved" && userDetails?.roleId?.permission.includes("dlrReject") && <div className="d-flex justify-content-between gap-2 mt-3">
                      <div className="btn btn-primary" data-bs-toggle="modal" data-bs-target={`#${confirmModalId}`} onClick={()=>setUpdatedStatus("rejected")}>{langPreference.reject || "Reject"}</div>
                    </div>}

                      {details?.status?.toLowerCase() === "approved" && <div className="d-flex justify-content-between gap-2 mt-3">
                      <button className="btn create-order text-white" disabled={download} onClick={()=>!download && id && getDownloadDpr(id)}>{langPreference.download || "Download"}</button>
                    </div>}


                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ConfirmationModal modalId={confirmModalId} handleConfirm={handleApproval} message={updatedStatus=="approved" ? langPreference?.approve_confirmation : langPreference?.confirm_reject ||  <>Are you sure you want to <br /> {updatedStatus} ?</>} />
      <button className='d-none' id="statusModalBtn" data-bs-toggle="modal" data-bs-target={`#${statusModalId}`} />
      <StatusModal
        modalId={statusModalId}
        message={confirmationMsg}
        handleClose={handleCloseStatusModal}
      />
    </>
  )
}

export default ViewDLR