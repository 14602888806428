import React, { useEffect, useState } from 'react'
import Spinwheel from '../../components/common/Spinwheel';
import toast from 'react-hot-toast';
import { getReportApi } from '../../utils/ApiEndpointFunctions';
import { useFormik } from 'formik';
import moment from 'moment';
import { formatPrice } from '../../utils/HelperFuncitons';
import FilterReport from '../../components/modal/filterReport';
import { reportFilterInitialValue, reportValidationSchema } from '../../utils/Validation';
// import * as XLSX from 'xlsx';
import * as XLSX from 'xlsx-js-style';
import VendorReport from './VendorReport';
import ItemReport from './ItemReport';
import { useLocation, useNavigate } from 'react-router-dom';
import { useUserContext } from '../../context/userContext';

function Reports(props) {
    const [loading, setLoading] = useState(false)
    const [download, setDownload] = useState(false)
    const [report, setReport] = useState([])
    const filterModalId = "filterModalID"
    const [reportType, setReportType] = useState("")
    const {langPreference} = useUserContext()
    const location = useLocation();
    const {userDetails} = useUserContext()
    const navigate = useNavigate();

    const filterFormik = useFormik({
        initialValues: location?.state?.path == "/report" && location.state?.filterValue ? location.state?.filterValue : reportFilterInitialValue,
        validationSchema: reportValidationSchema(langPreference), 
        onSubmit: async (values) => {
            setLoading(true)
            setReportType(values?.reportType)
            let vendorId = values?.vendorId?.value?._id
            let selectedDate = `${filterFormik.values?.to ? new Date(filterFormik.values?.to).getTime() + "-" : ""}${filterFormik.values?.from ? new Date(filterFormik.values?.from).getTime() : ""}`
            document.getElementById(filterModalId).click()
            try {
                const res = await getReportApi(vendorId, selectedDate, values?.status, values?.serialNumber, values?.uniqueItemId, values?.reportType)
                if (res.status === 200) {
                    setReport(res?.data?.data)
                }
                setLoading(false)
            } catch (error) {
                setLoading(false)
                toast.error(error?.response?.data?.message || "Something went wrong")
                console.log("error", error);
            }
        }
    })


    const handleReset = () => {
        filterFormik.setValues(reportFilterInitialValue)
        setReportType("")
        setReport([])
        navigate("/report", { replace: true })
    }

    const getBalance = (challanAmt, voucherAmt) => {
        return Number(challanAmt || 0) - Number(voucherAmt || 0)
    }

    const vendorReportToExcel = (report) => {
        setDownload(true);
        const workbook = XLSX.utils.book_new();
        const worksheetData = [];
        const defaultHeight = [];
        const merges = [];
        const boldCellStyle = { font: { bold: true }}
        const borderCellStyle = {
            border: {
                right: {
                    style: "thin",
                    color: "000000"
                },
                left: {
                    style: "thin",
                    color: "000000"
                },
                top: {
                    style: "thin",
                    color: "000000"
                },
                bottom: {
                    style: "thin",
                    color: "000000"
                },
            }
        }
        const centerWithBoldStyle = {
            font: { bold: true },
            alignment: {
                vertical: "center",
                horizontal: "center",
                wrapText: '1', // any truthy value here
            },
        }
    
        const defaultZooming = [
            { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 },
            { width: 20 }, { width: 20 }, { width: 20 }
        ];
    
        const addRow = (po, voucher = {}) => {
            worksheetData.push([
                { v: po?.poNumber || '', s: borderCellStyle },
                { v: po?.createDate ? moment(po.createDate).format("DD/MM/YYYY") : '', s: borderCellStyle },
                { v: po?.amount ? Number(Number(po?.amount)?.toFixed(2)) : '', s: borderCellStyle },
                { v: po?.challan?.challanNumber || '', s: borderCellStyle },
                { v: po?.challan?.createdAt ? moment(po?.challan?.createdAt).format("DD/MM/YYYY") : '', s: borderCellStyle },
                { v: po?.challan?.amount ? Number(Number(po?.challan?.amount)?.toFixed(2)) : '', s: borderCellStyle },
                { v: voucher?.voucherNumber || '', s: borderCellStyle },
                { v: voucher?.createDate ? moment(voucher.createDate).format("DD/MM/YYYY") : '', s: borderCellStyle },
                { v: voucher?.amount ? Number(Number(voucher?.amount)?.toFixed(2)) : '', s: borderCellStyle },
            ]);
            defaultHeight.push({ hpx: 40 }, { hpx: 40 }, { hpx: 40 }, { hpx: 40 }, { hpx: 40 });
        };

        if (userDetails?.siteId?.companyId?.name) {
            let startRow = worksheetData.length
            worksheetData.push([{ v: `Company: ${userDetails.siteId.companyId.name}`, s: {...centerWithBoldStyle} }]);
            merges.push({
                s: { r: startRow, c: 0 }, // start row and column
                e: { r: startRow, c: 8 }  // end row and column
            });
         }

         if (userDetails?.siteId?.name) {
            let startRow = worksheetData.length
            worksheetData.push([{ v:  `Site: ${userDetails.siteId.name}`, s: {...centerWithBoldStyle} }]);
            merges.push({
                s: { r: startRow, c: 0 }, // start row and column
                e: { r: startRow, c: 8 }  // end row and column
            });
         }

        let startRow = worksheetData.length
        worksheetData.push([{ v: `Vendor/Subcontractor report from ${moment(filterFormik.values?.to).format("DD/MM/YYYY")} to ${moment(filterFormik.values?.from).format("DD/MM/YYYY")}`, s: {...centerWithBoldStyle} }]);
        merges.push({
            s: { r: startRow, c: 0 }, // start row and column
            e: { r: startRow, c: 8 }  // end row and column
        });
        
        report.forEach((ele) => {
            const vendorStartRow = worksheetData.length;
            worksheetData.push([{ v: ele?.vendorDetails?.name, s: {...centerWithBoldStyle} }]);
            merges.push({
                s: { r: vendorStartRow, c: 0 }, // start row and column
                e: { r: vendorStartRow, c: 8 }  // end row and column
            });
            worksheetData.push(
                ['PO Number', 'PO Date', 'PO Amount', 'Challan No', 'Challan Date', 'Amount', 'Voucher Number', 'Voucher Date', 'Voucher Amount'].map(item => ({ v: item, s: {...centerWithBoldStyle,...borderCellStyle} }))
            );
            ele?.vendorPo?.forEach(po => {
                if (po.combinedVouchers.length > 0) {
                    po.combinedVouchers.forEach((voucher, index) => {
                        let voucherReference = {};
                        if (voucher?.challanNumber?.includes("PO")) {
                            voucherReference.poNumber = voucher?.challanNumber;
                        } else if (voucher?.challanNumber?.includes("CH")) {
                            voucherReference.challan = { challanNumber: voucher.challanNumber };
                        }

                        if (index === 0) {
                            addRow(po, voucher);
                        } else {
                            addRow(voucherReference, voucher);
                        }
                    });
                } else {
                    addRow(po);
                }
            });
    
            worksheetData.push([
                { v: 'Sub total', s: {...boldCellStyle,...borderCellStyle} }, { v: "", s: borderCellStyle }, { v: Number(Number(getTotalValue(ele?.vendorPo, "po"))?.toFixed(2)), s: {...boldCellStyle,...borderCellStyle} },
                { v: 'Challan Value', s: {...boldCellStyle,...borderCellStyle} }, { v: "", s: borderCellStyle }, { v: Number(Number(getTotalValue(ele?.vendorPo, "challan"))?.toFixed(2)), s: {...boldCellStyle,...borderCellStyle} },
                { v: 'Voucher Value', s: {...boldCellStyle,...borderCellStyle} }, { v: "", s: borderCellStyle }, { v: Number(Number(getTotalValue(ele?.vendorPo, "voucher"))?.toFixed(2)), s: {...boldCellStyle,...borderCellStyle} }
            ]);
            worksheetData.push([{ v: 'Balance', s: {...boldCellStyle,...borderCellStyle} }, { v: Number(Number(ele?.balance)?.toFixed(2)), s: {...boldCellStyle,...borderCellStyle} }]);
            worksheetData.push([{ v: 'Advance', s: {...boldCellStyle,...borderCellStyle} }, { v: Number(Number(ele?.advance)?.toFixed(2)), s: {...boldCellStyle,...borderCellStyle} }]);
            worksheetData.push([]);
        });

        // footer
        let footerRow = worksheetData.length
        worksheetData.push([{ v: "Powered by MMS", s: {...centerWithBoldStyle} }]);
        merges.push({
            s: { r: footerRow, c: 0 }, // start row and column
            e: { r: footerRow, c: 8 }  // end row and column
        });

        const worksheet = XLSX.utils.aoa_to_sheet([]);
        XLSX.utils.sheet_add_aoa(worksheet, worksheetData, { origin: "A1", cellStyles: true });
        worksheet['!cols'] = defaultZooming;
        worksheet['!rows'] = defaultHeight;
        worksheet['!merges'] = merges;
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Report');
        XLSX.writeFile(workbook, 'Vendor-Report.xlsx');
        setDownload(false);
    };

    const ItemReportToExcel = (report) => {
        setDownload(true);
        const workbook = XLSX.utils.book_new();
        const worksheetData = [];
        const defaultHeight = []
        const merges = [];
        const boldCellStyle = { font: { bold: true }}
        const borderCellStyle = {
            border: {
                right: {
                    style: "thin",
                    color: "000000"
                },
                left: {
                    style: "thin",
                    color: "000000"
                },
                top: {
                    style: "thin",
                    color: "000000"
                },
                bottom: {
                    style: "thin",
                    color: "000000"
                },
            }
        }
        const centerWithBoldStyle = {
            font: { bold: true },
            alignment: {
                vertical: "center",
                horizontal: "center",
                wrapText: '1', // any truthy value here
            },
        }
    
        const defaultZooming = [
            { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 },
            { width: 20 }, { width: 20 }, { width: 20 },{ width: 20 },{ width: 20 },{ width: 20 }
        ];


        const addRow = (po, voucher = {}, item = {}) => {
            worksheetData.push([
                { v: po?.poNumber || '', s: borderCellStyle },
                { v: po?.createDate ? moment(po.createDate).format("DD/MM/YYYY") : '', s: borderCellStyle },
                { v:po?.amount ? Number(Number(po?.amount)?.toFixed(2)) : '', s: borderCellStyle },
                { v:po?.challan?.challanNumber || '', s: borderCellStyle },
                { v:po?.challan?.createdAt ? moment(po?.challan?.createdAt).format("DD/MM/YYYY") : '', s: borderCellStyle },
                { v:po?.challan?.amount ? Number(Number(po?.challan?.amount)?.toFixed(2)) : '', s: borderCellStyle },
                { v:voucher?.voucherNumber || '', s: borderCellStyle },
                { v:voucher?.createDate ? moment(voucher.createDate).format("DD/MM/YYYY") : '', s: borderCellStyle },
                { v:Number(Number(voucher?.amount)?.toFixed(2))  || '', s: borderCellStyle },
                { v:item?.name ? item?.name : '', s: borderCellStyle },
                { v:item?.unitPrice ? Number(Number(item?.unitPrice)?.toFixed(2)) : '', s: borderCellStyle },
                { v:item?.challanUnitPrice ? Number(Number(item?.challanUnitPrice)?.toFixed(2))  : '', s: borderCellStyle },
            ]);
            defaultHeight.push({ hpx: 40 },{ hpx: 40 },{ hpx: 40 },{ hpx: 40 },{ hpx: 40 })
        };

        if (userDetails?.siteId?.companyId?.name) {
            let startRow = worksheetData.length
            worksheetData.push([{ v: `Company: ${userDetails.siteId.companyId.name}`, s: {...centerWithBoldStyle} }]);
            merges.push({
                s: { r: startRow, c: 0 }, // start row and column
                e: { r: startRow, c: 11 }  // end row and column
            });
         }

         if (userDetails?.siteId?.name) {
            let startRow = worksheetData.length
            worksheetData.push([{ v:  `Site: ${userDetails.siteId.name}`, s: {...centerWithBoldStyle} }]);
            merges.push({
                s: { r: startRow, c: 0 }, // start row and column
                e: { r: startRow, c: 11 }  // end row and column
            });
         }

        let startRow = worksheetData.length
        worksheetData.push([{ v: `Item report from ${moment(filterFormik.values?.to).format("DD/MM/YYYY")} to ${moment(filterFormik.values?.from).format("DD/MM/YYYY")}`, s: {...centerWithBoldStyle} }]);
        merges.push({
            s: { r: startRow, c: 0 }, // start row and column
            e: { r: startRow, c: 11 }  // end row and column
        });

        report.forEach((ele) => {
            const vendorStartRow = worksheetData.length;
            worksheetData.push([{ v: ele?.vendorDetails?.name, s: {...centerWithBoldStyle} }]);
            merges.push({
                s: { r: vendorStartRow, c: 0 }, // start row and column
                e: { r: vendorStartRow, c: 11 }  // end row and column
            });
            defaultHeight.push({ hpx: 40 },{ hpx: 40 },{ hpx: 40 },{ hpx: 40 },{ hpx: 40 })
            worksheetData.push(['PO Number', 'PO Date', 'PO Amount', 'Challan No', 'Challan Date', 'Amount', 'Voucher Number', 'Voucher Date', 'Voucher Amount', 'Item', 'Po Item Rate', 'Challan Item Rate'].map(item => ({ v: item, s: {...centerWithBoldStyle,...borderCellStyle,} })))
            ele?.vendorPo?.forEach(po => {

                const vouchers = po?.combinedVouchers || [];
                const itemsArr = po?.combinedItems?.length ? groupVendorPOItems(po?.combinedItems) : [];
                const maxCount = Math.max(vouchers.length, itemsArr.length, 1);

                return Array.from({ length: maxCount }).map((_, index) => {
                    const voucher = vouchers[index];
                    const item = itemsArr[index];

                    let voucherReference = {};
                    if (voucher?.challanNumber?.includes("PO")) {
                        voucherReference.poNumber = voucher?.challanNumber;
                    } else if (voucher?.challanNumber?.includes("CH")) {
                        voucherReference.challan = { challanNumber: voucher.challanNumber };
                    }

                    if (index === 0) {
                        addRow(po, voucher, item);
                    } else if (voucher && item) {
                        addRow(voucherReference, voucher, item);
                    } else if (voucher) {
                        addRow(voucherReference, voucher, {})
                    } else if (item) {
                        addRow({}, {}, item)
                    }

                });
            })

            worksheetData.push([
                { v: 'Sub total', s: {...boldCellStyle,...borderCellStyle} }, { v: "", s: borderCellStyle }, { v: Number(Number(getTotalValue(ele?.vendorPo, "po"))?.toFixed(2)), s: {...boldCellStyle,...borderCellStyle} },
                { v: 'Challan Value', s: {...boldCellStyle,...borderCellStyle} }, { v: "", s: borderCellStyle }, { v: Number(Number(getTotalValue(ele?.vendorPo, "challan"))?.toFixed(2)), s: {...boldCellStyle,...borderCellStyle} },
                { v: 'Voucher Value', s: {...boldCellStyle,...borderCellStyle} }, { v: "", s: borderCellStyle }, { v: Number(Number(getTotalValue(ele?.vendorPo, "voucher"))?.toFixed(2)), s: {...boldCellStyle,...borderCellStyle} },
                { v: "", s: borderCellStyle },{ v: "", s: borderCellStyle },{ v: "", s: borderCellStyle }
                // 'Item Value', Number(getTotalValue(ele?.vendorPo, "poItem")), Number(getTotalValue(ele?.vendorPo, "challanItem"))
            ]);
            worksheetData.push([{ v: 'Balance', s: {...boldCellStyle,...borderCellStyle} }, { v: Number(Number(ele?.balance)?.toFixed(2)), s: {...boldCellStyle,...borderCellStyle} }]);
            worksheetData.push([{ v: 'Advance', s: {...boldCellStyle,...borderCellStyle} }, { v: Number(Number(ele?.advance)?.toFixed(2)), s: {...boldCellStyle,...borderCellStyle} }]);
            worksheetData.push([]);
        });

        let footerRow = worksheetData.length
        worksheetData.push([{ v: "Powered by MMS", s: {...centerWithBoldStyle} }]);
        merges.push({
            s: { r: footerRow, c: 0 }, // start row and column
            e: { r: footerRow, c: 11 }  // end row and column
        });
        const worksheet = XLSX.utils.aoa_to_sheet([]);
        XLSX.utils.sheet_add_aoa(worksheet, worksheetData, { origin: "A1", cellStyles: true });
        worksheet['!cols'] = defaultZooming;
        worksheet['!rows'] = defaultHeight;
        worksheet['!merges'] = merges;
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Report');
        XLSX.writeFile(workbook, 'Item-Report.xlsx');
        setDownload(false);
    };


    const exportToExcel = () => {
        if (filterFormik?.values?.reportType?.toLowerCase() === "vendor") {
            vendorReportToExcel(report)
        } else if (filterFormik?.values?.reportType?.toLowerCase() === "item") {
            ItemReportToExcel(report)
        } else {
            toast.error("Something Went Wrong")
        }
    }

    const groupVendorPOItems = (itemsList) => {
        if (!itemsList || !Array.isArray(itemsList)) return [];
        return itemsList.reduce((acc, item) => {
            const existingItem = acc.find(i => i.name === item.name);

            if (!existingItem) {
                acc.push({ name: item.name, unitPrice: 0, challanUnitPrice: 0 });
            }

            if (item.type === 'poItem') {
                acc.find(i => i.name === item.name).unitPrice += item.unitPrice || 0;
            } else if (item.type === 'challanItem') {
                acc.find(i => i.name === item.name).challanUnitPrice += item.challanUnitPrice || 0;
            }

            return acc;
        }, [])
    }


    const getTotalValue = (list, type) => {
        let amt = 0
        if (type === "po") {
            list.forEach(po => {
                amt += po?.amount || 0
            })
        } else if (type === "challan") {
            list.forEach(po => {
                amt += po?.challan?.amount || 0
            })
        } else if (type === "voucher") {
            list.forEach(po => {
                po?.combinedVouchers?.forEach(voch => {
                    amt += voch?.amount || 0
                })
            })
        } else if (type === "poItem") {
            list.forEach(po => {
                groupVendorPOItems(po?.combinedItems)?.forEach(item => {
                    amt += item?.unitPrice || 0
                })
            })
        } else if (type === "challanItem") {
            list.forEach(po => {
                groupVendorPOItems(po?.combinedItems)?.forEach(item => {
                    amt += item?.challanUnitPrice || 0
                })
            })
        }

        return amt
    }

    useEffect(() => {
        if (location?.state?.path == "/report" && location.state?.filterValue) {

            filterFormik.handleSubmit()
        }
    }, [location])
console.log("filterFormik",filterFormik?.values);


    return (
        <>
            <div className="main-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="main-content report-container">
                           <div className='report-header'>
                           <div>
                            {props.type !== "dashboard" && <div className="dash-nav1 dash-nav-report">
                                <div className="d-flex justify-content-between align-items-center">
                                    <h3 className="mb-0 font-blue">{langPreference?.reports || "Reports"}</h3>
                                            <div className="d-flex align-items-center justify-content-end">
                                                <div className="dropdown">
                                                    <button className="btn btn-dark me-2" type="button" disabled={!Object.values(filterFormik?.values).filter(Boolean).length} onClick={handleReset} >
                                                    {langPreference?.reset_filter || "Reset filter"}
                                                    </button>
                                                    <button className="btn filter  dropdown-toggle me-2" type="button" data-bs-toggle="modal" data-bs-target={`#${filterModalId}`}  >
                                                        <i className="bi bi-funnel me-2"></i> {langPreference?.filter || "Filter"}
                                                    </button>
                                                    {Boolean(reportType && report?.length) && <button className="btn btn-dark me-2" disabled={download} type="button" onClick={exportToExcel} >
                                                         {langPreference?.download || "Download"}
                                                    </button>}
                                                </div>
                                            </div>
                                </div>
                            </div>}
                            <div className="d-flex justify-content-end align-items-end">
                                
                            </div>
                            </div>
                           </div>
                            <div className='report-content' style={{marginTop:'6rem'}}>
                            {loading ?
                                <div className='d-flex align-items-center justify-content-center'><Spinwheel /></div>
                                :
                                report?.length > 0 ?
                                    reportType === "vendor" ?
                                        <VendorReport filterFormik={filterFormik} report={report} getTotalValue={getTotalValue} getBalance={getBalance} />
                                        :
                                        <ItemReport filterFormik={filterFormik} report={report} getTotalValue={getTotalValue} getBalance={getBalance} groupVendorPOItems={groupVendorPOItems} />
                                    :
                                    <h4 className='text-center'>{reportType ? langPreference?.data_not_found||"Data Not Found" : langPreference?.select_date_range_report || "Please select date range and report type"} </h4>
                            }
                            </div>

                        </div>
                    </div>
                    <FilterReport modalId={filterModalId} formik={filterFormik} clickHandler={() => filterFormik.handleSubmit()} />
                </div>
            </div>
        </>
    )
}

export default Reports