import React, { useEffect, useState } from 'react'
import Spinwheel from '../common/Spinwheel'
import { useUserContext } from '../../context/userContext';
import AsyncSelect from 'react-select/async';
import { debounce } from "lodash";
import { getItemSuggestionApi, getSiteItemApi } from '../../utils/ApiEndpointFunctions';

function InventoryItemModal(props) {
  const { modalId, formik, disable } = props
  const {userDetails,langPreference} = useUserContext()
  const hasWriteAccess = userDetails?.roleId?.permission?.includes("inventoryWrite")
  const isNew = !formik?.values?._id

  const filteritems = async (inputValue, cb) => {
    try {
      const res = await getSiteItemApi(inputValue)
      if (res.status === 200) {
        let fetchedItem = res.data?.data?.data?.map(ele => ({ label: ele?.details?.name, value: {...ele?.details, _id: ele?.details?.uniqueItemId } }))
        cb(fetchedItem);
      }
    } catch (error) {
      console.log(error)
    }
  };

  const itemsOnChange = debounce(filteritems, 1000);

  const itemsOptions = (inputValue, callback) => {
    itemsOnChange(inputValue, callback);
  }

  const handleItemSelect = (e) => {
    formik.setFieldValue("name", e.value);
    formik.setFieldValue("hsnCode", e.value?.hsnCode);
    formik.setFieldValue("localName", e.value?.localName);
    formik.setFieldValue("description", e.value?.description);
    formik.setFieldValue("unitType", e.value?.unitType);
    formik.setFieldValue("tax", e.value?.tax);
    formik.setFieldValue("uniqueItemId", e.value?._id);
  }

  return (
    <>
      <div
        className="modal fade"
        id={modalId}
        tabIndex="-1"
        aria-labelledby="deleteModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog  modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-0">

              <button type="button" id="confirmModalCloseBtn" className="btn-close1" data-bs-dismiss="modal" aria-label="Close"><i className="bi bi-x-lg"></i></button>
            </div>
            <div className="modal-body ">
              <div className="">
                <div className="bb">
                  <h5>{langPreference?.inventory_item || "Inventory Item"}</h5>
                </div>
                <form onSubmit={formik?.handleSubmit} className="my-fx">

                  {isNew ? <div className="ed-box">
                    <label htmlFor="name" className="form-label">{langPreference.name || "Name"}</label>
                    <AsyncSelect
                        className="col"
                        placeholder="Select..."
                        cacheOptions
                        loadOptions={itemsOptions}
                        defaultOptions
                        name='name'
                        value={formik?.values?.name ? { label: formik?.values?.name?.name, value: formik?.values?.name } : null}
                        onChange={(e) => { handleItemSelect(e) }}
                      />
                     {formik?.touched?.name && formik?.errors?.name ? (<div className='req-error'>{formik?.errors?.name}</div>) : null}
                  </div> 
                    : <div className="ed-box">
                    <label htmlFor="name" className="form-label">{langPreference.name || "Name"}</label>
                    <input type="text" className="form-control" id="name" placeholder="Enter " disabled={true} name='name' value={formik?.values?.name} />
                  </div>
                    }

                  <div className="ed-box">
                    <label htmlFor="localName" className="form-label">{langPreference.local_name || "Local name"}</label>
                    <input type="text" className="form-control" id="localName" placeholder="Enter " disabled={true} name='localName' value={formik?.values?.localName} />
                  </div>
                  <div className="ed-box">
                    <label htmlFor="description" className="form-label">{langPreference.description || "Description"}</label>
                    <input type="text" className="form-control" id="description" placeholder="Enter " disabled={true} name='description' value={formik?.values?.description} />
                  </div>
                  
                  <div className="ed-box">
                    <label htmlFor="hsnCode" className="form-label">{langPreference.hsn_code || "HSN Code"}</label>
                    <input type="text" className="form-control" id="hsnCode" placeholder="Enter " disabled={true} name='hsnCode' value={formik?.values?.hsnCode} />
                  </div>
                  {!isNew &&<div className="ed-box">
                    <label htmlFor="availableQty" className="form-label">{langPreference.available_quantity || "Available quantity"}</label>
                    <input type="text" className="form-control" id="availableQty" placeholder="Enter " disabled={true} name='availableQty' value={formik?.values?.availableQty} />
                  </div>}
                  
                  <div className="ed-box">
                    <label htmlFor="unitType" className="form-label">{langPreference.unit || "Unit"}</label>
                    <input type="text" className="form-control" id="unitType" placeholder="Enter " disabled={true} name='unitType' value={formik?.values?.unitType} />
                  </div>

                  {
                    hasWriteAccess && <>
                      <div className="ed-box">
                        <label htmlFor="itemType">{langPreference?.asset_type || "Asset Type"}</label>
                        <select className="form-select" name='itemType' aria-label="Default select example" value={formik?.values?.itemType?.toLowerCase()} onChange={formik?.handleChange} onBlur={formik?.handleBlur}  >
                          <option value="fixed">Fixed</option>
                          <option value="consumable">Consumable</option>
  
                        </select>
                        {formik?.touched?.itemType && formik?.errors?.itemType ? (<div className='req-error'>{formik?.errors?.itemType}</div>) : null}

                      </div>
                    <div className="ed-box">
                    <label htmlFor="minQty" className="form-label">{langPreference.min_qty_alert || "Min Qty Alert"}</label>
                    <input type="text" className="form-control" id="minQty" placeholder="Enter " name='minQty' disabled={!hasWriteAccess} value={formik?.values?.minQty} onChange={(e)=>{
                          if (/^\d*$/.test(e.target.value)) {
                            formik?.handleChange(e)
                          }
                    }} onBlur={formik?.handleBlur}  />
                    <p className='fs-6'>{langPreference?.notification_quantity_alert || `Notification will be sent when available quantity goes below ${formik?.values?.minQty ||0}`}</p>
                    {formik?.touched?.minQty && formik?.errors?.minQty ? (<div className='req-error'>{formik?.errors?.minQty}</div>) : null}
                  </div>
                    <div className="ed-box">
                    <label htmlFor="addedQty" className="form-label">{langPreference.add_quantity || "Add quantity"}</label>
                    <input type="text" className="form-control" id="addedQty" placeholder="Enter " name='addedQty' disabled={!hasWriteAccess} value={formik?.values?.addedQty} onChange={(e)=>{
                      if (/^\d*$/.test(e.target.value)) {
                        formik?.handleChange(e)
                      }
                      }} onBlur={formik?.handleBlur}  />
                    {formik?.touched?.addedQty && formik?.errors?.addedQty ? (<div className='req-error'>{formik?.errors?.addedQty}</div>) : null}
                  </div>
                  <div className="ed-box d-flex gap-2">
                        <label class="form-check-label" htmlFor="flexSwitchCheckChecked">{langPreference.track || "Track"}</label>
                      <div class="form-check form-switch">
                        <input class="form-check-input" name='isTrack' type="checkbox" role="switch" id="flexSwitchCheckChecked" checked={formik?.values?.isTrack} onChange={(e)=>formik?.setFieldValue("isTrack",e?.target?.checked)} onBlur={formik?.handleBlur}/>
                      </div>
                  {formik?.touched?.isTrack && formik?.errors?.isTrack ? (<div className='req-error'>{formik?.errors?.isTrack}</div>) : null}
                  </div>

                    </> 
                  }

                  <div className="d-flex mt-4 justify-content-end align-items-center">
                  {userDetails?.roleId?.permission?.includes("inventoryWrite") && <button disabled={disable} type='submit' className="save">
                      {langPreference.save || "Save"}
                    </button> }  
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default InventoryItemModal