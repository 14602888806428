import { debounce } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react'
import AsyncSelect from 'react-select/async';
import DatePicker from "react-datepicker";
import { getItemsApi,getvendorsApi, submitDailyLabourReportApi, getDailyLabourReportByIdApi } from '../../../utils/ApiEndpointFunctions';
import { useFormik } from 'formik';
import { dlrAddInitalValue, dlrAddValidationSchema,  } from '../../../utils/Validation';
import Spinwheel from '../../../components/common/Spinwheel';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import ConfirmationModal from '../../../components/modal/ConfirmationModal';
import { useUserContext } from '../../../context/userContext';
import moment from 'moment';
import StatusModal from '../../../components/modal/StatusModal';
import Select from 'react-select';

function AddOrUpdateDLR() {
  const [addLoader, setAddLoader] = useState(false)
  const submitConfirmModal = "submitConfirmModal1"
  const statusModalId = "statusModal1"
  const navigate = useNavigate();
  const params = useParams();
  const { dlrId } = params;
  const [dprDetails, setDprDetails] = useState({})
  const { userDetails,langPreference } = useUserContext();
  const [confirmationMsg, setConfirmationMsg] = useState('');
  const [loading,setLoading] = useState(false)
  const [vendorOptions,setVendorOptions] = useState([])


  const handleSelectDate = (e, eleType) => {
    if (eleType === 'dueDate') {
      dlrFormik.setFieldValue("dueDate", new Date(e).toISOString())
    } else {
      dlrFormik.setFieldValue("createDate", new Date(e).toISOString())
    }
  };

  const handleSelect = (e,index,type) => {
    dlrFormik.setFieldValue(`items[${index}].[${type}].label`, e?.label)
    dlrFormik.setFieldValue(`items[${index}].[${type}].value`, e.value)
    if(type=="vendorId"){
      dlrFormik.setFieldValue(`items[${index}].itemId.label`, "")
      dlrFormik.setFieldValue(`items[${index}].itemId.value`, "")
    }
  }

  const gevendors = async () => {
    try {
      const type = "Sub-Contractor"
      const res = await getvendorsApi("", "", "", type)
      if (res.status === 200) {
        let fetchList = res.data?.data?.vendors?.map(ele => ({ label: ele.name, value: ele?._id }))
        setVendorOptions(fetchList)
      }
    } catch (error) {
      console.log(error)
    }
  };



  const dprSubmit = async (values) => {
    const payload = {
      ...values,
      items:values?.items?.map(ele=>{return {...ele,itemId:ele?.itemId?.value,vendorId:ele?.vendorId?.value}})
    }
    setAddLoader(true)
    try {
      const res =  await submitDailyLabourReportApi(payload);
      if (res.status === 200) {
        document.getElementById(submitConfirmModal).click()
        setConfirmationMsg(res?.data?.message || `DLR ${res.data?.data?.dprNumber || ""} is successfully ${dprDetails?.status === "submitted" ? "updated." : "submitted."}`)
        document.getElementById('statusModalBtn').click()
      }
    } catch (error) {
      error && error.response ?
        toast.error(error.response?.data?.message) :
        toast.error("something went wrong");
    }
    setAddLoader(false)
  }


  const handleSumbitApproval = (confirmation) => {
    dlrFormik.validateForm()
    const errors = Object.keys(dlrFormik.errors)  
    if (errors?.length > 0 || confirmation) {
      dlrFormik.handleSubmit()
    }else {
      document.getElementById("submitModalBtn1").click()
    }
    

  }

  const dlrFormik = useFormik({
    initialValues: dlrAddInitalValue,
    onSubmit: dprSubmit,
    validationSchema: dlrAddValidationSchema(langPreference)
  })


  // Function to fetch items based on vendorId
const filterVendorItems = async (inputValue, cb, vendorId) => {
  if (!vendorId) {
    cb([]);
    return;
  }

  try {
    const res = await getItemsApi(inputValue, "", "", vendorId);
    if (res.status === 200) {
      let fetchedItems = res.data?.data?.items?.map(ele => ({
        label: ele?.name,
        value: ele?._id,
      }));
      // console.log("Items:", fetchedItems);
      cb(fetchedItems);
    }
  } catch (error) {
    console.error("Error fetching items:", error);
  }
};

// Create a memoized function for each item based on its vendorId
const getItemOptions = useCallback((index) => {
  return debounce((inputValue, cb) => {
    filterVendorItems(inputValue, cb, dlrFormik?.values?.items[index]?.vendorId?.value);
  }, 500);
}, [dlrFormik.values.items]);


 const getDlrById = async()=>{
  setLoading(true)
  try {
    const res =  await getDailyLabourReportByIdApi(dlrId);
    if (res.status === 200) {
      const details = res?.data?.data
      details.items = details?.items?.map(ele=>{
          return{
            ...ele,
            vendorId:{label:ele?.vendorName,value:ele?.vendorId},
            itemId:{label:ele?.name,value:ele?.itemId},
          }
        })
      dlrFormik.setValues(details)
      // console.log("data",details,dlrFormik?.values);
      
    }
  } catch (error) {
    error && error.response ?
      toast.error(error.response?.data?.message) :
      toast.error("something went wrong");
  }
  setLoading(false)
 }


  useEffect(() => {
    if (dlrId) {
      getDlrById()
    }
    gevendors()
    dlrFormik.validateForm()
  }, [dlrId])


  const handleApprovalModalClose = () => {
     navigate("/dlr")
  }



const handleAddItemQty =(index)=>{
  let newItem =     {
    vendorId: "",
    itemId:"",
    noOfLabour:0,
    noOfTechnical:0,
    noOfNonTechnical:0,
  }

  let list  = []
   dlrFormik.values.items.map((ele,ind)=>{
    if(index==ind){
      list.push(ele,newItem)
    }else{
      list.push(ele)
    }
   })
   dlrFormik.setFieldValue("items",list)
}


const handleRemoveItemQty =(index)=>{
  let newList = dlrFormik.values.items.filter((ele,ind)=>ind!=index)
   dlrFormik.setFieldValue("items",newList)
}


const getTotalCount =(type)=>{
  let total =0
  dlrFormik?.values?.items?.forEach(ele=>{
    if(ele[type] && !isNaN(ele[type])){
      total+=Number(ele[type])
    }
  })
  return total
}

console.log("fomik",dlrFormik);

  return (
    <>
      <div className="dash-nav1">
        <div className="back"><span onClick={() => navigate(-1)} className="back cursor-pointer" ><i className="bi bi-chevron-left"></i>{langPreference?.back ||"Back"}</span></div>
        <div className="align-items-center d-block d-lg-flex justify-content-between">
          <h3 className="mb-0 font-blue">{dlrId ?  langPreference?.edit_dlr || "Edit DLR" : langPreference?.create_new_dlr || "Create New DLR"}</h3>
          {dlrFormik.values.items?.length && <div>            
            <button type="button" disabled={addLoader} className="mb-2 mb-sm-2 sbm-purchase" onClick={()=>handleSumbitApproval()}>
              {addLoader  ? <Spinwheel sizeClass={"spin-small"} /> : langPreference?.submit_for_approval || "Submit for approval"}
            </button>


          </div>}
        </div>
      </div>
      <div className="dash-table">
        <div className="container">
          <div className="row gap-xl-0">
            <div className="col-12 col-md-8">
              <div className="items-detail-box position-relative ">
                <div className='d-flex justify-content-between align-items-center bb'>
                </div>
                  <>
                      <div className='' >
                       <div className='d-flex align-items-center justify-content-between my-3'>
                        <div className="w-auto">
                          <label htmlFor="startDate">{langPreference?.date ||"Date"} <span className='req-error'>*</span></label>
                          <DatePicker dateFormat="dd/MM/yyyy" placeholderText='Create date' className='form-select cursor-pointer w-auto' name='createDate' selected={dlrFormik.values?.createDate} onChange={(e) => { handleSelectDate(e, "createDate") }}  />
                          <span id="startDateSelected"></span>
                        </div>
                        <div className=''>
                            <label htmlFor='workDescription'>{langPreference?.reason_for_labor ||"Reason for Labor"} <span className='req-error'>*</span></label>
                            <input
                              className="form-control w-100"
                              name="reason"
                              value={dlrFormik?.values?.reason}
                              onChange={dlrFormik.handleChange}
                              onBlur={dlrFormik.handleBlur}
                            />
                            {dlrFormik?.touched?.reason && dlrFormik?.errors?.reason ? (<div className="req-error">{dlrFormik?.errors?.reason}</div>) : null}
                          </div>
                       </div>
                        <div className='w-100 mt-1'>
                          <div className="table-responsive" style={{ overflowX: "auto" }}>
                            <table className="table table-bordered" style={{ minWidth: "200px" }}>
                              <thead>
                                <tr>
                                  <th colSpan={3}>{langPreference?.subcontractor_name ||"Subcontractor name"} <span className='req-error'>*</span> </th>
                                  <th colSpan={3}>{langPreference?.item ||"Item"} <span className='req-error'>*</span></th>
                                  <th colSpan={1}>{langPreference?.technical ||"Technical"} <span className='req-error'>*</span></th>
                                  <th colSpan={1}>{langPreference?.non_technical ||"Non Technical"} <span className='req-error'>*</span></th>
                                  <th colSpan={1}>{langPreference?.no_of_labour ||"No of Labour"} <span className='req-error'>*</span></th>
                                  <th colSpan={1}></th>
                                </tr>
                              </thead>
                              <tbody>
                                {dlrFormik?.values?.items?.map((ele, index) => (
                                  <tr key={index} style={{height:"50px"}}>
                                    <td colSpan={3} style={{width:"200px"}}>
                                      <div className=''>
                                        <Select name={`dlrFormik.items.[${index}].vendorId.value`}  style={{ cursor: "pointer !important" }}  className='col w-100' placeholder="Select vendor..." cacheOptions value={{ label: dlrFormik.values?.items[index]?.vendorId?.label, value:dlrFormik.values?.items[index]?.vendorId?.value }} options={vendorOptions} defaultOptions onChange={(e) => { handleSelect(e,index,"vendorId") }} />
                                        {dlrFormik?.touched?.items?.[index]?.vendorId && dlrFormik?.errors?.items?.[index]?.vendorId?.value ? (<div className='req-error'>{dlrFormik?.errors?.items?.[index]?.vendorId?.value}</div>) : null}
                                      </div>
                                    </td>
                                      <td colSpan={3} style={{width:"200px"}}>
                                      <div  className=''>
                                        <AsyncSelect name={`dlrFormik.items.[${index}].itemId.value`} key={dlrFormik?.values?.items[index]?.vendorId?.value+"item"+index}  style={{ cursor: "pointer !important" }} isDisabled={!dlrFormik?.values?.items[index]?.vendorId} className='col w-100' placeholder="Select item..." cacheOptions value={{ label: dlrFormik.values?.items[index]?.itemId?.label, value:dlrFormik.values?.items[index]?.itemId?.value }}  loadOptions={getItemOptions(index)} defaultOptions onChange={(e) => { handleSelect(e,index,"itemId") }} />
                                        {dlrFormik?.touched?.items?.[index]?.itemId && dlrFormik?.errors?.items?.[index]?.itemId?.value ? (<div className='req-error'>{dlrFormik?.errors?.items?.[index]?.itemId?.value}</div>) : null}
                                      </div>
                                    </td>
                                    <td style={{width:"50px"}}  className=''>
                                      <input
                                        type="text"
                                        className="form-control"
                                        name={`items[${index}].noOfTechnical`}
                                        value={dlrFormik?.values?.items?.[index]?.noOfTechnical}
                                        onChange={(e) => {
                                          if (/^\d*$/.test(e.target.value)) {
                                            dlrFormik.handleChange(e)
                                            dlrFormik.setFieldValue(`items.[${index}].noOfLabour`, Number(e.target?.value ||0)+Number(ele?.noOfNonTechnical ||0))
                                          }}}
                                        onBlur={dlrFormik.handleBlur}
                                        style={{height:"30px"}}
                                      />
                                      {dlrFormik?.touched?.items?.[index]?.noOfTechnical && dlrFormik?.errors?.items?.[index]?.noOfTechnical ? (<div className='req-error'>{dlrFormik?.errors?.items?.[index]?.noOfTechnical}</div>) : null}
                                
                                    </td>
                                    <td style={{width:"50px"}}  className=''>
                                      <input
                                        type="text"
                                        className="form-control"
                                        name={`items[${index}].noOfNonTechnical`}
                                        value={dlrFormik?.values?.items?.[index]?.noOfNonTechnical}
                                        onChange={(e) => {
                                          if (/^\d*$/.test(e.target.value)) {
                                            dlrFormik.handleChange(e)
                                            dlrFormik.setFieldValue(`items.[${index}].noOfLabour`, Number(e.target?.value ||0)+Number(ele?.noOfTechnical ||0))
                                        }}}
                                        onBlur={dlrFormik.handleBlur}
                                        style={{height:"30px"}}
                                      />
                                      {dlrFormik?.touched?.items?.[index]?.noOfNonTechnical && dlrFormik?.errors?.items?.[index]?.noOfNonTechnical ? (<div className='req-error'>{dlrFormik?.errors?.items?.[index]?.noOfNonTechnical}</div>) : null}
                                
                                    </td> 
                                    <td  style={{width:"50px"}} className=''>
                                      <div>{dlrFormik?.values?.items?.[index]?.noOfLabour}</div>
                                      {dlrFormik?.touched?.items?.[index]?.noOfLabour && dlrFormik?.errors?.items?.[index]?.noOfLabour ? (<div className='req-error'>{dlrFormik?.errors?.items?.[index]?.noOfLabour}</div>) : null}
                                      {/* <input
                                        type="text"
                                        className="form-control"
                                        name={`items[${index}].noOfLabour`}
                                        value={dlrFormik?.values?.items?.[index]?.noOfLabour}
                                        onChange={(e) => {
                                          if (/^\d*$/.test(e.target.value)) {
                                            dlrFormik.handleChange(e)
                                          }}}
                                        onBlur={dlrFormik.handleBlur}
                                        style={{height:"30px"}}
                                      /> */}
                                      {/* {dlrFormik?.touched?.items?.[index]?.noOfLabour && dlrFormik?.errors?.items?.[index]?.noOfLabour ? (<div className='req-error'>{dlrFormik?.errors?.items?.[index]?.noOfLabour}</div>) : null} */}
                                
                                    </td>               
                                    <td >
                                      <div className='d-flex gap-2'>
                                      {dlrFormik?.values?.items?.length>1 && <button className='add-vendor' onClick={()=>handleRemoveItemQty(index)}>-</button>}
                                      {<button className='add-vendor'  onClick={()=>handleAddItemQty(index)}>+</button>}
                                      </div>
                                    </td>
                                
                                  </tr>
                                ))}
                                <tr>
                                  <td colSpan={3} style={{width:"200px"}}><span className='fw-bold'>{langPreference?.total_no_of_count || "Total No of count"}</span></td>
                                  <td colSpan={3} style={{width:"200px"}}></td>
                                  <td>{getTotalCount("noOfTechnical")}</td>
                                  <td>{getTotalCount("noOfNonTechnical")}</td>
                                  <td>{getTotalCount("noOfLabour")}</td>
                                  <td></td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <hr />
                      </div>
                  </>
                <hr />

              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="order-review">
                <div className="d-flex bb justify-content-between align-items-center">
                  <h6>{langPreference?.preview || "Preview"}</h6>
                </div>
               <div className="org">
                  {dprDetails?.dlrNumber && <div className="d-flex justify-content-between align-items-center">
                    <p className="dark">{langPreference?.dlrNumber || "DLR number"}</p>
                    <p className="light">{dprDetails?.dlrNumber}</p>
                  </div>}

                  <div className="d-flex justify-content-between align-items-center">
                    <p className="dark">{langPreference?.date || "Date"}</p>
                    <p className="light">{moment(dlrFormik.values?.createDate).format("DD/MM/YYYY")}</p>
                  </div>
                  {dprDetails?.status && <div className="d-flex justify-content-between align-items-center">
                    <p className="dark">{langPreference?.status || "Status"}</p>
                    <p className="light">{dprDetails?.status}</p>
                  </div>}
                  <div className="d-flex justify-content-between align-items-center">
                    <h6>{langPreference?.items || "Items"} ({langPreference?.count || "count"}: {dlrFormik?.values?.items?.length})</h6>
                  </div>
                  <table className="table">
                    <thead>
                      <tr>
                        <th style={{ width: "30%" }} scope="col">{langPreference?.subcontractor_name || "Sub-contractor name"}</th>
                        <th style={{ width: "30%" }} scope="col">{langPreference?.items || "Item"}</th>
                        <th scope="col">{langPreference?.no_of_labour || "No of Labour"}</th>
                        <th scope="col">{langPreference?.technical || "Technical"}</th>
                        <th scope="col">{langPreference?.non_technical || "Non Technical"}</th>
                      </tr>
                    </thead>
                    <tbody>
                        {dlrFormik?.values?.items?.map((ele, index) => {
                          return (<tr>
                            <td style={{ overflowWrap: "anywhere" }}>{ele?.vendorId?.label}</td>
                            <td style={{ overflowWrap: "anywhere" }}>{ele?.itemId?.label}</td>
                            <td >{ele?.noOfLabour}</td>
                            <td >{ele?.noOfTechnical}</td>
                            <td >{ele?.noOfNonTechnical}</td>
                          </tr>)
                        })
                      }
                      <tr>
                        <td><span className='fw-bold'>{langPreference?.total_no_of_count || "Total No of count"}</span></td>
                        <td></td>
                        <td>{getTotalCount("noOfLabour")}</td>
                        <td>{getTotalCount("noOfTechnical")}</td>
                        <td>{getTotalCount("noOfNonTechnical")}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                {dlrFormik.values.reason && <div className="add-box">
                  <h5>{langPreference?.reason_for_labor || "Reason for Labor"}:</h5>
                  <p className="mb-0">{dlrFormik.values?.reason}</p>
                </div>}
              </div>
            </div>
          </div>
        </div>
      </div>
      <button className='d-none' id="submitModalBtn1" data-bs-toggle="modal" data-bs-target={`#${submitConfirmModal}`} />
      <ConfirmationModal modalId={submitConfirmModal} handleConfirm={() => handleSumbitApproval(true)} message={langPreference?.submit_confirmation || <>Are you sure you want to <br /> Submit ?</>} />
      <button className='d-none' id="statusModalBtn" data-bs-toggle="modal" data-bs-target={`#${statusModalId}`} />
      <StatusModal
        modalId={statusModalId}
        message={confirmationMsg}
        handleClose={handleApprovalModalClose}
      />

    </>
  )
}

export default AddOrUpdateDLR