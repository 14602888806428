import React, { useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useUserContext } from '../../context/userContext';
import { S3_URL } from '../../utils/BaseUrl';
import Logo from './Logo';
import toast from 'react-hot-toast';


export default function MainNavbar() {
    const { userDetails,langPreference } = useUserContext();
    const location = useLocation()


    return (
        <>
            {/* <!-- navbar --> */}
            <div className="dash-nav">
                <nav className="navbar navbar-expand-lg bg-body-tertiary">

                    <div className="container">
                        <Link to="/dashboard" className="">
                            {/* <img style={{ width: "8%" }} src="/logo.png" alt="" /> */}
                            <Logo width="100px" logoUrl='/Group.png' />
                            </Link>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse justify-content-center" id="navbarNavDropdown">
                            <ul className="navbar-nav">
                                <li className="nav-item ms-2">
                                    <div>
                                        <div><span className=''><img src={userDetails?.siteId?.companyId?.companyLogo ?  S3_URL + userDetails?.siteId?.companyId?.companyLogo: "/noLogoUploaded.png"} alt="" style={{ width: "50px", height: "50px" }} className='img-fluid rounded'/></span></div>
                                    </div>
                                </li>
                                
                                <li className="nav-item ms-2">
                                    <div>
                                        <div><span className='text-white fw-bold'>{langPreference?.company || "Site"}:</span> <span className='text-white'>{userDetails?.siteId?.name}</span></div>
                                        <div><span className='text-white fw-bold'>{langPreference?.site ||"Company"}:</span> <span className='text-white'>{userDetails?.siteId?.companyId?.name}</span></div>
                                    </div>
                                </li>
                                
                            </ul>
                        </div>
                        {/* <form className="d-flex" role="search">
                            <input className="form-control" type="search" placeholder="Search for" aria-label="Search" />
                        </form> */}


                        <div className='d-flex align-items-center gap-5'>
                        <div className="dropdown ms-2 ">
                            <div className="btn bg-white " type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <div className='d-flex justify-content-center align-items-center'>  <img src={userDetails?.profileImage ? S3_URL + userDetails?.profileImage : "/images/person-circle.svg"} alt='profile' style={{ width: "25px", height: "25px" }} className='rounded-circle img-fluid me-1' />
                                    <span>{`${userDetails.firstName ? userDetails.firstName : ""} ${userDetails.lastName ? userDetails.lastName : ""}`}</span>
                                </div>
                                <div className="float-start badge rounded-pill bg-light text-dark">{userDetails?.roleId?.name || userDetails?.designation}</div>
                            </div>

                            <ul className="dropdown-menu">
                                <li>
                                    <Link to="/manage-sites" className="dropdown-item" >
                                    {langPreference?.manage_sites || " Manage Sites"}
                                    </Link>
                                </li>
                                <li >
                                    <Link to={"/change-language"} className="dropdown-item">
                                    {langPreference?.change_language || "Change language" }
                                    </Link>
                                </li>
                                <li>
                                    <Link target='_blank' to="/termsandconditions" className="dropdown-item" >
                                    {langPreference?.terms_and_conditions || "Terms and Conditions" }
                                    </Link>
                                </li>
                                <li>
                                    <Link target='_blank' to="/privacypolicy" className="dropdown-item" >
                                      {langPreference?.privacy_policy || "Privacy Policy" }
                                    </Link>
                                </li>
                                <li>
                                    <Link data-bs-toggle="modal" className="dropdown-item" data-bs-target="#exampleModalout1" >
                                    {langPreference?.logout || "Log Out" }
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        {userDetails?.roleId?.permission?.includes("notificationRead") &&
                        <div>
                            <Link to={"/notification"} >
                                { userDetails?.hasUnseenNotification ? <img width="24" height="24" src="https://img.icons8.com/external-tal-revivo-color-tal-revivo/24/external-notification-alert-both-on-phone-and-tablet-pc-date-color-tal-revivo.png" alt="external-notification-alert-both-on-phone-and-tablet-pc-date-color-tal-revivo" />
                                    : <img width="24" height="24" src="https://img.icons8.com/fluency/48/appointment-reminders--v1.png" alt="appointment-reminders--v1" />
                                }
                            </Link>
                        </div>
                        }
                      
                       
                        </div>
                    </div>
                </nav>
            </div>
        </>
    )
}
