import { debounce, uniqueId } from 'lodash';
import React, { useEffect, useRef, useState } from 'react'
import DatePicker from "react-datepicker";
import {  createOrUpdateBoqApi, getSiteBoqByIdApi, removeBoqItemApi, submitSiteBoqByIdApi } from '../../utils/ApiEndpointFunctions';
import { useFormik } from 'formik';
import {  boqAddInitalValue, boqAddValidationSchema,  } from '../../utils/Validation';
import Spinwheel from '../../components/common/Spinwheel';
import toast from 'react-hot-toast';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import ConfirmationModal from '../../components/modal/ConfirmationModal';
import { formatPrice, getRoundOff } from '../../utils/HelperFuncitons';
import { useUserContext } from '../../context/userContext';
import moment from 'moment';
import StatusModal from '../../components/modal/StatusModal';
import * as XLSX from 'xlsx-js-style';


function AddSiteBOQ() {
  const [addLoader, setAddLoader] = useState(false)
  const submitConfirmModal = "submitConfirmModal1"
  const statusModalId = "statusModal1"
  const convertConfirmModal = "convertConfirmModal1"
  const navigate = useNavigate();
  const params = useParams();
  const { boqId } = params;
  const [selectItemId, setSelectItemId] = useState();
  const { userDetails,langPreference} = useUserContext();
  const [confirmationMsg, setConfirmationMsg] = useState('');
  const [updateItem,setUpdateItem] = useState({index:null,loading:false})
  const location = useLocation();
  const [isUpload,setUpload] = useState(false)
  const fileRef = useRef()


  const handleSelectDate = (e, eleType) => {
    boqFormik.setFieldValue(eleType, new Date(e).toISOString())
  };


  const boqSubmit = async (values) => {
    if(values?._id){
      const payload = {
        _id:values._id,
        contractStartDate:values.contractStartDate,
        contractDate:values.contractDate,
        completionDate:values.completionDate,
        actualDate:values.actualDate,
        category:values?.category,
      }
      try {
        setAddLoader(true)
        const res =  await submitSiteBoqByIdApi(payload);
        if (res.status === 200) {
          toast.success(res.data.message);
          document.getElementById(submitConfirmModal).click()
          setConfirmationMsg(res?.data?.message || `BOQ ${res.data?.data?.boqNumber || ""} is successfully submitted.`)
          document.getElementById('statusModalBtn').click()
        }
      } catch (error) {
        error && error.response ?
          toast.error(error.response?.data?.message) :
          toast.error("something went wrong");
      }
      setAddLoader(false)
    }
  }

  const boqFormik = useFormik({
    initialValues: boqAddInitalValue,
    onSubmit: boqSubmit,
    validationSchema: boqAddValidationSchema(langPreference)
  })

  const getSiteBoqById = async () => {
    const res = await getSiteBoqByIdApi(boqId)
    if (res.status === 200) {
      const data = res.data?.data
      if(data){
        const list = data?.items?.map(ele=>{
          return {
            ...ele,
            saved:true,
            isDraft:false,
          }
        })
        boqFormik.setValues(data)
        boqFormik.setFieldValue("items",list)
      }
    }
  }

  useEffect(() => {
    if(boqId){
      getSiteBoqById(boqId)
    }
  }, [boqId])

  const handleConfirmation = (isConvert) => {
    boqFormik.handleSubmit();
  }

  const checkValidation = (btn) => {
    const errors = Object.keys(boqFormik.errors)   
    if (errors?.length > 0) {
      boqFormik.handleSubmit()
    } else {
      document.getElementById(btn).click()
    }
  }

  const checkSaveValidation = (value,index) => {
    const error = boqFormik?.errors?.items?.filter((ele,ind)=>index==ind)?.[0]
    if(!error){
      handleBoqItemSave(value,index)
    }else{
      boqFormik.handleSubmit()
    } 
  }

  const handleApprovalModalClose = () => {
     navigate("/boq")
  }

const handleAddItemQty =(index, sNo)=>{
const isUnSaved = boqFormik?.values?.items?.length && boqFormik?.values?.items?.filter(ele=>!ele?.saved)?.length
if(isUnSaved){
  toast.error(langPreference?.save_to_continue || "Please save item")
  return
}

  const item = {
    orderNo:(sNo || 0)+1,
    saved:false,
    isDraft:true,
    quantity: '',
    unitPrice: '',
    unitType: "",
  }
  let list = []
  if(!index && index!=0){
    list.push(item)
  }else{
    boqFormik?.values?.items?.forEach((ele,ind)=>{
      if(index==ind){
        list.push(ele,item)
      }else{
        if(ind>index){
          list.push({...ele,orderNo:ele?.orderNo ? ele?.orderNo+1 : 0})
        }else{
          list.push(ele)
        }
      }
    })
  }

  boqFormik.setFieldValue("items", list)
}

const handleRemoveItemQty =async(value,index)=>{
  try {
    let newList = boqFormik.values.items.filter((item,ind)=>ind!=index)
    if(value?.isDraft){
      boqFormik?.setFieldValue("items",newList)
      return
    } 
    const res =  await removeBoqItemApi({_id:value?._id});
    if (res.status === 200) {
      toast.success(res.data.message);
      boqFormik?.setFieldValue("items",newList)
    }
    
  } catch (error) {
    console.log("handleRemoveItemQty error",error);
    error && error.response ?
      toast.error(error.response?.data?.message) :
      toast.error("something went wrong");
  }
}

 const handleBoqItemSave =async(values,index)=>{
  try {
    setUpdateItem({index,loading:true})
    const res =  await createOrUpdateBoqApi({...boqFormik?.values,items:values});
    if (res.status === 200) {
      toast.success(res.data.message);
      const data = res?.data?.data
      let list = boqFormik?.values?.items.map((ele,ind)=>{
        if(ind==index){
          return {
            ...ele,
            saved:true,
            isDraft:false,
            _id:data?.items?.[0]?._id,
            uniqueBoqItemId:data?.items?.[0]?.uniqueBoqItemId,
          }
        }
        return ele
      })
     boqFormik?.setFieldValue("items",list)
     boqFormik?.setFieldValue("_id",data?._id)
     boqFormik?.setFieldValue("boqNumber",data?.boqNumber)
    }
  } catch (error) {
    console.log("error",error);
    
    error && error.response ?
      toast.error(error.response?.data?.message) :
      toast.error("something went wrong");
  }
  setUpdateItem({index:null,loading:false})
 }


const handleChange =(e,index,value,type)=>{
  if(isNaN(Number(e?.target?.value)) && type=="number"){
    return
  }
  // console.log(isNaN(Number(e?.target?.value)) && type=="number");
  
  boqFormik.handleChange(e)
  boqFormik.handleBlur(e)
}

const getTotalAmt =(items)=>{
  let total =0
  items?.forEach(ele=>{
    total+=Number(ele?.quantity)*Number(ele?.unitPrice)
  })
  return total
}

const handleFileUpload = async(e) => {
  try {
    const file = e.target.files[0];
    const fileSplitArr = file?.name?.split(".")
    const fileExtension = fileSplitArr?.[fileSplitArr?.length-1]
    const allowedExtensions = ['xlsx','xls']

    if(!allowedExtensions?.includes(fileExtension)){
      toast.error(`Only xlsx, xls file allow`);
      return
    }
    
    if (file) {
      setUpload(true)
      const reader = new FileReader();
      reader.onload = (event) => {
        const data = new Uint8Array(event.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet);
  
        // Validate the presence of required columns
        const requiredFields = ["Name and Details", "Unit", "Total Quantity", "Rate"];
        const headers = Object.keys(jsonData[0] || {});
        const missingFields = requiredFields.filter((field) => !headers.includes(field));
        if (missingFields.length > 0) {
          toast.error(`Missing required fields: ${missingFields.join(", ")}`);
          setUpload(false)
          return
        }
  
        if(jsonData?.length==0){
          toast.error(`Data not found`);
        }
  
        // Extract relevant columns
        const filteredData = [];
        const lastOrderNo = boqFormik?.values?.items?.length ? (boqFormik?.values?.items?.[boqFormik?.values?.items?.length-1]?.orderNo || 0) : 0
        for (const [index, row] of jsonData.entries()) {
          const nameAndDetails = row["Name and Details"] || "";
          const unitType = row["Unit"] || "";
          const quantity = row["Total Quantity"];
          const unitPrice = row["Rate"];
  
          if (!nameAndDetails) {
            toast.error(`Row ${index + 1}: 'Name and Details' is required.`);
            setUpload(false)
            break;
          }
          if (!unitType) {
            toast.error(`Row ${index + 1}: 'Unit' is required.`);
            setUpload(false)
            break;
          }
          if (isNaN(quantity)) {
            toast.error(`Row ${index + 1}: 'Total Quantity' is not a number.`);
            setUpload(false)
            break;
          }
          if (isNaN(unitPrice)) {
            toast.error(`Row ${index + 1}: 'Rate' is not a number.`);
            setUpload(false)
            break;
          }
  
          // Add valid row to filtered data
          filteredData.push({
            nameAndDetails,
            unitType,
            quantity,
            unitPrice,
            isSaved:false,
            isDraft:true,
            orderNo:lastOrderNo+index+1
          });
        }
        // If no errors, set data
        if (filteredData.length === jsonData.length) {
          toast.success("File processed successfully!");
          let list = boqFormik.values?.items
          list = [...list, ...filteredData]
          boqFormik.setFieldValue("items", list)
        }
      };
      reader.readAsArrayBuffer(file);
    }
    setUpload(false)
  } catch (error) {
    console.log("error",error);
    setUpload(false)
    toast.error("Failed to upload")
  }
}
console.log("bo",boqFormik);


  return (
    <>
      <div className="dash-nav1">
      <div className="back"><span onClick={() => navigate(location?.state?.path ? location?.state?.path : -1, { state: { ...location?.state } })} className="back cursor-pointer" ><i className="bi bi-chevron-left"></i> {langPreference?.back || "Back"}</span></div>
        <div className="align-items-center d-block d-lg-flex justify-content-between">
          <h3 className="mb-0 font-blue">{boqId ? langPreference?.edit_boq || "Edit BOQ" : langPreference?.add_boq || "Add BOQ"} </h3>
          <div>            
            <button type="button" disabled={addLoader} className="mb-2 mb-sm-2 sbm-purchase" onClick={() => {
              if (boqFormik.values?.items.filter(item => !item.isSaved)?.length) {
                if(boqFormik.values?.items.filter(item => item.isDraft)?.length){
                  toast.error(langPreference?.save_to_continue ||  "Please save items")
                  return
                }
                checkValidation("submitModalBtn1")
              } else toast.error(langPreference?.please_select_items || "Please select items")
            }}>
              {addLoader ? <Spinwheel sizeClass={"spin-small"} /> : langPreference?.submit_for_approval || "Submit for approval"}
            </button>
          </div>
        </div>
      </div>
      <div className="dash-table">
        <div className="container">
          <div className="row gap-3 gap-xl-0">
            <div className="col-12">
              <div className="">
                <div className='d-flex justify-content-between align-items-center bb'>
                </div>
                <div>
                 <div className="form-details">
                      <div className="">
                        <div className='row row-cols-3'>
                        <div className="d-flex flex-column">
                          <label htmlFor="contractDate">{langPreference?.contract_date || "Contract Date"}</label>
                          <DatePicker dateFormat="dd/MM/yyyy" placeholderText='Contract Date' className='form-select cursor-pointer' name='contractDate' selected={boqFormik.values?.contractDate} onChange={(e) => { handleSelectDate(e, "contractDate") }} />
                          <span id="contractDate"></span>
                        </div>
                        <div className="d-flex flex-column">
                          <label htmlFor="contractStartDate">{langPreference?.date_of_start_contract || "Date of Start (as per Contract)"}</label>
                          <DatePicker dateFormat="dd/MM/yyyy" placeholderText='Contract Start Date' className='form-select cursor-pointer' name='contractStartDate' selected={boqFormik.values?.contractStartDate} onChange={(e) => { handleSelectDate(e, "contractStartDate") }} />
                          <span id="contractStartDate"></span>
                        </div>
                        <div className="d-flex flex-column">
                          <label htmlFor="completionDate">{langPreference?.date_of_completion_contract || "Date of Completion (as per contract)"}</label>
                          <DatePicker dateFormat="dd/MM/yyyy" placeholderText='Completion Date' className='form-select cursor-pointer' name='completionDate' selected={boqFormik.values?.completionDate} onChange={(e) => { handleSelectDate(e, "completionDate") }} />
                          <span id="completionDate"></span>
                        </div>
                        <div className="d-flex flex-column">
                          <label htmlFor="actualDate">{langPreference?.date_of_start_actual || "Actual Date"}</label>
                          <DatePicker dateFormat="dd/MM/yyyy" placeholderText='Actual Date' className='form-select cursor-pointer' name='actualDate' selected={boqFormik.values?.actualDate} onChange={(e) => { handleSelectDate(e, "actualDate") }} />
                          <span id="actualDate"></span>
                        </div>
                        <div className="d-flex flex-column">
                        <label htmlFor="category">Category</label>
                          <input
                            type="text"
                            className="form-control"
                            name={`category`}
                            value={boqFormik?.values?.category}
                            onChange={boqFormik?.handleChange}
                            onBlur={boqFormik?.handleBlur}
                            disabled={updateItem?.loading}
                            // style={{ height: "30px", width: "60px" }}
                          />
                          {boqFormik?.touched?.category && boqFormik?.errors?.category ? (<div className='req-error'>{boqFormik?.errors?.category}</div>) : null}
                        </div>
                        </div>
                      </div>
                      <div className='mt-3'>
                       <div>{langPreference?.total_amount || "Total Amount"}: {getTotalAmt(boqFormik?.values?.items)}</div> 
                       {boqFormik?.values?.boqNumber && <div>{langPreference?.boq_number || "BOQ Number"}: {boqFormik?.values?.boqNumber}</div> }

                      </div>
                    </div>
                </div>
                  <>
                      <div className='mb-3 items-detail-box position-relative border-0 m-0 p-0' >
                        {/* First Row: Basic Details */}
                        <div className='w-100 mt-1'>
                          <div className='d-flex justify-content-end'>
                        <button type="button"  className="add-vendor mb-sm-0 mb-sm-2" onClick={(e)=>{!isUpload && fileRef.current?.click()}} >
                        {isUpload ? <Spinwheel sizeClass={"spin-small"} /> : langPreference?.upload || "Upload"}
                        </button>
                        <input type='file' accept=".xlsx, .xls" style={{display:'none'}} ref={fileRef} disabled={isUpload} onChange={(e) => {!isUpload && handleFileUpload(e)}}/>
                          </div>
                          <div className="table-responsive">
                            <table className="table d-table mt-4" >
                              <thead>
                                <tr>
                                  <th>{langPreference?.item_name_details || "Name and Details"}</th>
                                    <th>{langPreference?.unit || "Unit"}</th>
                                   <th>{langPreference?.qty || "Qty"}</th>
                                    <th>{langPreference?.rate || "Rate"}</th>
                                    <th>{langPreference?.amount || "Amount"}</th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>
                              {boqFormik?.values?.items?.map((ele, index) => (
                               <tr key={index} style={{height:"50px"}}>
                                    <td >
                                    <textarea
                                        type="text"
                                        className="form-control"
                                        name={`items[${index}].nameAndDetails`}
                                        value={boqFormik?.values?.items?.[index]?.nameAndDetails}
                                        onChange={(e)=>handleChange(e,index,ele,"text")}
                                        onBlur={(e)=>handleChange(e,index,ele,"text")}
                                        disabled={updateItem?.loading}
                                        style={{height:"30px"}}
                                      />
                                      {boqFormik?.touched?.items?.[index]?.nameAndDetails && boqFormik?.errors?.items?.[index]?.nameAndDetails ? (<div className='req-error'>{boqFormik?.errors?.items?.[index]?.nameAndDetails}</div>) : null}
                                     </td>
                                    <td>
                                      <input
                                        type="text"
                                        className="form-control"
                                        name={`items[${index}].unitType`}
                                        value={boqFormik?.values?.items?.[index]?.unitType}
                                        onChange={(e)=>handleChange(e,index,ele,"text")}
                                        onBlur={(e)=>handleChange(e,index,ele,"text")}
                                        disabled={updateItem?.loading}
                                        style={{height:"30px",width:"60px"}}
                                      />
                                      {boqFormik?.touched?.items?.[index]?.unitType && boqFormik?.errors?.items?.[index]?.unitType ? (<div className='req-error'>{boqFormik?.errors?.items?.[index]?.unitType}</div>) : null}
                                    </td>
                                    <td >
                                    <input
                                        type="text"
                                        className="form-control"
                                        name={`items[${index}].quantity`}
                                        value={boqFormik?.values?.items?.[index]?.quantity}
                                        onChange={(e)=>(e.target?.value?.split(".")[1]?.length < 3 || e.target?.value?.split(".")[1]?.length === undefined) && handleChange(e,index,ele,"number")}
                                        onBlur={(e)=>handleChange(e,index,ele,"number")}
                                        disabled={updateItem?.loading}
                                        style={{height:"30px",width:"60px"}}
                                      />
                                      {boqFormik?.touched?.items?.[index]?.quantity && boqFormik?.errors?.items?.[index]?.quantity ? (<div className='req-error'>{boqFormik?.errors?.items?.[index]?.quantity}</div>) : null}
                                     </td>
                                    <td>
                                      <input
                                        type="text"
                                        className="form-control"
                                        name={`items[${index}].unitPrice`}
                                        value={boqFormik?.values?.items?.[index]?.unitPrice}
                                        onChange={(e)=>(e.target?.value?.split(".")[1]?.length < 3 || e.target?.value?.split(".")[1]?.length === undefined) && handleChange(e,index,ele,"number")}
                                        onBlur={(e)=>handleChange(e,index,ele,"number")}
                                        disabled={updateItem?.loading}
                                        style={{height:"30px",width:"60px"}}
                                      />
                                      {boqFormik?.touched?.items?.[index]?.unitPrice && boqFormik?.errors?.items?.[index]?.unitPrice ? (<div className='req-error'>{boqFormik?.errors?.items?.[index]?.unitPrice}</div>) : null}
                                    </td>
                               
                                    <td><p>{ele?.quantity && ele?.unitPrice ? Number(ele?.quantity)*Number(ele?.unitPrice) : 0}</p></td>
                                    <td>
                                      <div className='d-flex gap-2'>
                                      {<button className={`${ele?.saved ? "save-btn" : "unsave-btn"}`}  disabled={updateItem?.loading} onClick={()=>checkSaveValidation(ele,index)}>{updateItem?.index==index ? "Saving" :langPreference?.save || "Save"}</button>}
                                      {<button className='add-vendor'  disabled={updateItem?.loading}  onClick={()=>handleRemoveItemQty(ele,index)}>-</button>}
                                      <button  disabled={updateItem?.loading} className='add-vendor'  onClick={() => {  handleAddItemQty(index,ele?.orderNo||0) }}>+</button>
                                      </div>
                                    </td>
                                
                                  </tr>))}

                              </tbody>
                            </table>
                              {boqFormik.values.items?.length==0 && <div className='d-flex justify-content-end'>
                     <button className="add-vendor mb-sm-0 mb-sm-2"  onClick={() => { handleAddItemQty(null,0) }}>
                    <i className="bi bi-plus"></i> {langPreference?.add || "Add Item"}
                  </button>
                    </div>}
                          </div>
                        </div>
                      </div>
                  </>

              </div>
            </div>

          </div>
        </div>
      </div>
      {/* for po approval */}
      <button className='d-none' id="submitModalBtn1" data-bs-toggle="modal" data-bs-target={`#${submitConfirmModal}`} />
      <ConfirmationModal modalId={submitConfirmModal} handleConfirm={() => handleConfirmation(false)} message={langPreference?.submit_confirmation || <>Are you sure you want to <br /> Submit ?</>} />
      <button className='d-none' id="statusModalBtn" data-bs-toggle="modal" data-bs-target={`#${statusModalId}`} />
      <StatusModal
        modalId={statusModalId}
        message={confirmationMsg}
        handleClose={handleApprovalModalClose}
      />
    </>
  )
}

export default AddSiteBOQ