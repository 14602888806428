import React, { useEffect, useState } from 'react'
import Spinwheel from '../../components/common/Spinwheel';
import toast from 'react-hot-toast';
import { Link, useNavigate, } from 'react-router-dom';
import {  getInventoryReportApi } from '../../utils/ApiEndpointFunctions';
import { useFormik } from 'formik';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import FilterInventoryReport from '../../components/modal/filterInventoryReport';
import { inventoryReportFilterInitialValue, inventoryReportValidationSchema } from '../../utils/Validation';
import { useUserContext } from '../../context/userContext';

function InventoryReport(props) {
   const location = useLocation();
   const {langPreference} = useUserContext()
   const [search,setSearch] = useState(false)
   const [inventoryReportList, setInventoryReportList] = useState([])
   const [loading, setLoading] = useState(false)
   const navigate = useNavigate();
   const filterModalId = "inventoryFilterModalID"


   const getReport = async(vendorId, selectedDate, inventoryId)=>{
      setLoading(true)
      try {
          const res = await getInventoryReportApi(vendorId, selectedDate, inventoryId)
          if (res.status === 200) {
            setInventoryReportList(res?.data?.data?.data)
          }
          setLoading(false)
      } catch (error) {
          setLoading(false)
          toast.error(error?.response?.data?.message || "Something went wrong")
          console.log("error", error);
      }
   }

   const filterFormik = useFormik({
      initialValues: location?.state?.path == "/inventory/report" && location.state?.filterValue ? location.state?.filterValue : inventoryReportFilterInitialValue,
      validationSchema: inventoryReportValidationSchema(langPreference), 
      onSubmit: async (values) => {
          let vendorId = values?.vendor?.value
          let inventoryId = values?.item?.value
          let selectedDate = `${filterFormik.values?.to ? new Date(filterFormik.values?.to).getTime() + "-" : ""}${filterFormik.values?.from ? new Date(filterFormik.values?.from).getTime() : ""}`
          document.getElementById(filterModalId).click()
          setSearch(true)
         getReport(vendorId, selectedDate, inventoryId)
      }
  })

  const handleReset = () => {
      filterFormik.setValues(inventoryReportFilterInitialValue)
      navigate("/inventory/report", { replace: true })
      getReport("", "", "")
      setSearch(false)
  }

      useEffect(() => {
          if (location?.state?.path == "/inventory/report" && location.state?.filterValue) {
              filterFormik.handleSubmit()
          }
      }, [location])


   return (
      <>
         <div className="main-content">
            <div className="container-fluid">
               <div className="row">
                  <div className="main-content">
                     <div className='report-header'>
                           <div>
                            {props.type !== "dashboard" && <div className="dash-nav1 dash-nav-report">
                                <div className="d-flex justify-content-between align-items-center">
                                 <div className="dash-nav1">
                                    <div className="back"><span onClick={() => navigate(-1)} className="back cursor-pointer" ><i className="bi bi-chevron-left"></i> {langPreference?.back || "Back"}</span></div>
                                    <div className="d-flex justify-content-between align-items-center">
                                       <h3 className="mb-0 font-blue">{langPreference?.inventory_report || "Inventory Report"}</h3>
                                    </div>
                                 </div>
                                            <div className="d-flex align-items-center justify-content-end">
                                                <div className="dropdown">
                                                    <button className="btn btn-dark me-2" type="button" disabled={!Object.values(filterFormik?.values).filter(Boolean).length} onClick={handleReset} >
                                                        {langPreference?.reset_filter || "Reset filter"}
                                                    </button>
                                                    <button className="btn filter  dropdown-toggle me-2" type="button" data-bs-toggle="modal" data-bs-target={`#${filterModalId}`}  >
                                                        <i className="bi bi-funnel me-2"></i>{langPreference?.filter || "Filter"}
                                                    </button>
                                                </div>
                                            </div>
                                </div>
                            </div>}
                            </div>
                           </div>
                         
                     {loading ? <div className='d-flex align-items-center justify-content-center mt-4'>
                        <Spinwheel />
                     </div> :
                        <div className='mt-5 pt-5'>
                           {inventoryReportList?.length==0 ?  <h4 className='text-center mt-5 pt-5'>{search ? langPreference?.data_not_found ||  "Data not found" : langPreference?.please_select_date || "Please select date range"} </h4> :
                           inventoryReportList?.map(ele => <div className='table-responsive mt-4'>
                              <table className="table table-bordered table-responsive">
                                 <thead>
                                    <tr>
                                       <th scope='col' colspan="12" className='text-center'>{ele?.name}</th>
                                    </tr>
                                    <tr>
                                       <th scope='col' >{langPreference?.date || "Date"}</th>
                                       <th scope='col' >{langPreference?.challan_id || "Challan ID"}</th>
                                       <th scope='col' >{langPreference?.gin_id || "GIN ID"}</th>
                                       <th scope='col' >{langPreference?.approved_by || "Approved By"}</th>
                                       <th scope='col' >{langPreference?.from || "From"}</th>
                                       <th scope='col' >{langPreference?.type || "Type"}</th>
                                       <th scope='col' >{langPreference?.qty || "Quantity"}</th>
                                    </tr>
                                 </thead>
                                 <tbody>
                                    {ele?.inOutStock?.length > 0 ? ele?.inOutStock?.map((item, index) => {
                                       return (
                                          <tr>
                                             <td>{item?.approvedOn ? moment(item?.approvedOn).format("DD/MM/YYYY") : "-"}</td>
                                             <td>{item?.type == "challan" && <Link to={`/challan/view/${item?.challan?._id}`} state={{ path: "/inventory/report", filterValue: filterFormik?.values }}>{item?.challan?.challanNumber}</Link>} </td>
                                             <td>{(item?.type == "gin" || item?.type == 'goods-return') && <Link to={`/gin/view/${item?.type == "gin" ? item?.gin?._id : item?.goodsreturn?.returnFromGin?._id}`} state={{ path: "/inventory/report", filterValue: filterFormik?.values }}>{item?.type == "gin" ? item?.gin?.ginNumber : item?.goodsreturn?.returnFromGin.ginNumber}</Link>} </td>
                                             <td className='text-capitalize'>{item?.approvedBy?.firstName} {item?.approvedBy?.lastName}</td>
                                             <td className='text-capitalize'>{item?.type}</td>
                                             <td>{item?.type == "gin" ? "OUT" : "IN"}</td>
                                             <td>{item?.addedQty || item?.quantity}</td>
                                          </tr>)
                                    }) : <tr className='text-center'>
                                       <td colSpan="12">
                                          <h1>{langPreference?.data_not_found || "Data Not Found"}</h1>
                                       </td>
                                    </tr>}
                                 </tbody>
                                 <tfoot>
                                    <div className='my-2'>
                                       <h5>{langPreference?.available_quantity || "Available quantity"}: {ele?.availableQty}</h5>
                                    </div>
                                 </tfoot>
                              </table>
                           </div>)}
                        </div>
                     }

                  </div>
               </div>
            </div>
         </div>
         <FilterInventoryReport modalId={filterModalId} formik={filterFormik} clickHandler={() => filterFormik.handleSubmit()} />
      </>
   )
}

export default InventoryReport